import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"

// Validation schema
const validationSchema = Yup.object({
   stop_loss: Yup.number()
  .required("Enter your Stop Loss")
  .min(0, "Stop Loss must be at least 0")
  .max(100, "Stop Loss cannot be more than 100")
  .typeError("Stop Loss must be a number"),
});

function formatDate(dateStr) {
  try {
    // Create a Date object from the input date string
    const date = new Date(dateStr);
    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    // Get the day, month, and year from the Date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()]; // getMonth() returns 0-11
    const year = date.getFullYear();
    // Format the date as "Month Day, Year"
    return `${month} ${day}, ${year}`;
  } catch (error) {
    // Handle any errors that occurred
    console.error("Error formatting date:", error);
    return dateStr; // or some other default value/error message
  }
}

// Custom form component
const ExchangeForm = ({ item, index, handleSubmit }) => {
  const formik = useFormik({
    initialValues: { stop_loss: item.settings && item.settings.portfolio_stop_loss ?item.settings.portfolio_stop_loss:"",exchange_id:item.exchange_id},
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, index)
        .finally(() => {
          setSubmitting(false); // Reset isSubmitting after submission
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <div> */}
        <Label className="mt-3" htmlFor={`stop_loss_${index}`}>Overall portfolio stop loss</Label>
        <Row className='mb-3'>
        <Col md="6 mb-3">
          <div className="input-group">
            <Input
              type="number"
              name="stop_loss"
              id={`stop_loss_${index}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stop_loss}
            />
            <Label className="input-group-text" htmlFor={`stop_loss_${index}`}>%</Label>
          </div>
        {formik.touched.stop_loss && formik.errors.stop_loss ? (
          <div className="error-message" style={{color:"#f46a6a",marginTop:"0.25rem",fontSize:"80%"}}>{formik.errors.stop_loss}</div>
        ) : <div></div>}
          </Col>
        <Col md="6">
      <button  className={formik.isSubmitting ? "btn btn-success save-user disabled" : "btn btn-success save-user"} type="submit" disabled={formik.isSubmitting}>
        {formik.isSubmitting ? 'Processing...' : 'Save'}
      </button>
      </Col>
      {item.settings && item.settings.portfolio_stop_loss && item.settings.portfolio_stop_loss !=0 && item.settings.portfolio_stop_loss_start_date ?
      <span>Note : Starting from {formatDate(item.settings.portfolio_stop_loss_start_date)}, if the portfolio losses exceed the specified stop loss {item.settings.portfolio_stop_loss}% ({Number(item.settings.portfolio_stop_loss_value).toFixed(2)} USDT) , the bot will stop creating new orders for this exchange.</span> : null}

    </Row>

      {/* </div> */}
    </form>
  );
};

export default ExchangeForm;