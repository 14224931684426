import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label,UncontrolledAlert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
//import { GoogleLogin } from "react-google-login";
//import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
//import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, socialLogin,userForgetPasswordSuccess,registerUserSuccessful } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/icon.png";
import lightlogo from "../../assets/images/logo_light.png";
import blz_logo from "../../assets/images/blaze_logo.png";
import ReCAPTCHA from "react-google-recaptcha";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {form_loading : false,captcha_value:""};
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.userForgetPasswordSuccess ? setTimeout(() => this.props.userForgetPasswordSuccess(), 10000):'';
    this.props.registerUserSuccessful ? setTimeout(()=>this.props.registerUserSuccessful(),30000):'';
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };

  user_login = get_valus =>{
    this.setState({form_loading : true});
    this.props.loginUser(get_valus, this.props.history);
  };

  render() {
    document.title = "Login";
    return (
      <React.Fragment>       
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to DeriSnap.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                    {this.props.error && this.props.error ? (
                      <Alert color="danger">{this.props.error.includes("Firebase:") ? this.props.error.replace("Firebase:",""):this.props.error}</Alert>
                    ) : null}
                    {this.props.forgetSuccessMsg ? (
                        <UncontrolledAlert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </UncontrolledAlert>
                      ) : null}
                      {this.props.registrationSuccess ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.registrationSuccess}
                        </Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email:
                            "",
                          password:
                            "",
                          captchaToken: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email("Please Enter Valid Email").required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Your Password"
                          ),
                          captchaToken : Yup.string().required(
                            "Please verify you are not a robot"
                          ),
                        })}                        
                        onSubmit={this.user_login}
                      >
                        {({ errors, status, touched,setFieldValue }) => (                                                    
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type="password"
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                              </div>
                              <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                            </div>
                            <div className="mb-3">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} render="explicit" onChange={(response) => { setFieldValue("captchaToken", response); }}/>                            
                            {errors.captchaToken && touched.captchaToken && (<p className="text-danger" style={{fontSize: '80%',color:'#f46a6a'}}>{errors.captchaToken}</p>)}
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {this.state.form_loading && !this.props.error ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                Log In
                              </button>
                            </div>
                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Don&apos;t have an account ?{" "}
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} DeriSnap.
                    Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'11px'}} ></img></a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  registrationSuccess: PropTypes.string,
  userForgetPasswordSuccess: PropTypes.any,
  registerUserSuccessful: PropTypes.any,
  forgetSuccessMsg: PropTypes.string,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  const { forgetSuccessMsg } = state.ForgetPassword;
  const {registrationSuccess} = state.Account
  return { error,forgetSuccessMsg,registrationSuccess };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, userForgetPasswordSuccess, socialLogin, registerUserSuccessful })(Login)
);
