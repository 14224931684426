import api from './api_helper';

// Gets the logged in user data from local session

// Get Expiry Dates
const getDiagonalExpiryDates = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/delta/get-expiry-dates', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Get Common strikes
const getDiagonalCommonStrikes = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/diagonals/get-common-strikes', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Start Bot
const startBot = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/diagonals/start-bot', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Stop Bot
const stopBot = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/diagonals/stop-bot', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// Market Close
const marketClose = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/diagonals/market-close-diagonal', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}


export {
  getDiagonalExpiryDates,
  getDiagonalCommonStrikes,
  startBot,
  stopBot,
  marketClose
}
