import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes";
import { profileSuccess, profileError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  UpdateProfile,
} from "../../../helpers/user_helper"
import { toast } from "react-toastify";

const fireBaseBackend = getFirebaseBackend();

function* editProfile({ payload: { user } }) {
  try {
      const response = yield call(UpdateProfile, {
        first_name: user.first_name,
        last_name:user.last_name,
        password: user.password,
        time_zone:user.time_zone
      })      
      yield put(profileSuccess("Profile Updated Successfully"));
      toast.success("Profile Updated Successfully");
  } catch (error) {
    yield put(profileError(error));
    toast.error(error[1]);
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
