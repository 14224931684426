import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Label,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Button
} from "reactstrap";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Select from "react-select"
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import api from '../../../services/api.service';
import Swal from 'sweetalert2';
import { getContractValue, formatUTCDate, formatUTCDateTime, capitalizeFirst, getOrderMarketAction, getCCXTOrderStatusStyle, getTrendmethodStyle, getPrices, counterRenderer, getExchangeName, timeFormat, getOrderStatusStyle } from "../../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import HistoryModal from "../../../components/Modal/HistoryModal";
import "react-rangeslider/lib/index.css"
import { getCCXTAllExchanges, getCCXTExchangeMarkets, getCCXTUserExchanges } from "helpers/ccxt_helper"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ListOptions = (props) => {
document.title = "Spot Trade - Completed Orders";
    firebase.auth();
    const { SearchBar } = Search;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const contractValue = getContractValue('BTCUSDT');
    const firestoreDb = firebase.firestore();
    const firestoreDbUser = firestoreDb.collection("users").doc(uid);
    //const ordersRef = firestoreDbUser.collection("ccxt_orders_history").orderBy("created_at", "desc"); ;  
    const [orderData, setOrderData] = useState([]);
    const [ordersPNL, setOrdersPNL] = useState([]);
    const [ordersPNLDisplay, setOrdersPNLDisplay] = useState([]);
    const [stopOrderFlag, setStopOrderFlag] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ttop, setttop] = useState(false);
    const [popovertop, setpopovertop] = useState(false);

    // const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
    let defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 1);
    defaultStartDate.setHours(0, 0, 0, 0);
    defaultStartDate = defaultStartDate.toISOString();
    // const defaultEndDate = new Date(new Date()).toISOString();

    let defaultEndDate = new Date();
    defaultEndDate.setHours(23, 59, 59, 999);
    defaultEndDate = defaultEndDate.toISOString();

    const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
    const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);
    const [ccxtAllExchanges, setCCXTAllExchanges] = useState([]);
    const [ccxtExchangeMarkets, setCCXTExchangeMarkets] = useState([]);
    const [filter_exchange, setFilterExchange] = useState("all");
    const [filter_markets, setFilterMarkets] = useState('');
    const [data_loader, setDataLoader] = useState({ loading: false });
    const [symbol_loader, setSymbolLoader] = useState({ loading: false });
    const [visibleID, setVisibleID] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [symbolHide, setSymbolHide] = useState(false);
    const [filterSymbol,setFilterSymbol] = useState('all');
    //for settings -end

    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        } else if (err.data.message) {
            toast.error(err.data.message);
        } else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    const popovertopchange = targetName => {
        if (!popovertop[targetName] || !popovertop[targetName].popover) {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: true
                }
            });
        } else {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: false
                }
            });
        }
    };

    const ispopoverOpen = targetName => {
        return popovertop[targetName] ? popovertop[targetName].popover : false;
    };



    const getOrders = useCallback(async () => {
        setDataLoader({ loading: true });
        var body = {
            filterStartDate: filterStartDate,
            filterEndDate: filterEndDate
        }
        await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history', body).then(async (res) => {
            var completedorders = res.data.response.data;
            // list = []
            // completedorders.filter(onlyUnique)
            // let dropdown_data = [{label: 'All', value: 'all'}]
            // for (var i = 0; i < list.length; i++) {
            //     dropdown_data.push({label: list[i], value: list[i]})
            // }
            // setfilter_dropdown(dropdown_data)
            let totalpnl = {}
            const items = [];
            if (completedorders !== undefined && completedorders !== null) {
                completedorders.forEach((doc) => {
                    let item = doc.data;
                    if (item.pnl_value && item.pnl_value !== "") {
                        if (totalpnl[item.settings.quote]) {
                            totalpnl[item.settings.quote] = Number(totalpnl[item.settings.quote]) + Number(item.pnl_value);
                        } else {
                            totalpnl[item.settings.quote] = item.pnl_value;
                        }
                    }
                    items.push(item)
                });
            } 

            setOrdersPNL(totalpnl)
            setOrderData(items);
            setDataLoader({ loading: false });
        });

    }, [contractValue]);

    useEffect(() => {
        getOrders();
        getCCXTAllExchangesList()
        //getMarketsList()
        //pnldisplay();
    }, []);
    let list = []
    const [filter_dropdown,setfilter_dropdown]= useState([])
    function onlyUnique(value, index, array) {
      if (list.includes(value.data.symbol) === false){
        list.push(value.data.symbol)
      }
      // return array.indexOf(value.data.symbol) === index;
    }
    //Get Exchanges list
    const getCCXTAllExchangesList = async () => {
        try {
            await getCCXTUserExchanges().then((res) => {
                setCCXTAllExchanges(res.data.response.exchanges);
            }).catch(e => {
                throw e
            })
        } catch (e) {
            setIsLoading(false);
            handleError(e);
        }
    }
    const handleSelect = (e) => {
        setFilterMarkets(e);
        handleSymbolFilterChange(e)
      } 
    const getMarketsList = async (exchange_id) => {
        try {
            // await getCCXTExchangeMarkets(exchange_id).then((res) => {
            //     console.log(res.data.response.markets,'################################')
            //     setCCXTExchangeMarkets(res.data.response.markets);
            //     setSymbolHide(false)
            // }).catch(e => {
            //     throw e
            // })
            if (exchange_id == "all") {
                setCCXTExchangeMarkets([])
                setFilterMarkets([{label: "All" , value: "all"}])
            }else{
                setFilterMarkets([{label: "All" , value: "all"}])
                setCCXTExchangeMarkets([])
            await api({ contentType: true, auth: true }).get('/ccxt/get-spot-symbols-list/'+exchange_id).then((res) => {
                // console.log('res.data....',res.data);
                //setSpotPairs
            const symbolsListResponse = res.data.response.symbols;
            var  add = {label: "All" , value: "all"}
            var result = symbolsListResponse.map(item => {
              return {
                label: item.id.replace('_', '/'),
                value: item.id
              }
            }) 
            result.splice(0, 0, add);
            console.log(result,'result')
            setCCXTExchangeMarkets(result);
              });
            }
        } catch (e) {
            setSymbolHide(true);
            setCCXTExchangeMarkets([])
            //handleError(e);
        }
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleCreatedDateChange = async (selectedDates, datestr) => {
        // setfilter_dropdown([{label: 'All', value: 'all'}])
        // setFilterMarkets([{label: "All" , value: "all"}])
        if (selectedDates && selectedDates.length == 2) {
            let converted_date = new Date(convert(selectedDates[0]));
            converted_date.setHours(0, 0, 0, 0);
            setFilterStartDate(converted_date.toISOString());
            let converted_end_date = new Date(convert(selectedDates[1]));
            if (selectedDates[1]) {
                converted_end_date.setHours(23, 59, 59, 999);
                setFilterEndDate(converted_end_date.toISOString());
            }

            if (filter_exchange != "all") {
                setDataLoader({ loading: true });
                var body = {
                    filterStartDate: converted_date.toISOString(),
                    filterEndDate: converted_end_date.toISOString(),
                    filter_exchange: filter_exchange,
                    symbol : filterSymbol
                }
                let dynamicEndpoint;
                if(filterSymbol!= 'all') {
                     dynamicEndpoint = "/CCXT/ccxt-completed-orders-history-2";
                }else {
                    dynamicEndpoint = "/CCXT/ccxt-completed-orders-history-1" 
                }
                await api({ contentType: true, auth: true }).post(dynamicEndpoint, body).then(async (res) => {
                    var completedorders = res.data.response.data;
                    // completedorders.filter(onlyUnique)
                    if(filterSymbol == 'all') {
                    list = res.data.response.symbols;
                    let dropdown_data = [{label: 'All', value: 'all'}]
                    for (var i = 0; i < list.length; i++) {
                        dropdown_data.push({label: list[i], value: list[i]})
                    }
                    setfilter_dropdown(dropdown_data)
                    }

                    // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("exchange_id", '==', filter_exchange).where("created_at", '>=', converted_date.toISOString()).where("created_at", '<=', converted_end_date.toISOString()).orderBy("created_at", "desc").get().then(async(allOrders) => {            
                    let orders_info = []; let totalpnl = {};
                    new Promise((resolve, reject) => {
                        if (completedorders !== undefined && completedorders !== null) {
                            completedorders.forEach(function (doc) {
                                var data = doc.data;
                                if (data.pnl_value && data.pnl_value !== "") {
                                    if (totalpnl[data.settings.quote]) {
                                        totalpnl[data.settings.quote] = Number(totalpnl[data.settings.quote]) + Number(data.pnl_value);
                                    } else {
                                        totalpnl[data.settings.quote] = data.pnl_value;
                                    }
                                }
                                orders_info.push(data);
                            })
                        }
                    })

                    if (orders_info.length > 0) {
                        setOrdersPNL(totalpnl)
                        setOrderData(orders_info);
                        setDataLoader({ loading: false });
                    }
                    else {
                        //setOrdersPNL(0)
                        setOrderData([])
                        setDataLoader({ loading: false });
                    }
                })
            }
            else {
                setDataLoader({ loading: true });

                var body = {
                    filterStartDate: converted_date.toISOString(),
                    filterEndDate: converted_end_date.toISOString()
                }
                await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history', body).then(async (res) => {
                    var completedorders = res.data.response.data;
                    list = []

                    // completedorders.filter(onlyUnique)
                    // let dropdown_data = [{label: 'All', value: 'all'}]
                    // for (var i = 0; i < list.length; i++) {
                    //     dropdown_data.push({label: list[i], value: list[i]})
                    // }
                    // setfilter_dropdown(dropdown_data)
                    // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("created_at", '>=', converted_date.toISOString()).where("created_at", '<=', converted_end_date.toISOString()).orderBy("created_at", "desc").get().then(async(allOrders) => {
                    let orders_info = []; let totalpnl = {};
                    if (completedorders !== undefined && completedorders !== null) {
                        completedorders.forEach(function (doc) {
                            var data = doc.data;
                            if (data.pnl_value && data.pnl_value !== "") {
                                if (totalpnl[data.settings.quote]) {
                                    totalpnl[data.settings.quote] = parseFloat(totalpnl[data.settings.quote]) + parseFloat(data.pnl_value);
                                } else {
                                    totalpnl[data.settings.quote] = data.pnl_value;
                                }
                            }
                            orders_info.push(data);
                        })
                    }
                    if (orders_info.length > 0) {
                        setOrdersPNL(totalpnl)
                        setOrderData(orders_info);
                        setDataLoader({ loading: false });
                    }
                    else {
                        //setOrdersPNL(0)
                        setOrderData([])
                        setDataLoader({ loading: false });
                    }
                })
            }
        } else {
            setFilterStartDate(defaultStartDate);
            setFilterEndDate(defaultEndDate);
        }
    }

    const handleFilterChange = async (e) => {

        const exchange_id = e.target.value;
        if (exchange_id != "all") {
            setSymbolLoader({ loading: true });
            setDataLoader({ loading: true });
            var body = {
                filterStartDate: filterStartDate,
                filterEndDate: filterEndDate,
                filter_exchange: exchange_id
            }
            await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history-1', body).then(async (res) => {
                var completedorders = res.data.response.data;
                // list = []
                // completedorders.filter(onlyUnique)
                let dropdown_data = [{label: 'All', value: 'all'}]
                list = res.data.response.symbols;
                for (var i = 0; i < list.length; i++) {
                    dropdown_data.push({label: list[i], value: list[i]})
                }
                setfilter_dropdown(dropdown_data)
                setFilterSymbol('all')
                // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("exchange_id", '==', exchange_id).where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc").get().then(async(allOrders) => {
                let orders_info = []; let totalpnl = {};
                if (completedorders !== undefined && completedorders !== null) {
                    completedorders.forEach(function (doc) {
                        var data = doc.data;
                        if (data.pnl_value && data.pnl_value !== "") {
                            if (totalpnl[data.settings.quote]) {
                                totalpnl[data.settings.quote] = parseFloat(totalpnl[data.settings.quote]) + parseFloat(data.pnl_value);
                            } else {
                                totalpnl[data.settings.quote] = data.pnl_value;
                            }
                        }
                        orders_info.push(data);
                    })
                }
                if (orders_info.length > 0) {
                    setOrdersPNL(totalpnl)
                    setOrderData(orders_info);
                    setDataLoader({ loading: false });
                    setSymbolLoader({ loading: false });
                }
                else {
                    //setOrdersPNL(0)
                    setOrderData([])
                    setDataLoader({ loading: false });
                    setSymbolLoader({ loading: false });
                }
            })
        }
        else {
            setDataLoader({ loading: true });
            setSymbolLoader({ loading: true });
            var body = {
                filterStartDate: filterStartDate,
                filterEndDate: filterEndDate
            }
            await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history', body).then(async (res) => {
                var completedorders = res.data.response.data;

                setFilterSymbol('all');
                // list = []

                // completedorders.filter(onlyUnique)
                // let dropdown_data = [{label: 'All', value: 'all'}]
                // for (var i = 0; i < list.length; i++) {
                //     dropdown_data.push({label: list[i], value: list[i]})
                // }
                // setfilter_dropdown(dropdown_data)
                // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc").get().then(async(allOrders) => {
                let orders_info = []; let totalpnl = {};
                if (completedorders !== undefined && completedorders !== null) {
                    completedorders.forEach(function (doc) {
                        var data = doc.data;
                        if (data.pnl_value && data.pnl_value !== "") {
                            if (totalpnl[data.settings.quote]) {
                                totalpnl[data.settings.quote] = parseFloat(totalpnl[data.settings.quote]) + parseFloat(data.pnl_value);
                            } else {
                                totalpnl[data.settings.quote] = data.pnl_value;
                            }
                        }
                        orders_info.push(data);
                    })
                }

                if (orders_info.length > 0) {
                    setOrdersPNL(totalpnl)
                    setOrderData(orders_info);
                    setDataLoader({ loading: false });
                    setSymbolLoader({ loading: false });
                }
                else {
                    //setOrdersPNL(0)
                    setOrderData([])
                    setDataLoader({ loading: false });
                    setSymbolLoader({ loading: false });
                }
            })

        }

    }


    const handleSymbolFilterChange = async (e) => {

        const filter_symbol = e.value;
        setFilterSymbol(filter_symbol);
        if (filter_exchange != "all" && filter_symbol != "all") {
            setDataLoader({ loading: true });
            var body = {
                filterStartDate: filterStartDate,
                filterEndDate: filterEndDate,
                filter_exchange: filter_exchange,
                symbol: filter_symbol
            }
            await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history-2', body).then(async (res) => {
                var completedorders = res.data.response.data;
                // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("exchange_id", '==', filter_exchange).where("symbol", '==', filter_symbol).where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc").get().then(async(allOrders) => {
                let orders_info = []; let totalpnl = {};
                if (completedorders !== undefined && completedorders !== null) {
                    completedorders.forEach(function (doc) {
                        var data = doc.data;
                        if (data.pnl_value && data.pnl_value !== "") {
                            if (totalpnl[data.settings.quote]) {
                                totalpnl[data.settings.quote] = parseFloat(totalpnl[data.settings.quote]) + parseFloat(data.pnl_value);
                            } else {
                                totalpnl[data.settings.quote] = data.pnl_value;
                            }
                        }
                        orders_info.push(data);
                    })
                }

                if (orders_info.length > 0) {
                    setOrdersPNL(totalpnl)
                    setOrderData(orders_info);
                    setDataLoader({ loading: false });
                }
                else {
                    //setOrdersPNL(0)
                    setOrderData([])
                    setDataLoader({ loading: false });
                }
            })
        }
        else {
            setDataLoader({ loading: true });
            var body = {
                filterStartDate: filterStartDate,
                filterEndDate: filterEndDate,
                filter_exchange: filter_exchange
            }
            await api({ contentType: true, auth: true }).post('/CCXT/ccxt-completed-orders-history-1', body).then(async (res) => {
                var completedorders = res.data.response.data;
                // list = []
                // completedorders.filter(onlyUnique)
                let dropdown_data = [{label: 'All', value: 'all'}]
                list = res.data.response.symbols;
                for (var i = 0; i < list.length; i++) {
                    dropdown_data.push({label: list[i], value: list[i]})
                }
                setfilter_dropdown(dropdown_data)
                // const exchange_filter = firestoreDbUser.collection("ccxt_orders_history").where("exchange_id", '==', filter_exchange).where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc").get().then(async(allOrders) => {
                let orders_info = []; let totalpnl = {};
                if (completedorders !== undefined && completedorders !== null) {
                    completedorders.forEach(function (doc) {
                        var data = doc.data;
                        if (data.pnl_value && data.pnl_value !== "") {
                            if (totalpnl[data.settings.quote]) {
                                totalpnl[data.settings.quote] = parseFloat(totalpnl[data.settings.quote]) + parseFloat(data.pnl_value);
                            } else {
                                totalpnl[data.settings.quote] = data.pnl_value;
                            }
                        }
                        orders_info.push(data);
                    })
                }

                if (orders_info.length > 0) {
                    setOrdersPNL(totalpnl)
                    setOrderData(orders_info);
                    setDataLoader({ loading: false });
                }
                else {
                    //setOrdersPNL(0)
                    setOrderData([])
                    setDataLoader({ loading: false });
                }
            })

        }

    }

    const InfoPopoverCell = ({ row }) => {
        const [showPopover, setShowPopover] = useState(false);
        const trend_method = row.settings.trend_method ? getTrendmethodStyle(row.settings.trend_method) : '';
        return (
            <div className="border p-2">
                <b className="text-primary font-size-15">{getExchangeName(row.exchange_id)}</b>
                <div style={{ float: "right" }}>
                    <OverlayTrigger
                        trigger="click"
                        show={showPopover}
                        onToggle={() => setShowPopover(!showPopover)}
                        placement="right"
                        overlay={
                            <Popover id={`popover-${row.id}`}>
                                <Popover.Header
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        borderBottomColor: "white"
                                    }}
                                >
                                    <b>Info</b>
                                    <button
                                        onClick={() => setShowPopover(false)}
                                        style={{
                                            float: 'right',
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            fontSize: '20px',
                                            padding: '0px',
                                            marginTop: '-4px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}
                                    >
                                        &times;
                                    </button>
                                </Popover.Header>
                                <Popover.Body style={{ backgroundColor: "black", color: "white" }}>
                                    Fill type: <b>{row.settings.fill_type.length !== 0 ? (row.settings.fill_type === 'instant_fill' ? 'Instant Fill' : 'Wait and Fill') : '--'}</b><br />
                                    {row.settings.fill_type !== "instant_fill" && row.settings.price_distance_percentage && row.settings.price_distance_percentage.length !== 0
                                        ? <> Price Distance % for Wait and Fill: <b>{row.settings.price_distance_percentage}</b><br /></>
                                        : ''
                                    }
                                    Market Stop Loss: <b>{row.settings.stop_loss && row.settings.stop_loss.length !== 0 ? `${row.settings.stop_loss}${row.settings.stop_loss_type === "points" ? 'Pts' : "%"}` : '--'}</b><br />
                                    Minimum Take Profit%: <b>{row.settings.min_take_profit_percentage ? row.settings.min_take_profit_percentage : '--'}</b><br />
                                    Trailing Take Profit%: <b>{row.settings.trailing_take_profit_percentage ? row.settings.trailing_take_profit_percentage : '--'}</b><br />
                                    Trend signal method: <b>{row.settings.trend_method ? row.settings.trend_method : '--'}</b><br />
                                    {row.settings.trend_method === "sma"
                                        ? <>
                                            Time frame: <b>{row.settings.time_frame ? row.settings.time_frame : '15m'}</b><br />
                                            Short length: <b>{row.settings.short_term ? row.settings.short_term : '10'}</b><br />
                                            Long length: <b>{row.settings.long_term ? row.settings.long_term : '100'}</b><br />
                                        </>
                                        : row.settings.trend_method === "ema"
                                        ? <>
                                            Time frame: <b>{row.settings.ema_time_frame ? row.settings.ema_time_frame : '15m'}</b><br />
                                            Short length: <b>{row.settings.ema_short_term ? row.settings.ema_short_term : '8'}</b><br />
                                            Long length: <b>{row.settings.ema_long_term ? row.settings.ema_long_term : '20'}</b><br />
                                        </>
                                        : row.settings.trend_method === "macd"
                                        ? <>
                                            Time frame: <b>{row.settings.macd_time_frame ? row.settings.macd_time_frame : "15m"}</b><br />
                                            Short length: <b>{row.settings.macd_short_term ? row.settings.macd_short_term : '12'}</b><br />
                                            Long length: <b>{row.settings.macd_long_term ? row.settings.macd_long_term : '26'}</b><br />
                                            Signal length: <b>{row.settings.macd_signal_term ? row.settings.macd_signal_term : '9'}</b><br />
                                        </>
                                        : row.settings.trend_method === "supertrend"
                                        ? <>
                                            Time frame: <b>{row.settings.st_time_frame ? row.settings.st_time_frame : "15m"}</b><br />
                                            ATR length: <b>{row.settings.st_atr_length ? row.settings.st_atr_length : '10'}</b><br />
                                            Factor: <b>{row.settings.st_factor ? row.settings.st_factor : '3'}</b><br />
                                            Min supertrend distance: <b>{row.settings.st_min_distance ? row.settings.st_min_distance : '--'}</b><br />
                                            Max supertrend distance: <b>{row.settings.st_max_distance ? row.settings.st_max_distance : '--'}</b><br />
                                        </>
                                        : <>
                                            Time frame: 15m<br />
                                            Short length: 10<br />
                                            Long length: 100<br />
                                        </>
                                    }
                                    Order Side : <b>{row.settings.order_side && row.settings.order_side ? row.settings.order_side : "both"}</b> <br />
                                    {row.settings.order_side === "sell" && row.settings.min_sell_price ? <>Minimum Sell Price: <b>{row.settings.min_sell_price}</b><br /></> : ''}
                                    {row.settings.order_side === "buy" && row.settings.max_buy_price ? <>Maximum Buy Price: <b>{row.settings.max_buy_price}</b><br /></> : ''}
                                    Waiting Time for Order after Stop Loss: <b>{row.settings.waiting_time_for_next_order_stop_loss && row.settings.waiting_time_for_next_order_stop_loss !== '' ? timeFormat(row.settings.waiting_time_for_next_order_stop_loss) : '--'}</b><br />
                                    Waiting Time for Order after Trailing Profit: <b>{row.settings.waiting_time_for_next_order_trailing_profit && row.settings.waiting_time_for_next_order_trailing_profit !== '' ? timeFormat(row.settings.waiting_time_for_next_order_trailing_profit) : '--'}</b><br />
                                    {row.settings.settings_quantity?<>Quantity: {Number(row.settings.settings_quantity)}{row.settings.quantity_type === 'balance_per' ? '% (quote margin)' : row.settings.quantity_type === 'balance_per_base' ? '% (base margin)' : ''}</>:''}
                                </Popover.Body>
                            </Popover>
                        }
                        rootClose
                    >
                        <i
                            className="bx bxs-info-circle text-dark font-size-15" style={{cursor: 'pointer'}}
                            onClick={() => setShowPopover(true)}
                        ></i>
                    </OverlayTrigger>
                </div>
                <div className="text-muted font-size-12">
                    <i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(row.created_at)}
                </div>
                {row.settings.signal ? (
                    <div className="align-text-bottom created-date">
                        <span title={trend_method.fullform}>{trend_method.name}</span> Signal: {row.settings.signal === 'uptrend' ? (
                            <>
                                <span className="text-success font-size-18" style={{ "verticalAlign": "middle" }}>
                                    <i className="bx bx-caret-up"></i>
                                </span>Uptrend
                            </>
                        ) : (
                            <>
                                <span className="text-danger font-size-18" style={{ "verticalAlign": "middle" }}>
                                    <i className="bx bx-caret-down"></i>
                                </span>Downtrend
                            </>
                        )}
                    </div>
                ) : ''}
            </div>
        );
    };

    const columns = [
    {
        dataField: 'exchange_id',
        text: 'Exchange',
        sort: false,
        headerStyle: { width: "6%" },
        formatter: (value, row) => <InfoPopoverCell row={row} />
    },
        { dataField: 'direction', text: 'Side', formatter: (cell) => { return <>{<span className={cell === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{cell.toUpperCase()}</span>}</> }, sort: false, headerStyle: { width: "5%" } },
        { dataField: 'settings.trading_pair', text: 'Symbol', formatter: (value, row) => {return <>{<><span className={row.direction === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{row.settings.trading_pair.toUpperCase()}</span>{row.created_by_manual && row.created_by_manual== 1 ?<div><span className="text-primary  font-size-11 ">Manually created</span></div>:''}<div className={row.order_trigger_type && row.order_trigger_type == "stop_loss" && row.trailing_stop_loss_price && row.trailing_stop_loss_price > 0 ?"text-success font-size-11" :row.order_trigger_type && row.order_trigger_type == "stop_loss" ? "text-danger font-size-11 " : "text-muted font-size-11"}>{row.market_close==1?"Triggered Market Close":row.order_trigger_type && row.order_trigger_type == "stop_loss" && row.trailing_stop_loss_price && row.trailing_stop_loss_price > 0 ? "Triggered Tailing Profit" : row.order_trigger_type && row.order_trigger_type == "stop_loss" ? "Triggered Stop Loss" : row.is_manual_stop_triggered == 1 ? "Manually stopped" : ""} </div></>}</> }, sort: false, headerStyle: { width: "5%" } },
        { dataField: 'price', text: 'Sell/Buy Price', sort: false, headerStyle: { width: "5%" }, formatter: (value, row) => { return <>{<span className={row.direction === "buy" ? "text-success" : "text-danger"} title={"(" + row.price + " x " + row.settings.quantity + " = " + (row.price * row.settings.quantity) + ")"}>{Number((row.price * row.settings.quantity)).toFixed(5) + ' ' + row.settings.quote}{<div><small className="text-muted" title={row.price}>{"(" + Number(row.price).toFixed(5) + ' ' + row.settings.quote + ")"}</small></div>}</span>}</> } },
        { dataField: 'settings.quantity', text: 'Qty', sort: false, headerStyle: { width: "10%" } },
        { dataField: 'order_id', text: 'Order ID', sort: false, headerStyle: { width: "10%" } },
        { dataField: 'status', text: 'Status', sort: false, headerStyle: { width: "6%" }, formatter: (value, row) => { return <> <span dangerouslySetInnerHTML={{ __html: getCCXTOrderStatusStyle(row) }}></span></> } },
        { dataField: 'pnl', text: 'PnL', sort: false, headerStyle: { width: "6%" }, formatter: (value, row) => { return <> <small title={row.pnl_value ? row.pnl_value : ''} className={row.pnl_value ? row.pnl_value >= 0 ? 'text-success' : 'text-danger' : ''}>{row.pnl_value ? parseFloat(row.pnl_value) + ' ' + row.settings.quote : '...'}{row.pnl_qty ? <div>{"(for qty " + row.pnl_qty + ")"}</div> : ""}</small></> } },

    ];

    const data = orderData;
    const options = {
        page: 1,
        sizePerPage: 100,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        hideSizePerPage: true,
        custom: true
    };

    const defaultSorted = [
        {
            dataField: "created_at", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];
    let classvalue = "table-light table-text-color-light";
    const rowStyleFormat = (row) => {
        if (filter_exchange !== "all" && filterSymbol!="all") {
            if (row.status == "cancelled") {
                //  classvalue=classvalue=="table-light table-text-color-light"?"order-row table-text-color-light":"table-light table-text-color-light"; 
            } else if (row.order_trigger_type != "base") {
                classvalue = classvalue == "table-light table-text-color-light" ? "order-row table-text-color-light" : "table-light table-text-color-light";
            }
        } else {
            classvalue = "order-row table-text-color-light";
        }
        return classvalue;
    }
    const emptyDataMessage = () => { return data_loader.loading ? 'Loading...' : 'No Data Found' }
    const pnldisplay = () => {
        let returnval = "";
        Object.keys(ordersPNL).forEach(function (key) {
            let prepend_string = returnval == "" ? "" : returnval + " , ";
            if (Number(ordersPNL[key]) < 0) {
                returnval = prepend_string + "<span title=" + ordersPNL[key] + " class='text-danger'>" + parseFloat(ordersPNL[key]) + " " + key + "</span>"
            } else {
                returnval = prepend_string + "<span title=" + ordersPNL[key] + " class='text-success'>" + parseFloat(ordersPNL[key]) + " " + key + "</span>"

            }

        })
        if (returnval == "") { returnval = " NA"; }
        return returnval

    }
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Completed Orders" />
                    <Row>
                        <Col lg="12">
                            <Card className="border box_4">
                                <CardBody>
                                    <Row>
                                        <Col xxl={1} lg={6} className="d-flex align-items-center">
                                            <h5><b>Filter</b></h5>
                                        </Col>
                                        <Col xxl={2} lg={6}>
                                            <Label htmlFor="Created">Created date</Label>
                                            <Flatpickr className="form-control d-block"
                                                placeholder="Created Date"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d/m/Y",
                                                    defaultDate: [filterStartDate, filterEndDate]
                                                }}
                                                onChange={handleCreatedDateChange}
                                            />
                                        </Col>

                                        <Col xxl={2} lg={6}>
                                            <Label htmlFor="status">Exchange</Label>
                                            <select name="select_exchange" className="form-select" onChange={(e) => { handleFilterChange(e), setFilterExchange(e.target.value), getMarketsList(e.target.value) }}>
                                                <option value={"all"} > All </option>
                                                {ccxtAllExchanges.map((exchange, index) => (
                                                    <React.Fragment key={index}>
                                                        <option value={exchange.exchange_id} > {getExchangeName(exchange.exchange_id)} </option>
                                                    </React.Fragment>
                                                ))}
                                            </select>
                                        </Col>

                                        {!symbol_loader.loading && filter_exchange != 'all' && !symbolHide ?
                                            <Col xxl={2} lg={6}>
                                                <Label htmlFor="status">Symbol</Label>
                                                {/* <select name="select_exchange" className="form-select" onChange={(e) => { handleSymbolFilterChange(e), setFilterMarkets(e.target.value) }}> */}
                                                    {/* <option value={"all"} > All </option> */}
                                                    <Select id="select_add_pair" placeholder="All" value={filter_markets} onChange={(e) => {handleSelect(e)} } options={filter_dropdown} classNamePrefix="select2-selection"></Select>
                                                    {/* {ccxtExchangeMarkets.map((markets, index) => (
                                                        <React.Fragment key={index}>
                                                            <option value={markets.value} > {markets.value} </option>
                                                        </React.Fragment>
                                                    ))} */}
                                                {/* </select> */}
                                            </Col>
                                            : ''}
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="box_4">

                                <CardBody>
                                    <h4 className="card-title mb-3">Orders</h4>

                                    {orderData && orderData.length > 0 ? (
                                        <PaginationProvider
                                            pagination={paginationFactory(options)}
                                            keyField="order_id"
                                            columns={columns}
                                            data={data}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="order_id"
                                                    columns={columns}
                                                    data={data}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            {/* <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row> */}

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    {filterStartDate && filterEndDate ? <span className="fw-bold">Total PNL for the data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)} :  {" "}
                                                                        <span dangerouslySetInnerHTML={{ __html: pnldisplay() }}></span> </span> : ''}
                                                                </Col>
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto pagination pagination-rounded justify-content-end">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            responsive
                                                                            defaultSorted={defaultSorted}
                                                                            bordered={true}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-check"
                                                                            }
                                                                            headerWrapperClasses={"table-light"}
                                                                            noDataIndication={emptyDataMessage}
                                                                            rowClasses={rowStyleFormat}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto pagination pagination-rounded justify-content-end">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    ) : <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3 ">
                                                {data_loader.loading ? 'Loading...' : 'No orders found'}

                                            </div>
                                        </Col>
                                    </Row>}
                                </CardBody>


                                {/* <CardBody>
                                    <h4 className="card-title mb-3">Orders</h4>

                                        <div className="table-responsive">                                                                                            
                                                <Table className="table table-bordered table align-middle table-nowrap table-check  caption-top-dark">
                                                    <thead >
                                                        <tr>
                                                            <th>Exchange</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sell/Buy Price</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th>PnL</th>
                                                        </tr>
                                                    </thead>
                                                    {orderData.length > 0 ? (
                                                        orderData.map((order, index) => {
                                                        let market_action =order.order_trigger_type && order.order_trigger_type=="stop_loss"?"Triggered Stop loss":""
                                                        let trend_method=order.settings.trend_method?getTrendmethodStyle(order.settings.trend_method):''
                                                        return (
                                                                <tbody key={index}>
                                                                 
                                                                           
                                                                    
                                                                                <tr className="trow-hover" key={index}>
                                                                                
                                                                                    <td className=" p-2 ">
                                                                                        <div className="border p-2">
                                                                                        <b className="text-primary font-size-15">{getExchangeName(order.exchange_id)}</b>
                                                                                        <div style={{"float":"right"}}><a
                                                                                                id={"Popovertop-open-" + order.trend_signal_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`open-${order.trend_signal_id}`);
                                                                                                }}
                                                                                            >
                                                                                              <i className="bx bxs-info-circle text-primary font-size-15"></i>
                                                                                            </a>
                                                                                            <Popover
                                                                                                placement="right"
                                                                                                isOpen={ispopoverOpen(`open-${order.trend_signal_id}`)}
                                                                                                target={"Popovertop-open-"+ order.trend_signal_id}
                                                                                                toggle={() => {
                                                                                                    popovertopchange(`open-${order.trend_signal_id}`);
                                                                                                }}
                                                                                                >
                                                                                                <PopoverHeader>Info</PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    Fill type:  {order.settings.fill_type == 'instant_fill' ? 'Instant Fill' : 'Wait and Fill' }  <br />
                                                                                                    Trend signal method: {order.settings.trend_method}
                                                                                                    <br />
                                                                                                    {order.settings.trend_method=="sma"?
                                                                                                    <>
                                                                                                    Time frame: {order.settings.time_frame}<br />
                                                                                                    Short length: {order.settings.short_term}<br />
                                                                                                    Long length: {order.settings.long_term}<br />
                                                                                                    </>:order.settings.trend_method=="macd"? 
                                                                                                    <>
                                                                                                     Time frame: {order.settings.macd_time_frame}<br />
                                                                                                    Short length: {order.settings.macd_short_term}<br />
                                                                                                    Long length: {order.settings.macd_long_term}<br />
                                                                                                    Signal length: {order.settings.macd_signal_term}<br />
                                                                                                    </>: order.settings.trend_method=="supertrend"?
                                                                                                    <>
                                                                                                    Time frame: {order.settings.st_time_frame}<br />
                                                                                                    ATR length: {order.settings.st_atr_length}<br />
                                                                                                    Factor: {order.settings.st_factor}
                                                                                                    <br />
                                                                                                    Minimum supertrend distance:  {order.settings.st_min_distance}<br />
                                                                                                    Maximum supertrend distance:  {order.settings.st_max_distance}
                                                                                                    </>
                                                                                                    :<>
                                                                                                    Time frame: 15m<br />
                                                                                                    Short length: 10<br />
                                                                                                    Long length: 100<br />
                                                                                                    </>
                                                                                                    }
                                                                                                </PopoverBody>
                                                                                            </Popover>
                                                                                            </div>
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}</div>
                                                                                            {order.settings.signal ? <div className="align-text-bottom created-date"><span title={trend_method.fullform}>{trend_method.name}</span> Signal:{order.settings.signal == 'uptrend' ? <><span className="text-success font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-up"></i></span>Uptrend</> : <><span className="text-danger font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-down"></i></span>Downtrend</>}</div> : ''}
                                                                                        </div>
                                                                                    </td>
                                                                                  
                                                                                <td>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                            
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                    {order.settings.trading_pair}
                                                                                    </Badge>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                                        </div>
                                                                                        : ''}
                                                                                </td>

                                                                                <td>
                                                                                    <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{Number(order.price).toFixed(2)}</h6>
                                                                                    {order.highest_bid_now || order.lowest_ask_now ?
                                                                                        <div>
                                                                                            <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                                                            <span className="text-muted">, </span>
                                                                                            <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                                                        </div>
                                                                                        : ""
                                                                                    }
                                                                                </td>
                                                                               
                                                                                <td>{Number(order.settings.quantity)}</td>
                                                                                <td className="font-size-11">{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td> <span dangerouslySetInnerHTML={{ __html: getCCXTOrderStatusStyle(order) }}></span></td>                                                                                                                                                                                                                                          
                                                                               
                                                                            </tr>
                                                                      
                                                              
                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    {data_loader.loading ? 'Loading...':'No orders found'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>                                                
                                            </div>
                                     
                                   
                                </CardBody> */}

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*Listing orders */}
            <div className="page-content">

            </div>
            <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ symbol: "", show: false })}
                data={historyData}
                symbolname={modalShow.symbol}
            />
        </React.Fragment>
    );
}
export default ListOptions;
