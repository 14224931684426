import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import {layoutModeTypes} from '../../constants/layout'
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, UncontrolledTooltip } from "reactstrap";

import { changeLayout,changeLayoutMode } from "../../store/actions";
// Import menuDropdown 
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from "../CommonForBoth/RightSidebar";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.png";
import logoLight from "../../assets/images/full-logo-light.png";
import logoLightSvg from "../../assets/images/logo_light.png";
import logoDark from "../../assets/images/logo-color.png";

import logoLightPng from "../../assets/images/full-logo-light.png";
// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import store from "../../store";


//i18n
import { withTranslation } from "react-i18next";

import MarketPrice from "components/MarketPrice";
import firebase from "firebase/compat/app";
import api from "../../services/api.service"
import moment from "moment";

const getUserName = () => {
  if (localStorage.getItem("user")) {
    const obj = JSON.parse(localStorage.getItem("user"))
    return obj;
  }
}
async function UpdateProfileData(data){
  let data2 = {theme_mode : data}
  let user = JSON.parse(localStorage.getItem("user"));
  user.profile.theme_mode = data2.theme_mode;
  localStorage.setItem("user", JSON.stringify(user));   
  firebase.auth();
  const firestoreDb = firebase.firestore(); 
  let updateData = data2
  updateData.updated_at = moment().toISOString();
  // const firestoreDb1 = firestoreDb.collection("users").doc(userData.uid);  
	// const result = firestoreDb1.collection("profile").doc('data').update(updateData);

  let send_update_data = {
    uid: user.uid,
    data: updateData
}
  const result =  await api({ contentType: true, auth: true }).post('/user/update-user',send_update_data)
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
      user_details:'',
      layoutModeType: this.props.layoutModeType,
    };
    this.changeLayoutMode = this.changeLayoutMode.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }
  componentDidMount() {
    this.get_running_orders();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const userData = getUserName();   
    if (userData) {
      this.setState({ user_details:currentUser,name: userData && userData.profile? userData.profile.first_name:'' ,layoutModeType:this.state.layoutModeType })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      const currentUser = JSON.parse(localStorage.getItem('user'));
      if (userData) {        
        this.setState({ user_details:currentUser,name: userData && userData.profile? userData.profile.first_name:'' })
      }
    }
    if (prevProps !== this.props) {
      this.setState({ 
        layoutModeType:this.state.layoutModeType ,
      })
    }
  }
  changeLayoutMode(e) {
    if (e.target.checked) {
      this.props.changeLayoutMode(e.target.value);
      this.setState({ layoutModeType: e.target.value });
    }
  }
  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  switchToSpot=async() => {
    //redirect to ccxt/settings
    window.location.href='/ccxt/settings?pairs'
  }
  get_running_orders = async()=>{
      firebase.auth();
      const firestoreDb = firebase.firestore();
      const currentUser = JSON.parse(localStorage.getItem('user'));
      let uid = currentUser.uid;
      const strangle_open_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'open').get().then(stra_open_order => {
        this.setState({active_orders: stra_open_order.size})
      });
      const strangle_position_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'closed').where('direction', '==', 'sell').get().then(stra_position_order => {
        this.setState({filled_orders:stra_position_order.size})
      });
      const diagonal_open_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'open').get().then(dia_open_order => {
        this.setState({dia_active_orders:dia_open_order.size})
       });
      const diagonal_position_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'closed').get().then(dia_position_order => {
        this.setState({dia_filled_orders:dia_position_order.size})
      });
      const straddle_open_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'open').get().then(straddle_open_order => {
        this.setState({straddle_active_orders:straddle_open_order.size})
      });
      const straddle_position_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'closed').where('bot_status', '==', 'active').get().then(straddle_position_order => {
        this.setState({straddle_filled_orders:straddle_position_order.size}) 
      });
      const trend_open_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'open').get().then(trend_open_order => {
        this.setState({trend_active_orders:trend_open_order.size})
       });
      const trend_position_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'closed').get().then(trend_position_order => {
        this.setState({trend_filled_orders:trend_position_order.size})
      });

    }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    {/* //LogoChange */}
                    <img src={this.props.layoutModeType && this.props.layoutModeType== "dark"?logoLight:logoDark} alt="" height="30" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="29" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form> */}
              {/* <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
                <DropdownToggle className="btn header-item" caret tag="button">
                  {this.props.t("Mega Menu")}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-megamenu">
                  <Row>
                    <Col sm={8}>
                      <Row>
                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("UI Components")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Lightbox")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Range Slider")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Sweet Alert")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Rating")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Forms")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tables")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Charts")}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Applications")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Ecommerce")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Calendar")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Email")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Projects")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tasks")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Contacts")}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Extra Pages")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">
                                {this.props.t("Light Sidebar")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                {this.props.t("Compact Sidebar")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                {this.props.t("Horizontal layout")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#"> {this.props.t("Maintenance")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Coming Soon")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Timeline")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("FAQs")}</Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col sm={6}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("UI Components")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Lightbox")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Range Slider")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Sweet Alert")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Rating")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Forms")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tables")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Charts")}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col sm={5}>
                          <div>
                            <img
                              src={megamenuImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown> */}

               {/* web view */}
               {/* <div className="market-price-box" style={{marginRight:"160px"}}> */}
               {this.state.user_details.is_deltakey_updated ?
                <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
               <div className="d-flex flex-row">
                
                <DropdownToggle className="btn header-item text-white bg-dark" caret tag="button" style={{"height":"43px"}}>
                <span className="fw-bold font-size-12">BTC / ETH</span>
                  <span className="d-block text-dark font-size-13"><MarketPrice /></span>
                </DropdownToggle>
                </div>
              </Dropdown> 
              :''}
              {/* </div> */}
            </div>
            <div className="d-flex" style={{"textAlign":"end"}}>
              {/* <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                >
                  <i className="mdi mdi-magnify" />
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0 w-100">
                      <div className="input-group w-100">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("Search") + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}

              {/* <LanguageDropdown /> */}

              {/* <Dropdown
                className="d-none d-lg-inline-block ms-1"
                isOpen={this.state.socialDrp}
                toggle={() => {
                  this.setState({ socialDrp: !this.state.socialDrp });
                }}
              >
                <DropdownToggle
                  className="btn header-item noti-icon"
                  caret
                  tag="button"
                >
                  <i className="bx bx-customize" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={github} alt="Github" />
                          <span>GitHub</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={bitbucket} alt="bitbucket" />
                          <span>Bitbucket</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dribbble} alt="dribbble" />
                          <span>Dribbble</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dropbox} alt="dropbox" />
                          <span>Dropbox</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={mail_chimp} alt="mail_chimp" />
                          <span>Mail Chimp</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={slack} alt="slack" />
                          <span>Slack</span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown> */}
               <div>
                
                <DropdownToggle className="btn header-item text-white" caret tag="button">
                {/* <Link className="btn btn-primary header-item noti-icon text-center" to="/ccxt/settings"> */}
  <button type="button" className="btn btn-success" onClick={this.switchToSpot}><i className="mdi mdi-swap-horizontal font-size-16 align-middle"></i> Switch to Spot</button>
  {/* </Link> */}
  </DropdownToggle>
  </div>
              <div className="dropdown d-lg-none d-xl-block ms-1">
                {/* <Link className="btn header-item noti-icon text-center" to="/ccxt/settings">
                <i className="mdi mdi-swap-horizontal" id="spot"  style={{fontSize:"30px",color:"#34C38F",cursor:"pointer"}}><UncontrolledTooltip placement="top" target="spot"> 
                Switch to Spot
                </UncontrolledTooltip></i>
               
                  </Link> */}
                
                <button
                  type="button"
                  className="btn header-item noti-icon"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" id="fullscreen" />
                  <UncontrolledTooltip placement="top" target="fullscreen"> 
                {this.props.t("Full Screen")}
                 </UncontrolledTooltip>
                </button>
                

                <label  className="btn header-item noti-icon text-center"  htmlFor="flexSwitchCheckDefault" role="button" style={{margin:"0px"}}>{this.props.layoutModeType === layoutModeTypes.DARK ? <><i  className="bx bx-sun " id="theme_mode" style={{fontSize: "22px",padding:"2px"}}/><UncontrolledTooltip placement="top" target="theme_mode"> 
                Theme Mode
              </UncontrolledTooltip></> : <><i className="bx bx-moon " id="theme_mode" style={{fontSize: "22px",top:"5px",padding:"2px"}}/><UncontrolledTooltip placement="top" target="theme_mode"> 
              Theme Mode
              </UncontrolledTooltip></>}{" "}</label>

              <input
                    type="radio"
                    className="invisible"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 12,
                      color: "white",
                      backgroundColor: "#536BDF",
                      paddingRight: 2,
                      width:"20px"
                      // ,height:"20px",paddingTop:"20px"
                    }}
                    id="flexSwitchCheckDefault"
                    name="radioDark"
                    value={this.props.layoutModeType === layoutModeTypes.DARK || this.state.layoutModeType === layoutModeTypes.DARK ? layoutModeTypes.LIGHT : layoutModeTypes.DARK}
                    onClick={(e)=> {UpdateProfileData(e.target.value)}}
                    checked={this.props.layoutModeType }
                    onChange={this.changeLayoutMode}
                  />
              </div>
             
              {/* <NotificationDropdown /> */}

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.toggleRightbar();
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin" />
                </button>
              </div> */}
            </div>
            </div>
         
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  layoutModeType : PropTypes.any,
  changeLayoutMode : PropTypes.func,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutModeType,layoutType } = state.Layout;
  return { layoutModeType,layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar , changeLayoutMode })(
  withTranslation()(Header)
);
