import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import { async } from "@firebase/util";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_order_count : 0,
    };
  }

  componentDidMount() {
    // let matchingMenuItem = null;
    // const ul = document.getElementById("navigation");
    // const items = ul.getElementsByTagName("a");
    // for (let i = 0; i < items.length; ++i) {
    //   if (this.props.location.pathname === items[i].pathname) {
    //     matchingMenuItem = items[i];
    //     break;
    //   }
    // }
    // if (matchingMenuItem) {
    //   this.activateParentDropdown(matchingMenuItem);
    // }
    this.get_active_order_count();
  }
  get_active_order_count = async() => {
    const uid = JSON.parse(localStorage.getItem("user")).uid;
    firebase.auth();
    const firestoreDb = firebase.firestore(); 
    const firestoreDbUser = firestoreDb.collection("users").doc(uid);
    const active_order_count = firestoreDbUser.collection("ccxt_orders").orderBy("created_at", "desc").get();
    const itemss = [];
    active_order_count.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        itemss.push(item)
      });
      this.setState({active_order_count : itemss.length})
    });
  }
  // activateParentDropdown = item => {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   if (parent) {
  //     parent.classList.add("active"); // li
  //     const parent2 = parent.parentElement;
  //     parent2.classList.add("active"); // li
  //     const parent3 = parent2.parentElement;
  //     if (parent3) {
  //       parent3.classList.add("active"); // li
  //       const parent4 = parent3.parentElement;
  //       if (parent4) {
  //         parent4.classList.add("active"); // li
  //         const parent5 = parent4.parentElement;
  //         if (parent5) {
  //           parent5.classList.add("active"); // li
  //           const parent6 = parent5.parentElement;
  //           if (parent6) {
  //             parent6.classList.add("active"); // li
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // };

  render() {
    const { location } = this.props;
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const isTrade = searchParams.has("pairs");
    const isExchangesActive = pathname === "/ccxt/settings" && !isTrade;
    const isTradeActive = pathname === "/ccxt/settings" && isTrade;

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav w-100">
                <li className={classnames("nav-item dropdown", { active: isExchangesActive})}>
                    <Link  to="/ccxt/settings">
                      <i className="bx bx-move-horizontal me-2" />
                      {this.props.t("Exchanges")}
                    </Link>
                </li>
                <li className={classnames("nav-item dropdown", { active: isTradeActive })}>
                    <Link  to="/ccxt/settings?pairs">
                      <i className="bx bx-stats me-2" />
                      {this.props.t("Trade")}
                    </Link>
                </li>
                <li className={classnames("nav-item", { active: pathname === "/ccxt/wallet" })}>
                    <Link to="/ccxt/wallet">
                      <i className="bx bx-money me-2" />
                      {this.props.t("Wallet")}
                    </Link>
                </li> 

                <li className={classnames("nav-item", { active: pathname === "/ccxt/orders/active" })}>
                    <Link  to="/ccxt/orders/active">
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Active Orders")}<span className="span-active-order">{this.state.active_order_count >0 ?' ( '+this.state.active_order_count + ' ) ':''}</span>
                    </Link>
                </li>


                <li className={classnames("nav-item", { active: pathname === "/ccxt/orders/completed" })}>
                    <Link  to="/ccxt/orders/completed">
                      <i className="bx bx-history me-2" />
                      {this.props.t("Completed Orders")}
                    </Link>
                </li>

                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ ordersState: !this.state.ordersState });
                      }}>
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Orders")}
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.ordersState,
                      })}
                    >
                      <Link to="/ccxt/orders/active" className="dropdown-item">
                        {this.props.t("Active")}
                      </Link>
                      <Link to="/ccxt/orders/completed" className="dropdown-item">
                        {this.props.t("Completed")}
                      </Link>
                       </div>
                </li>                */}
                 <li className="help-url">
                    <a href={process.env.REACT_APP_HELP_URL} target="_blank" rel="noopener noreferrer" style={{color:"#a6b0cf"}}>
                        <i className="bx bx-help-circle me-2" />{" "}
                        {this.props.t("Help")}
                    </a>
                </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
