import React, { useCallback, useEffect, useState } from "react";
import {
    Card,Modal,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    CardTitle, Spinner, Alert, Table, Tooltip, Badge,
    Accordion,
    AccordionBody,
    AccordionHeader,
    UncontrolledTooltip,
    AccordionItem, Popover,
    PopoverHeader,
    PopoverBody,
    FormGroup,
} from "reactstrap";
import parse from 'html-react-parser';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { Link } from "react-router-dom";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import 'firebase/auth';
import api from '../../services/api.service';
import Swal from 'sweetalert2';
import { formatUTCDate, getContractValue, formatUTCDateTime, capitalizeFirst, getSeconds,getMomentDateTimeFormat, getSecondsFromTime, get_actual_index, getStrangleMarketAction, getOrderStatusStyle, getPrices, counterRenderer,timeFormat, CustomParseFloat,getSettingsDateTimeFormat,getExpiryDates,getOrderLogs } from "../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import HistoryModal from "../../components/Modal/HistoryModal";
import Switch from "react-switch";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
const { default: axios } = require("axios");
import { MinimumTakeProfit,TaillingTakeProfit,MidPrize } from "../../helpers/help-msg";
import Bot_settings_default from '../../helpers/Common/Bot_default_settings_values.json';
import moment from "moment";
import DatePicker from "react-flatpickr";
const ListOptions = (props) => {
     firebase.auth();     
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    var firestoreDb = firebase.firestore().collection("users").doc(uid);
    const currentdate = new Date().toISOString();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const contractValue = getContractValue('BTCUSDT');
    const ordersRef = firestoreDb.collection("strangle_orders").orderBy("strangle_id", "desc").orderBy("symbol", "desc");    
    const [activeTab, setActiveTab] = useState(1)
    const [activeOrderTab, setActiveOrderTab] = useState("1");
    const [passedSteps, setPassedSteps] = useState([1])
    const [expiryDates, setExpiryDates] = useState([]);
    const [bestStrikes, setBestStrikes] = React.useState("");
    const [selectedExpiryDate, setSelectedExpiryDate] = useState("")
    const [validateStrikes, setValidateStrikes] = useState("")
    const [StrangleMidPrice, setStrangleMidPrice] = useState("");
    //for settings 
    const [settings, setSettings] = useState({
        index_percentage:Bot_settings_default.Strangles.index_percentage,
        quantity:Bot_settings_default.Strangles.quantity,
        trigger_percentage:Bot_settings_default.Strangles.stop_loss_active_percentage,
        stop_loss_percentage:Bot_settings_default.Strangles.market_stop_loss_percentage,
        stop_loss_index_percentage : Bot_settings_default.Strangles.index_price_range_percentage_for_stop_loss,
        min_take_profit_percentage : Bot_settings_default.Strangles.minimum_take_profit_percentage,
        trailing_take_profit_percentage : Bot_settings_default.Strangles.trailling_take_profit_percentage,
        take_profit_percentage : Bot_settings_default.Strangles.take_profit_percentage,
        Qty_time_clock : Bot_settings_default.Strangles.waiting_time_to_fill_qty,
        Roll_time_clock:Bot_settings_default.Strangles.Roll_time_clock,
        combined_sl:Bot_settings_default.Strangles.combined_stop_loss,
        roll_percentage:Bot_settings_default.Strangles.premium_Roll_Percentage,
        index_distance : Bot_settings_default.Strangles.distance_from_index,
        delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range,
        delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval,
        reentry_enabled : 0,
        wait_time_reentry:"0",
        time_to_close_orders_before_expiry:"0",
        combined_sl_percentage:Bot_settings_default.combined_sl_percentage,
        combined_trailing_profit_percentage:Bot_settings_default.combined_trailing_profit_percentage,
        combined_trailing_stop_price_percentage:Bot_settings_default.combined_trailing_stop_price_percentage,
        combined_take_profit_percentage:Bot_settings_default.combined_take_profit_percentage
    });
    const [orderData, setOrderData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [stopStrangleFlag, setStopStrangleFlag] = useState([]);
    const [stranglebot, setStranglebot] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ttop, setttop] = useState(false);
    const [ttop1, setttop1] = useState(false);
    const [tradeStatus, setTradeStatus] = useState("0");
    const [visibleMin, setVisibleMin] = useState(false);
    const [visibleTailling, setVisibleTailling] = useState(false);
    const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
    const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
    const [popovertop, setpopovertop] = useState(false);
    const [SlTriggerStrangles, setSlTriggerStrangles]=useState(true);
    const [Deltahedging,setDeltahedging]=useState(false);
    const [Deltahedging2,setDeltahedging2]=useState(false);
    const [TraillingStrangles,setTraillingStrangles]=useState(false);
    const [time_to_close_orders_before_expiry, settime_to_close_orders_before_expiry] = useState(0);
    const [visibleMidPrize, setVisibleMidPrize] = useState(false);
    const [MidPrizeMsg,setMidPrizeMsg] = useState();
  const [strangleAdvancedSettings,setStrangleAdvancedSettings]=useState(false)
   //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       
           const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
           // console.log(response)
             setToolTipData(JSON.parse(JSON.stringify(response.data)))
         
         }
 
         gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
  //meta title
    document.title = "Strangles";
    //for settings -end
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen('0');
        } else {
            setOpen(id);
        }
    };
  const [strangleswait_time_reentry, setstrangleswait_time_reentry] = useState(0);
  const [reentry_enabled, setreentry_enabled] = useState(false);


    function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);
      
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        return hours + ":" + minutes ;
      }
      const handleDH = (e,strategy = '') => {
        var time = e[0]
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var H_M = toSeconds(hours, minutes, 0);
        if (strategy == 'strangleswait_time_reentry'){
          setstrangleswait_time_reentry(H_M)
        }else if (strategy == 'time_to_close_orders_before_expiry'){
            settime_to_close_orders_before_expiry(H_M)
          }
      }
      function toSeconds(hours, minutes, seconds) {
        return hours * 3600 + minutes * 60 + seconds;
      }
    const popovertopchange = targetName => {
        if (!popovertop[targetName] || !popovertop[targetName].popover) {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: true
                }
            });
        } else {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: false
                }
            });
        }
    };

    const ispopoverOpen = targetName => {
        return popovertop[targetName] ? popovertop[targetName].popover : false;
    };
    const [loading, setLoading] = useState({
        loading: false
    });
    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                Yes
            </div>
        );
    };
    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        } else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    const toggleOrderTab = tab => {
        if (activeOrderTab !== tab) {
            setActiveOrderTab(tab);
            if (tab == 2) {
                setFilterStartDate(defaultStartDate);
                setFilterEndDate(defaultEndDate);
            }
        }
    };
    function toggleTab(tab) {
        if (activeTab == 1 && tab == 2) {
            setValidateStrikes("");
            setLoading({
                loading: false
            });
            setBestStrikes("")
            //get best strikes
            let exp_date = document.getElementById('exp_date').value;
            let index_percentage = document.getElementById('index_percentage').value;
            if (index_percentage > 0) {
                setLoading({
                    ...loading,
                    best_strikes: true,
                });

                const bodyParams = {
                    settlement_time: exp_date,
                    index_percentage: index_percentage
                };
                setSelectedExpiryDate(exp_date)
                new Promise(async (resolve, reject) => {
                    try {
                        const res = await api({ contentType: true, auth: true }).post('/delta/get-best-strikes', bodyParams);
                        setBestStrikes(res.data.response.best_strikes);
                        validateStrikesIfRunning(res.data.response.best_strikes);
                        resolve(); // Resolve the promise after successful execution
                    } catch (err) {
                        handleError(err);
                        reject(err); // Reject the promise if there's an error
                    } finally {
                        setLoading({
                            ...loading,
                            best_strikes: false,
                        });
                    }
                });
                

                var modifiedSteps = [...new Set(passedSteps), tab];
                modifiedSteps = modifiedSteps.filter(element => element > tab);
                setActiveTab(tab);
                setPassedSteps(modifiedSteps)
            } else {
                toast.error('Enter Index Percentage');


            }
        } else if (activeTab == 3 && tab > activeTab) {
            //start bot
            startBot(tab);


        } else if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                var modifiedSteps = [...new Set(passedSteps), tab];
                setActiveTab(tab);
                modifiedSteps = modifiedSteps.filter(element => element > tab);
                setPassedSteps(modifiedSteps)

            }
        }
    }
    const startBot = async (tab) => {
        setLoading({
            ...loading,
            start_bot: true,
        });
        if (stranglebot && tradeStatus == 1) {

            //settings
            //validation checks on settings            
            validateStrikesIfRunning(bestStrikes).then(async (response) => {
                if (response) {
                    //console.log("response", response)
                    setLoading({
                        ...loading,
                        start_bot: true,
                    });
                    //console.log("loading.best_strikes_validation", loading.best_strikes_validation)
//  else if (Number(settings.trigger_percentage) >= Number(settings.stop_loss_percentage)) {
                    //     toast.error('SL % must more than Stop Loss Activate Percentage');
                    //     setLoading({
                    //         ...loading,
                    //         start_bot: false,
                    //     });

                    //     return false;
                    // } 

                    if ((validateStrikes != '' && !loading.best_strikes_validation)) {
                        //console.log("symbol is already running")
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else if (settings.quantity == '') {
                        toast.error('Please enter quantity');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false; 
                    } else if (!reentry_enabled && settings.roll_status==1 && ((!settings.roll_percentage && !settings.index_distance) || (!settings.roll_percentage && settings.index_distance) || (settings.roll_percentage && !settings.index_distance))) {
                        toast.error('Must Enter Roll percentage and distance from index');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    }
                    
                    else {
                        //starting bot
                        //console.log("starting bot")
                        //console.log("settings",settings);
                        const calls_margin = Number(bestStrikes.calls.order_margin);
                        const puts_margin = Number(bestStrikes.puts.order_margin);
                        const required_margin = Number(calls_margin) + Number(puts_margin);
                        settings.required_margin = required_margin;
                        settings.current_index_price = Number(bestStrikes.index_price).toFixed(2);
                        settings.expiry_date = bestStrikes.expiry_date;
                        settings.calls_amount = bestStrikes.calls.best_bid;
                        settings.puts_amount = bestStrikes.puts.best_bid;
                        settings.call_symbol = bestStrikes.calls.symbol;
                        settings.call_symbol_id = bestStrikes.calls.product_id;
                        settings.put_symbol = bestStrikes.puts.symbol;
                        settings.put_symbol_id = bestStrikes.puts.product_id;
                        settings.waitTimeForFillAllQty = getSecondsFromTime(settings.Qty_time_clock);
                        settings.waitTimeForRoll = getSecondsFromTime(settings.Roll_time_clock);
                        settings.roll_status = !reentry_enabled && settings.roll_status ? 1 : 0;
                        settings.universal_stop = settings.universal_stop ? 1 : 0;
                        settings.autoMarketFill = settings.autoMarketFill ? 1 : 0;
                        settings.uid = uid;
                        settings.combined_sl = settings.combined_sl ? settings.combined_sl : false;
                        settings.combined_trailing_profit = TraillingStrangles == true ? 1 : 0;
                        settings.reentry_enabled = reentry_enabled == true ? 1 : 0;
                        settings.wait_time_reentry = strangleswait_time_reentry; 
                        // settings.stopdate_reentry = stopdate_reentry;
                        settings.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry;
                        settings.mid_price_percentage = StrangleMidPrice ? StrangleMidPrice : 0;
                       
                   
                        await api({ contentType: true, auth: true }).post('/strangles/create-strangles',settings).then(async(res)  => {
                        let strangle_result = res.data.response.data;
                                //console.log('dataaaaaa......',data);
                                // })
                                // await firestoreDb
                                //     .collection("strangles")
                                //     .add(settings)
                                //     .then(async (res) => {
                                //console.log("inserted to strangles", res)
                                settings.strangle_id = strangle_result.insertId;
                                settings.created_at = currentdate;
                                settings.updated_at = currentdate;
                                settings.SymbolName = bestStrikes.calls.symbol;
                                settings.SymbolId = bestStrikes.calls.product_id;
                                settings.amount = bestStrikes.calls.sell_price;
                                await api({ contentType: true, auth: true }).post('/delta/start-bot', settings).then(async (calls_res) => {
                                    settings.SymbolName = bestStrikes.puts.symbol;
                                    settings.SymbolId = bestStrikes.puts.product_id;
                                    settings.amount = bestStrikes.puts.sell_price;
                                    await api({ contentType: true, auth: true }).post('/delta/start-bot', settings).then(puts_res => {
                                        toast.success('Orders Placed successully');
                                        var modifiedSteps = [...new Set(passedSteps), tab];
                                        setActiveTab(tab);
                                        // hide popup
                                        setTimeout(() => {
                                            setActiveTab(1);
                                            setOpen('0');
                                          }, 3000);                                        
                                        modifiedSteps = modifiedSteps.filter(element => element > tab);
                                        setPassedSteps(modifiedSteps)
                                        setLoading({
                                            ...loading,
                                            start_bot: false,
                                        });
                                    }).catch(err => {
                                        handleError(err)
                                        setLoading({
                                            ...loading,
                                            start_bot: false,
                                        });
                                    })
                                }).catch(err => {
                                    handleError(err)
                                    setLoading({
                                        ...loading,
                                        start_bot: false,
                                    });
                                });
                            }).catch(err => {
                                handleError(err)
                                setLoading({
                                    ...loading,
                                    start_bot: false,
                                });
                            });
                    }
                } else {
                    setLoading({
                        ...loading,
                        start_bot: false,
                    });
                }
            });
        }
    }
    const validateStrikesIfRunning = async (best_strikes) => {
        return true
        // // setLoading({
        // //     ...loading,
        // //     best_strikes_validation: true,
        // // });
        // const call_symbol = best_strikes.calls.symbol;
        // const put_symbol = best_strikes.puts.symbol;
        // const check_call_symbol = await firestoreDb.collection("strangle_orders").doc(call_symbol).get();

        // if (check_call_symbol.exists) {
        //     toast.error('This symbol is already running');
        //     setValidateStrikes('This symbol is already running')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }

        // const check_put_symbol = await firestoreDb.collection("strangle_orders").doc(put_symbol).get();
        // if (check_put_symbol.exists) {
        //     toast.error('This symbol is already running');
        //     setValidateStrikes('This symbol is already running')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }

        // const check_call_diagonal = await firestoreDb.collection("diagonal_orders").doc(call_symbol).get();
        // if (check_call_diagonal.exists) {
        //     toast.error('This symbol is already running in diagonals');
        //     setValidateStrikes('This symbol is already running in diagonals')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }

        // const check_put_diagonal = await firestoreDb.collection("diagonal_orders").doc(put_symbol).get();
        // if (check_put_diagonal.exists) {
        //     toast.error('This symbol is already running in diagonals');
        //     setValidateStrikes('This symbol is already running in diagonals')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }
        // const check_call_straddles = await firestoreDb.collection("straddle_orders").doc(call_symbol).get();
        // if (check_call_straddles.exists) {
        //     toast.error('This symbol is already running in straddles');
        //     setValidateStrikes('This symbol is already running in straddles')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }

        // const check_put_straddles = await firestoreDb.collection("straddle_orders").doc(put_symbol).get();
        // if (check_put_straddles.exists) {
        //     toast.error('This symbol is already running in straddles');
        //     setValidateStrikes('This symbol is already running in straddles')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }
        // const check_call_trend = await firestoreDb.collection("trend_orders").doc(call_symbol).get();
        // if (check_call_trend.exists) {
        //     toast.error('This symbol is already running in trend following');
        //     setValidateStrikes('This symbol is already running in  trend following')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }

        // const check_put_trend = await firestoreDb.collection("trend_orders").doc(put_symbol).get();
        // if (check_put_trend.exists) {
        //     toast.error('This symbol is already running in trend following');
        //     setValidateStrikes('This symbol is already running in trend following')
        //     setLoading({
        //         ...loading,
        //         best_strikes_validation: false,
        //     });
        //     return false;
        // }
        // // setLoading({
        // //     ...loading,
        // //     best_strikes_validation: false,
        // // });
        // return true;
    }
    const getExpiryDateValues = useCallback(async () => {
        try {
            const bodyParams = {
              currency: 'BTC'
            };
            await getExpiryDates(bodyParams).then((res) => {
                setExpiryDates(res.data.response.expiry_dates);  
            }).catch(e => {
              throw e
            })
          } catch (e) {
            handleError(e);
          }
    });
    const get_settings = async () => {
        // await api({ contentType: true, auth: true }).get('/delta/get-order-settings?type=ordersettings-global').then(res => {
        //     if (res && res.data && res.data.response && res.data.response.settings) {
        //       const order_settings =res.data.response.settings;
        await api({ contentType: true, auth: true }).get('/strangles/getUserStranglesSettings')      
        .then((res_str)=>{
          if(res_str.data.response && res_str.data.response.data.toString().length > 0)
          {
            const order_settings = res_str.data.response.data;
              order_settings['delta_hedging_range'] = order_settings.delta_hedging_range ?  order_settings.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range,
              order_settings['delta_hedging_interval'] =  order_settings.delta_hedging_interval ?  order_settings.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval
              setSettings(order_settings);
              setStrangleMidPrice(order_settings.mid_price_percentage? order_settings.mid_price_percentage : "0");
              setreentry_enabled(order_settings.reentry_enabled? (order_settings.reentry_enabled == 1 ? true : false):false);
              setstrangleswait_time_reentry(order_settings.wait_time_reentry? order_settings.wait_time_reentry : "0");
              setDeltahedging(order_settings.deltahedging == 1 ? true:false);
              setDeltahedging2(order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg == 1 ? true:false);
              setSlTriggerStrangles(order_settings && order_settings.Sl_trigger_take_profit !== undefined ? order_settings.Sl_trigger_take_profit : true);
              setTraillingStrangles(order_settings && order_settings.combined_trailing_profit !== undefined ? order_settings.combined_trailing_profit : false);
              settime_to_close_orders_before_expiry(order_settings.time_to_close_orders_before_expiry ? order_settings.time_to_close_orders_before_expiry : "0");
            }
        });
        // var docRef = firestoreDb.collection("order-settings").doc('ordersettings-global');
        // await docRef.get().then((doc) => {
        //     if (doc.exists) {
        //         const order_settings = doc.data();
        //         setSettings(order_settings);
        //     }
        // })
    }
    const getOrders = useCallback(() => {
        setLoading({
            ...loading,
            Table_load : true
        }) 
        ordersRef.onSnapshot((querySnapshot) => {

            const items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                item.dbid = doc.id;
                if ((item.status.toLowerCase() === "closed" || item.filled_quantity > 1) && item.direction === "sell") {
                    let sold_price = item.price;
                    if (item.direction === 'buy') {
                        sold_price = (item.average_fill_price) ?(item.average_fill_price): (item.sold_price)
                    }
                    // let unrealized_pnl = parseFloat((Number(sold_price) - Number(item.lowest_ask_now)) * Number(contractValue) * Number(item.filled_quantity));
                    // item.unrealized_pnl_exact = unrealized_pnl;
                    // item.unrealized_pnl = Math.floor(unrealized_pnl * 100) / 100;
                    // item.unrealized_pnl_currency = 'USDT';
                } 
                // else {
                //     item.unrealized_pnl = '...'
                // }
                item.dbid = doc.id;

                items.push(item)
            });

            //Combine orders by diagonal id
            const all_strangles = items.reduce(function (row, obj) {
                row[obj.strangle_id] = row[obj.strangle_id] || [];        
                row[obj.strangle_id].push(obj);
                return row;
            }, []);
            
            //Convert to array & Find diagonal status - To enable market close
            const active_strangles = Object.keys(all_strangles).map(strangle_index => {                 
                const rows = all_strangles[strangle_index];
                if (stopStrangleFlag[rows[0].strangle_id] && stopStrangleFlag[rows[0].strangle_id] == 1 && rows[0].is_stop_triggered == 0 && rows[0].status.toLowerCase() !== 'cancelled') {
                    toast.error("Unable to stop the order, order may moved to position, please try again");
                    stopStrangleFlag[rows[0].strangle_id] = 0;
                    setStopStrangleFlag(stopStrangleFlag);
                }
                let market_filled_order;
                const filled_order = rows.find(element => (element.direction.toLowerCase() === 'buy' && element.status.toLowerCase() === 'open') || (element.direction.toLowerCase() === 'sell' && (element.status.toLowerCase() === 'closed' || element.filled_quantity > 0)));
                if (rows.length > 1) {
                    const filled_quantity_main = rows[0].filled_quantity;
                    const order_type_main = rows[0].order_type;
                    const direction_main = rows[0].direction;
                    const status_main = rows[0].status;
                    const filled_quantity_secondary = rows[1] ? rows[1].filled_quantity : 0;
                    const order_type_secondary = rows[1] ? rows[1].order_type : '';
                    const direction_secondary = rows[1] ? rows[1].direction : '';
                    const status_secondary = rows[1] ? rows[1].status : 'open';

                    if (order_type_main === "base" && status_main === "open" && (order_type_secondary !== 'base' || direction_secondary === "buy" || (filled_quantity_secondary > 0 && filled_quantity_main != filled_quantity_secondary))) {
                        market_filled_order = 1;
                    }
                    if (order_type_secondary === "base" && status_secondary === "open" && (order_type_main !== 'base' || direction_main === "buy" || (filled_quantity_main > 0 && filled_quantity_main != filled_quantity_secondary))) {
                        market_filled_order = 1;
                    }
                } else {
                    market_filled_order = 0;
                }
                const strangle_status = (filled_order) ? "closed" : "open";
                const market_fill_status = (rows[0].settings.autoMarketFill != 1 && (market_filled_order == 1)) ? "1" : "0";
                return rows.map((row, index) => {
                    row.strangle_status = strangle_status;
                    row.market_fill_status = market_fill_status;
                    return row
                });
            });
            setLoading({
                ...loading,
                Table_load : false
            }) 
            setOrderData(active_strangles);
           /*  const calls_puts = items.reduce(function (rows, obj, index) {

                return (!rows.length || obj.strangle_id !== rows[rows.length - 1][0].strangle_id ? rows.push([obj])
                    : rows[rows.length - 1].push(obj)) && rows;
            }, []);

            const strangles = calls_puts.map(rows => {
                if (stopStrangleFlag[rows[0].strangle_id] && stopStrangleFlag[rows[0].strangle_id] == 1 && rows[0].is_stop_triggered == 0 && rows[0].status.toLowerCase() !== 'cancelled') {
                    toast.error("Unable to stop the order, order may moved to position, please try again");
                    stopStrangleFlag[rows[0].strangle_id] = 0;
                    setStopStrangleFlag(stopStrangleFlag);
                }
                let market_filled_order;

                const filled_order = rows.find(element => (element.direction.toLowerCase() === 'buy' && element.status.toLowerCase() === 'open') || (element.direction.toLowerCase() === 'sell' && (element.status.toLowerCase() === 'closed' || element.filled_quantity > 0)));
                if (rows.length > 1) {
                    const filled_quantity_main = rows[0].filled_quantity;
                    const order_type_main = rows[0].order_type;
                    const direction_main = rows[0].direction;
                    const status_main = rows[0].status;
                    const filled_quantity_secondary = rows[1] ? rows[1].filled_quantity : 0;
                    const order_type_secondary = rows[1] ? rows[1].order_type : '';
                    const direction_secondary = rows[1] ? rows[1].direction : '';
                    const status_secondary = rows[1] ? rows[1].status : 'open';

                    if (order_type_main === "base" && status_main === "open" && (order_type_secondary !== 'base' || direction_secondary === "buy" || (filled_quantity_main > 0 && filled_quantity_main != filled_quantity_secondary))) {
                        market_filled_order = 1;
                    }
                    if (order_type_secondary === "base" && status_secondary === "open" && (order_type_main !== 'base' || direction_main === "buy" || (filled_quantity_main > 0 && filled_quantity_main != filled_quantity_secondary))) {
                        market_filled_order = 1;
                    }
                } else {
                    market_filled_order = 0;
                }


                const strangle_status = (filled_order) ? "closed" : "open";

                const market_fill_status = (rows[0].settings.autoMarketFill != 1 && (market_filled_order == 1)) ? "1" : "0";
                const strangle = rows.map((row, index) => {
                    row.strangle_status = strangle_status;
                    row.market_fill_status = market_fill_status;
                    return row
                })
                return strangle;
            });


            setOrderData(strangles); */
        });

    }, [ordersRef, contractValue]);


    //Completed Strangles filter
    const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
    const defaultEndDate = new Date(new Date()).toISOString();
    const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
    const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);   
   
    const convert = (str) => {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

    const handleCreatedDateChange = (selectedDates, datestr) => {   
        setpopovertop({}); 
        if (selectedDates && selectedDates.length) {
          let converted_date = new Date(convert(selectedDates[0]));
          converted_date.setHours(0, 0, 0, 0);
          setFilterStartDate(converted_date.toISOString()); 
          if(selectedDates[1]){
            let converted_end_date = new Date(convert(selectedDates[1]));
            converted_end_date.setHours(23, 59, 59, 999);
            setFilterEndDate(converted_end_date.toISOString()); 
          }
        } else {
          setFilterStartDate(defaultStartDate); 
          setFilterEndDate(defaultEndDate);       
        }
    }
    
    const getTransactions = async() => {        
        setLoading({
            ...loading,
            Table_load : true
        }) 
        var body =  {
            uid : uid,
            filterStartDate : filterStartDate,
            filterEndDate : filterEndDate
          }
          await api({ contentType: true, auth: true }).post('/strangles/strangles-history-data',body).then(async(res)  => {
            var data = res.data.response.data;
      

        // await historyRef.get().then((res) => {
            const items = [];
            data.forEach((doc) => {
                let item = doc.data;
                let sold_price = item.price;
                if (item.direction === 'buy') {
                    sold_price = (item.average_fill_price) ?(item.average_fill_price): (item.sold_price) ?  item.sold_price : item.price
                }
                items.push(item)
            });

            // const all_strangles = items.reduce(function (row, obj) {
            //     row[obj.strangle_id] = row[obj.strangle_id] || [];        
            //     row[obj.strangle_id].push(obj);
            //     return row;
            //   }, []);

              const all_strangles = items.reduce(function (arr, obj) {
                const index =obj.strangle_id+'_st'; // Assuming strangle_id starts from 1
                if (!arr[index]) {
                  arr[index] = [];
                }
                arr[index].push(obj);
                return arr;
              }, []);
               for (const key in all_strangles) {
                all_strangles[key].sort((a, b) => {
                  if (a.symbol === b.symbol) {
                    return a.roll_index - b.roll_index;
                  }
                  return a.symbol.localeCompare(b.symbol);
                });
              }
            const completed_strangles = Object.keys(all_strangles).map(strangle_index => {
                return all_strangles[strangle_index];        
            });
            setLoading({
                ...loading,
                Table_load : false
            }) 
            setTransactionData(completed_strangles);
        });
    }

    const getSiteSettings = async () => {        
        setLoading({
            ...loading,
            site_settings: true,
        });
        // var get_site_settings = await firebase.firestore().collection("site-settings").doc('data');
        // get_site_settings.get().then((res) => {
        //     if (res.exists) {
        //         const site_settings = res.data();
        //         setStranglebot(site_settings.enable_strangles)
        //         setLoading({
        //             ...loading,
        //             site_settings: false,
        //         });
        //     }
        // });
        await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
            if(res.data.response && res.data.response.settings) {
                setStranglebot(res.data.response.settings.enable_strangles);
                setLoading({
                                ...loading,
                                site_settings: false,
                            });
            }
          }).catch(err => {
            throw err;
          })
        // check admin trade status
        // const check_trade_status = await firestoreDb.collection("profile").doc('data').get().then((res)=>{
        //     console.log("res.data()",res.data());
        //     if(res.exists)
        //     {
        //         const check_status  = res.data();
        //         setTradeStatus(check_status.trade_status);                
        //     }
        // })

        const check_trade_status = await api({ contentType: true, auth: true }).get('/user/profile')
        .then(res => {
            if(res.data.response)
            {
                setTradeStatus(res.data.response.user.trade_status);
            }
        });

    }
    
    const marketCloseOrder = async (order) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove the order?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            let type;
            if (result.isConfirmed) {

                stopStrangleFlag[order.symbol] = 1;
                setStopStrangleFlag(stopStrangleFlag);
                if ((order.direction.toLowerCase() == "buy" && order.status == "open") || (order.direction.toLowerCase() === 'sell' && order.filled_quantity > 0)) {
                    type = "market_close"
                } else {
                    type = "stop"
                }
                const params = {
                    strangle_id: order.strangle_id,
                    dbid: order.dbid,
                    symbol: order.symbol,
                    type: type
                };
                await api({ contentType: true, auth: true }).post('/delta/market-close-strangle', params).then((res) => {
                    if (res.data !== "") {
                        toast.error(res.data.message);
                        //setStopped(res.data.message);
                        stopStrangleFlag[order.symbol] = 0;
                        setStopStrangleFlag(stopStrangleFlag);
                    }
                });
            }
        });
    }
    const marketCloseStrangle = async (strangleId, type) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the bot ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (type == 'stop') {
                    stopStrangleFlag[strangleId] = 1;
                    setStopStrangleFlag(stopStrangleFlag);
                } else {
                    stopStrangleFlag[strangleId] = 2;
                    setStopStrangleFlag(stopStrangleFlag);
                }

                try {
                    const params = {
                        strangle_id: strangleId,
                        type: type,
                        market_close_type:"manual"
                    };
                    await api({ contentType: true, auth: true }).post('/delta/market-close-strangle', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    };
    const stopReentry =async (strangleId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to stop reentry ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const params = {
                        strangle_id: strangleId
                    };
                    await api({ contentType: true, auth: true }).post('/delta/stop-reentry', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    }

    const marketFillStrangle = async (strangleId) => {
        try {
            const params = {
                strangle_id: strangleId
            };
            await api({ contentType: true, auth: true }).post('/delta/market-fill-strangle', params).then((res) => {
                if (res.data !== "") {
                    toast.error(res.data.message);
                    //setStopped(res.data.message);
                }
            });
        } catch (e) {
            toast.error(e.message);
            //setStopped(e.message);
        }
    };
    const getHistoryBysymbol = async (uid,dbid,symbol) => {
        try {  
          var body =  {
            uid : uid,
            strategy : "strangle",
            dbid : dbid
          }  
          let order_history= await getOrderLogs(body);
          if(order_history){
              setModalShow({
                dbid: symbol,
                show: true,
              });
              setHistoryData(order_history);
            } else {
                setHistoryData([]);
              setModalShow({
                dbid: symbol,
                show: true,
              });
            }
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data.message);        
          } else if (err.message) {
            toast.error(err.message);         
          } else if (err.request) {
            //console.log(err.message);
          } else {        
            //console.log(err.message);
          }
        }
      };
    // const getHistoryBysymbol = async (symbol) => {
    //     try {

    //         await firestoreDb
    //             .collection("historysymbol")
    //             .doc(symbol)
    //             .get()
    //             .then(async (doc) => {
    //                 if (doc.exists) {

    //                     let order_history = [];

    //                     if (doc.data().order_history) {
    //                         order_history = doc.data().order_history;
    //                         order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    //                     }

    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                     setHistoryData(order_history);
    //                 } else {
    //                     setHistoryData([]);
    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                 }
    //             });
    //     } catch (e) {
    //         //console.log("History symbol --: ", e);
    //     }
    // };        

    useEffect(() => {
        getSiteSettings();
        getExpiryDateValues();
        get_settings();
        getOrders();  
        setLoading({
            ...loading,
            Table_load : true
        })   
        setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())   
    setMidPrizeMsg(MidPrize())
    }, []);

    useEffect(() => { 
        let mounted = true;
        if (mounted) {      
            getTransactions();
        }         
        return () => {
          // actions to be performed when component unmounts
          mounted = false;
        }
    }, [filterStartDate, filterEndDate]);
    if (ToolTipData=="" && ToolTipData.length === 0) {
        return <div>Loading...</div>;
      }
    return (
        <React.Fragment>
            <div className="page-content common">
                <Container fluid={true}>
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Strangles" />
                    {stranglebot && tradeStatus == 1 && !loading.site_settings ? (
                        <Row>
                            <Col style={{paddingBottom: "20px"}}>
                            <span className="float-end" >You can change the settings for Strangles strategy <a href="/settings" target="_blank">here</a></span>
                            </Col>
                            
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Accordion open={open} toggle={toggle}>                                        
                                            <AccordionItem className="brd-soft">
                                                <AccordionHeader targetId="1" className="bg-soft">
                                                    <b>Create Strangles</b>                                                    
                                                </AccordionHeader>
                                                <AccordionBody accordionId="1">
                                                    <div className="wizard clearfix">
                                                        <div className="steps clearfix">
                                                            <ul>
                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 1 || ((activeTab !== 3) && (loading.best_strikes || loading.best_strikes_validation)),
                                                                    })}>
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: activeTab === 1,
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleTab(1);
                                                                        }}
                                                                    >
                                                                        <span className="number">1.</span>{" "}
                                                                        Choose Expiry
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 2 && !(loading.best_strikes || loading.best_strikes_validation),
                                                                    })}>
                                                                    <NavLink
                                                                        disabled={!(passedSteps || []).includes(2)}
                                                                        className={classnames({
                                                                            active: activeTab === 2,
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleTab(2);
                                                                        }}
                                                                    >
                                                                        <span className="number">2.</span>{" "}
                                                                        Confirm Strikes
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 3,
                                                                    })}>
                                                                   <NavLink
                                                                        disabled={!passedSteps.includes(3)}
                                                                        className={classnames({ active: activeTab === 3, done: passedSteps.includes(3) })}
                                                                        onClick={() => {
                                                                            toggleTab(3);
                                                                        }}
                                                                    >
                                                                        <span className="number">3.</span> Bot Setup
                                                                    </NavLink>

                                                                </NavItem>
                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 4,
                                                                    })}>
                                                                         <NavLink
                                                                        disabled={!passedSteps.includes(4)}
                                                                        className={classnames({ active: activeTab === 4, done: passedSteps.includes(4) })}
                                                                        onClick={() => {
                                                                            toggleTab(4);
                                                                        }}
                                                                    >
                                                                        <span className="number">4.</span>{" "}
                                                                        Bot Success
                                                                    </NavLink>
                                                                </NavItem>
                                                            </ul>
                                                        </div>
                                                        <div className="content clearfix">
                                                            <TabContent
                                                                activeTab={activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation) ? 1 : activeTab}
                                                                className="body"
                                                            >
                                                                <TabPane tabId={1}>

                                                                    <Row>
                                                                        <Col >
                                                                            <div className="mb-3">
                                                                                <Row>
                                                                                    <Col sm="6" xl="6">
                                                                                        <Label for="basicpill-firstname-input1">
                                                                                            Expiration date
                                                                                        </Label>{" "}<i className="bx bx-info-circle" id ="expdate" />
                                                                                        <UncontrolledTooltip placement="top" target="expdate"> 
                                                                                            {ToolTipData.expiration_date}
                                                                                        </UncontrolledTooltip>
                                                                                        <select className="form-select" id="exp_date">
                                                                                            {
                                                                                                expiryDates.map((index) => (
                                                                                                    <React.Fragment key={index}>
                                                                                                        <option value={index}>{formatUTCDate(index)}</option>
                                                                                                    </React.Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                    </Col>
                                                                                    <Col sm="6" xl="6">
                                                                                        <Label for="basicpill-firstname-input1">
                                                                                            Index Percentage
                                                                                        </Label>{" "}<i className="bx bx-info-circle" id ="indexper" />
                                                                                        <UncontrolledTooltip placement="top" target="indexper"> 
                                                                                            {ToolTipData.index_percentage}
                                                                                        </UncontrolledTooltip>
                                                                                        <div className="input-group">
                                                                                            <Input autoComplete="off"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="index_percentage"
                                                                                                placeholder="Enter Index %" value={settings.index_percentage} onChange={(event) => setSettings({
                                                                                                    ...settings,
                                                                                                    index_percentage: event.target.value
                                                                                                })}
                                                                                            />
                                                                                            <Label className="input-group-text" htmlFor="index_percentage">%</Label>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>

                                                                    </Row>


                                                                </TabPane>
                                                                <TabPane tabId={2}>
                                                                    {!loading.best_strikes ? (
                                                                        <>
                                                                            {bestStrikes ?
                                                                                <div>

                                                                                    <Row>
                                                                                        <Col lg="4">
                                                                                            <Card outline color="primary" className="border">
                                                                                                <CardHeader className="bg-transparent">
                                                                                                    <h5 className="my-0 text-primary">
                                                                                                        <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                                                                                    </h5>
                                                                                                </CardHeader>
                                                                                                <CardBody>
                                                                                                    <CardTitle className="mt-0">{bestStrikes.calls.symbol}</CardTitle>
                                                                                                    <CardText>
                                                                                                        <ul className="list-group">
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.calls.best_ask}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.calls.best_bid}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.calls.sell_price}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.calls.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.calls.leverage} </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.calls.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                                    </CardText>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>

                                                                                        <Col lg="4">
                                                                                            <Card outline color="primary" className="border">
                                                                                                <CardHeader className="bg-transparent">
                                                                                                    <h5 className="my-0 text-primary">
                                                                                                        <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                                                                                    </h5>
                                                                                                </CardHeader>
                                                                                                <CardBody>
                                                                                                    <CardTitle className="mt-0">{bestStrikes.puts.symbol}</CardTitle>
                                                                                                    <CardText >
                                                                                                        <ul className="list-group">
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.puts.best_ask}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.puts.best_bid}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.puts.sell_price}</span> </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.puts.leverage} </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.puts.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                                    </CardText>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                        <Col lg="4">
                                                                                            <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                                                                                <CardHeader className="bg-transparent">
                                                                                                    <h5 className="my-0 text-primary">
                                                                                                        <i className="mdi mdi-information-outline me-1" />Other Informations
                                                                                                    </h5>
                                                                                                </CardHeader>
                                                                                                <CardBody>
                                                                                                    <CardTitle className="mt-0"></CardTitle>
                                                                                                    <CardText>
                                                                                                        <ul className="list-group">
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Selected Index Percentage</b> : {bestStrikes.index_percentage}% </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {bestStrikes.contract_index} </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(bestStrikes.index_price).toFixed(2)} </li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(bestStrikes.puts.order_margin) + Number(bestStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                                                                                            <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={selectedExpiryDate} renderer={counterRenderer} /></li>

                                                                                                        </ul>
                                                                                                    </CardText>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>


                                                                                </div> : <Alert color="danger" role="alert">
                                                                                    No strikes found, please check with some other expiry date / index percentage
                                                                                </Alert>}</>
                                                                    ) : <Spinner className="ms-2" color="primary" />}
                                                                </TabPane>
                                                                <TabPane tabId={3}>
                                                                    <div>
                                                                        <Form>
                                                                            <Row >
                                                                        <Col lg="12" >
                                                                                                <Row className="mt-3">
                                                                                                        <Col lg="4">
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Quantity
                                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="QTY" />
                                                                                                            <UncontrolledTooltip placement="top" target="QTY"> 
                                                                                                                {ToolTipData.quantity}
                                                                                                            </UncontrolledTooltip>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.quantity} min={1} max={1000} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                quantity: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="1" min="1" max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="qty" value={settings.quantity} onChange={(event) => setSettings({
                                                                                                                        ...settings,
                                                                                                                        quantity: event.target.value
                                                                                                                    })}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="qty">Cont</Label>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        
                                                                                                    {/* </Row> */}

                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                       
                                                                                                   
                                                                                                    </Row>
                                                                                                    <Row  className="border opacity-75 bg-light mt-2 ">
                                                                                                            <Col lg="2">
                                                                                                             <h5 className="font-size-15 mt-5 mb-3">
                                                                                                    Re-entry Settings
                                                                                                        </h5>
                                                                                                        </Col>
                                                                                                      <Col lg="2"  className="mt-4 mb-3">
                                                                                                        {/* <FormGroup className="mb-0"> */}
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                          Enable Re-Entry
                                                                                                          </Label>{" "}
                                                                                                          <i className="bx bx-info-circle" id ="reentry_enabledreentry_enabled" />
                                                                                                            <UncontrolledTooltip placement="top" target="reentry_enabledreentry_enabled">
                                                                                                                {ToolTipData.reentry_enabled}
                                                                                                            </UncontrolledTooltip>
                                                                                                            {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                                                                                              <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                                                                                         {/* </FormGroup> */}
                                                                                                      {/* </Col> */}
                                                                                                      {/* <Col md="1"> */}
                                                                                                      <div>
                                                                                                              <Switch
                                                                                                              uncheckedIcon={<Offsymbol />}
                                                                                                              checkedIcon={<OnSymbol />}
                                                                                                              className="mb-sm-8 mb-0"                                      
                                                                                                              onChange={(e) => setreentry_enabled(e)} 
                                                                                                              checked={reentry_enabled}
                                                                                                            />
                                                                                                            </div>
                                                                                                      </Col>
                                                                                                      {reentry_enabled ?<>
                                                                                                          <Col lg="3"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Waiting time for Re-Entry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLESwait_time_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLESwait_time_reentry"> 
                                                                                                                {ToolTipData.wait_time_reentry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'strangleswait_time_reentry')}
                                                                                                                  value={toHoursAndMinutes(strangleswait_time_reentry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                  }}
                                                                                                                />
                                                                                                            </FormGroup>  
                                                                                                          </Col>
                                                                                                          
                                                                                                          {/* </Row>
                                                                                                          <Row> */}
                                                                                                            <Col lg="3"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Time to Close Orders Before Expiry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry1" />
                                                                                                            <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry1"> 
                                                                                                                {ToolTipData.time_to_close_orders_before_expiry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry')}
                                                                                                                  value={toHoursAndMinutes(time_to_close_orders_before_expiry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                    maxTime: "12:00"
                                                                                                                  }}
                                                                                                                />
                                                                                                            </FormGroup>  
                                                                                                          </Col>
                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                                 Date to stop reentry
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="stopdate_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="stopdate_reentry"> 
                                                                                                                {ToolTipData.stop_date_reentry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                            {/*must select only future date*/   }
                                                                                                            <DatePicker
                                                                                                                className="form-control d-block"
                                                                                                                placeholder="Select date"
                                                                                                                name="stopdate_reentry"
                                                                                                                format= "yyyy-MM-dd"
                                                                                                                onChange={(val) => {
                                                                                                                    const date = new Date(val);
                                                                                                                    const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                                                                                                    setSettings({
                                                                                                                        ...settings,
                                                                                                                        stopdate_reentry: dateOnly,
                                                                                                                    });
                                                                                                                }}
                                                                                                                value={settings.stopdate_reentry}
                                                                                                                options={{
                                                                                                                    minDate: tomorrow // Set to current date to disable past dates
                                                                                                                }}
                                                                                                            />

                                                                                                          </FormGroup>
                                                                                                          </Col>
                                                                                                          </>:''}
                                                                                                          </Row>
                                                                                                    </Col>
                                                                                                    </Row>
                                                                                                    {!reentry_enabled &&
                                                                                                      
                                                                                                      <Row className="border opacity-75">
                                                                                                          <Col lg="2">
                                                                                                          <h5 className="font-size-15 mt-5 mb-3 ">
                                                                                                          Roll Settings
                                                                                                          </h5>
                                                                                                          </Col>
                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                              <Label for="basicpill-namecard-input11">
                                                                                                                  Enable Roll
                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="enableroll" />
                                                                                                              <UncontrolledTooltip placement="top" target="enableroll"> 
                                                                                                                  {ToolTipData.roll_enable}
                                                                                                              </UncontrolledTooltip>
                                                                                                          {/* </Col> */}
                                                                                                          {/* <Col lg="4"> */}
                                                                                                              {/* <div className="mb-3"> */}
                                                                                                              {/* <Col md="1"> */}
                                                                                                              <div>
                                                                                                                  <Switch
                                                                                                                      uncheckedIcon={<Offsymbol />}
                                                                                                                      checkedIcon={<OnSymbol />}
                                                                                                                      className="me-1 mb-sm-8 mb-2"
                                                                                                                      checked={Boolean(Number(settings.roll_status))}
                                                                                                                      onChange={(e) => setSettings({
                                                                                                                          ...settings,
                                                                                                                          roll_status: !settings.roll_status
                                                                                                                      })}
                                                                                                                  />
                                                                                                                  </div>
                                                                                                                  </Col>
                                                                                                              {/* </div> */}
                                                                                                          {/* </Col> */}
                                                                                                      {/* </Row> */}
                                                                                                      
                                                                                                      {settings.roll_status ?
                                                                                                          <>
                                                                                                              {/* <Row> */}
                                                                                                                  {/* <Col> */}
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                      <Col lg="2"  className="mt-4 mb-3">
  
                                                                                                                              <Label for="basicpill-namecard-input11" className="mb-2">
                                                                                                                                  Distance from Index
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="DFX" />
                                                                                                                              <UncontrolledTooltip placement="top" target="DFX"> 
                                                                                                                                  {ToolTipData.roll_index_min_distance}
                                                                                                                              </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              <div className="input-group">
  
                                                                                                                                  <Input
                                                                                                                                      type="number" step="100" min="0"
                                                                                                                                      className="form-control"
                                                                                                                                      id="roll_index_distance" value={settings.index_distance } onChange={(event) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          index_distance: event.target.value
                                                                                                                                      })}
                                                                                                                                  />
  
                                                                                                                              </div>
  
                                                                                                                          </Col>
                                                                                                                      {/* </Row> */}
  
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                                              <Label for="basicpill-namecard-input11" >
                                                                                                                                  When to Roll? (H : M)
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="Roll" />
                                                                                                                              <UncontrolledTooltip placement="top" target="Roll"> 
                                                                                                                                  {ToolTipData.roll_wait_time}
                                                                                                                              </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              <div className="mb-3">
                                                                                                                                  <Flatpickr
                                                                                                                                      className="form-control d-block"
                                                                                                                                      placeholder="Select time" value={settings.Roll_time_clock ? settings.Roll_time_clock : Bot_settings_default.Strangles.Roll_time_clock} onChange={(e) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          Roll_time_clock: new Date(e).getHours() + ':' + new Date(e).getMinutes()
                                                                                                                                      })}
                                                                                                                                      options={{
                                                                                                                                          enableTime: true,
                                                                                                                                          noCalendar: true,
                                                                                                                                          dateFormat: "H:i",
                                                                                                                                          time_24hr: true
                                                                                                                                      }}
                                                                                                                                  />
                                                                                                                              </div>
                                                                                                                          </Col>
                                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                                              <Label for="basicpill-namecard-input11" className="">
                                                                                                                                  Premium Roll Percentage
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="Premium_Roll" />
                                                                                                                                      <UncontrolledTooltip placement="top" target="Premium_Roll"> 
                                                                                                                                          {ToolTipData.roll_premium_min_percentage}
                                                                                                                                      </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              {/* <Slider value={settings.roll_percentage } step={0.5} min={1} max={100} onChange={value => setSettings({
                                                                                                                                  ...settings,
                                                                                                                                  roll_percentage: value
                                                                                                                              })} /> */}
                                                                                                                              <div className="input-group">
  
                                                                                                                                  <Input
                                                                                                                                      type="number" step="0.5"  max="100"
                                                                                                                                      className="form-control"
                                                                                                                                      id="roll_percentage" value={settings.roll_percentage } onChange={(event) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          roll_percentage: event.target.value
                                                                                                                                      })}
                                                                                                                                  />
                                                                                                                                  <Label className="input-group-text" htmlFor="roll_percentage">%</Label>
                                                                                                                              </div>
  
                                                                                                                          </Col>
                                                                                                                      {/* </Row> */}
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                          
                                                                                                                      {/* </Row> */}
                                                                                                                  {/* </Col> */}
                                                                                                              {/* </Row> */}
                                                                                                          </> : ''}
                                                                                                          </Row>
                                                                                                         
  }
                                                                            {/* <Row> */}
                                                                            <div  className="mb-1 mt-1" >
                                                                            <a href="#"
                                                                                                           onClick={() => {
                                                                                                             setStrangleAdvancedSettings(!strangleAdvancedSettings);
                                                                                                           }}
                                                                                                       >
                                                                                                           Advanced settings
                                                                                                         </a>
                                                                                                         </div>

                                                                                <Col lg="12">

                                                                                    <Row>
                                                                                        <Col lg="12">
                                                                                      
                                                                                                
                                                                                                    {strangleAdvancedSettings ? <>
                                                                                                        <Row  className="border opacity-75 bg-light "> 
                                                                                                        <Col lg="4">
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                            Stop Loss Activate Percentage
                                                                                                            {" "}<i className="bx bx-info-circle" id ="TP" />
                                                                                                            <UncontrolledTooltip placement="top" target="TP"> 
                                                                                                                {ToolTipData.trigger}
                                                                                                            </UncontrolledTooltip>
                                                                                                            </Label>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.trigger_percentage} min={0} step={0.5} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                trigger_percentage: value
                                                                                                            })} /> */}


                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" value={settings.trigger_percentage } 
                                                                                                                    // onChange={(event) => setSettings({
                                                                                                                    //     ...settings,
                                                                                                                    //     trigger_percentage: event.target.value
                                                                                                                    // })}
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            trigger_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                    className="form-control"
                                                                                                                    id="trigger" step="0.5" max="100"
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="trigger">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.trigger_percentage && settings.trigger_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call stop loss activate value<span className="fw-bold"> {((bestStrikes.calls.sell_price * (settings.trigger_percentage/100)) + Number(bestStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put stop loss activate value <span className="fw-bold">{((bestStrikes.puts.sell_price * (settings.trigger_percentage/100)) + Number(bestStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                                                                                        </Col>
                                                                                                        <Col lg="4">
                                                                                                        
                                                                                                        <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                        Market Stop Loss Percentage
                                                                                                        {" "}<i className="bx bx-info-circle" id ="SP" />
                                                                                                        <UncontrolledTooltip placement="top" target="SP"> 
                                                                                                            {ToolTipData.stop_loss}
                                                                                                        </UncontrolledTooltip>
                                                                                                        </Label>
                                                                                                    {/* </Col>
                                                                                                    <Col> */}
                                                                                                    
                                                                                                        {/* <Slider value={settings.stop_loss_percentage} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                            ...settings,
                                                                                                            stop_loss_percentage: value
                                                                                                        })} /> */}
                                                                                                        <div className="input-group">

                                                                                                            <Input
                                                                                                                type="number" step="0.5" max="100"
                                                                                                                className="form-control"
                                                                                                                id="stop_loss" value={settings.stop_loss_percentage} 
                                                                                                                // onChange={(event) => setSettings({
                                                                                                                //     ...settings,
                                                                                                                //     stop_loss_percentage: event.target.value
                                                                                                                // })}
                                                                                                                onChange={(e) => { 
                                                                                                                    const value = e.target.value;
                                                                                                                    let newValue = 100; // Default value
                                                                                                                    if (value === null || value === '' || value < 0) {
                                                                                                                        newValue = '';
                                                                                                                    } else if (value <= 100) {
                                                                                                                        newValue = value;
                                                                                                                    }
                                                                                                                    setSettings({
                                                                                                                        ...settings,
                                                                                                                        stop_loss_percentage: newValue
                                                                                                                    });
                                                                                                                    }}
                                                                                                            />
                                                                                                            <Label className="input-group-text" htmlFor="stop_loss">%</Label>

                                                                                                        </div>
                                                                                                        {bestStrikes && settings.stop_loss_percentage && settings.stop_loss_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call market stop loss value<span className="fw-bold"> {((bestStrikes.calls.sell_price * (settings.stop_loss_percentage/100)) + Number(bestStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put market stop loss value <span className="fw-bold">{((bestStrikes.puts.sell_price * (settings.stop_loss_percentage/100)) + Number(bestStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                                                                                        {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                                <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input"
                                                                                                                id="combined_sl"
                                                                                                                checked={settings.combined_sl ? settings.combined_sl : false}
                                                                                                                onChange={(event) => setSettings({ ...settings, combined_sl: event.target.checked })}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="combined_sl">
                                                                                                                Combined Stop Loss
                                                                                                                </label>{" "}<i className="bx bx-info-circle" id ="Stop_loss_combined" />
                                                                                            <UncontrolledTooltip placement="top" target="Stop_loss_combined"> 
                                                                                                {ToolTipData.stop_loss_combined}
                                                                                            </UncontrolledTooltip>
                                                                                                                </div> */}
                                                                                                    </Col>
                                                                                                   
                                                                                                        {/* <Col lg="6"> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="4" className="mb-3">
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Index Price Range Percentage for Stop Loss <i className="bx bx-info-circle" id ="IPRPFSL" />
                                                                                                             <UncontrolledTooltip placement="top" target="IPRPFSL"> 
                                                                                                                 {ToolTipData.index_price_range}
                                                                                                             </UncontrolledTooltip>
                                                                                                             </Label>{" "}
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.stop_loss_index_percentage} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                stop_loss_index_percentage: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5"  max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="stop_loss_index" value={settings.stop_loss_index_percentage } 
                                                                                                                    // onChange={(event) => setSettings({
                                                                                                                    //     ...settings,
                                                                                                                    //     stop_loss_index_percentage: event.target.value
                                                                                                                    // })}
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            stop_loss_index_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="stop_loss_index">%</Label>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        </Row>
                                                                                                        <Row  className="border"> 
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="4" className="mb-3">
                                                                                                        <Label className="mt-3">Trailing Take Profit Percentage{" "}<i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}/> 
                                                                                                          
                                                                                                            </Label>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.min_take_profit_percentage} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                min_take_profit_percentage: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5" max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="take_profit" value={settings.min_take_profit_percentage} 
                                                                                                                    // onChange={(event) => setSettings({
                                                                                                                    //     ...settings,
                                                                                                                    //     min_take_profit_percentage: event.target.value
                                                                                                                    // })}
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            min_take_profit_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.min_take_profit_percentage && settings.min_take_profit_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call trailing profit value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.min_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put trailing profit value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.min_take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                                            <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                                <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input"
                                                                                                                id="StarangleMin"
                                                                                                                checked={SlTriggerStrangles}
                                                                                                                onChange={(event) => {setSlTriggerStrangles(event.target.checked);setSettings({
                                                                                                                    ...settings,
                                                                                                                    Sl_trigger_take_profit: event.target.checked
                                                                                                                })}}
                                                                                                                />
                                                                                                                <label className="form-check-label font-size-10" htmlFor="StarangleMin">
                                                                                                                Activate trailing take profit when stop loss completed on one leg
                                                                                                                </label>{" "}
                                                                                                                <i className="bx bx-info-circle" id ="SSLTMTP" />
                                                                                                                <UncontrolledTooltip placement="top" target="SSLTMTP"> 
                                                                                                                    {ToolTipData.stop_loss_trigger_market_take_profit}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="4" className=" mb-3">
                                                                                                        <Label className="mt-3">Trailing Stop Price Percentage{" "}<i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)} />
                                                                                                       
                                                                                                            </Label>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.trailing_take_profit_percentage} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                trailing_take_profit_percentage: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5"  max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="take_profit" value={settings.trailing_take_profit_percentage} 
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            trailing_take_profit_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.trailing_take_profit_percentage && settings.trailing_take_profit_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call trailing stop value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put trailing stop value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.trailing_take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                                            
                                                                                                            {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                                                                                            <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input"
                                                                                                            id="StarangleTrailling"
                                                                                                            checked={TraillingStrangles}
                                                                                                            onChange={(event) => setTraillingStrangles(event.target.checked)}
                                                                                                            />
                                                                                                            <label className="form-check-label font-size-10" htmlFor="StarangleTrailling">
                                                                                                            Combined Profit
                                                                                                            </label>{" "}
                                                                                                            <i className="bx bx-info-circle" id ="SSLTMTP" />
                                                                                                            <UncontrolledTooltip placement="top" target="SSLTMTP"> 
                                                                                                                {ToolTipData.stop_loss_trigger_market_take_profit}
                                                                                                            </UncontrolledTooltip>
                                                                                                        </div> */}
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                   
                                                                                                    {/* </Row>  */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="4" className="mb-3">
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Take Profit Percentage
                                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="TPP" />
                                                                                                            <UncontrolledTooltip placement="top" target="TPP"> 
                                                                                                                {ToolTipData.take_profit}
                                                                                                            </UncontrolledTooltip>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.take_profit_percentage} step={0.5} min={0} max={100} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                take_profit_percentage: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="0.5"  max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="take_profit" value={settings.take_profit_percentage}
                                                                                                                    //  onChange={(event) => setSettings({
                                                                                                                    //     ...settings,
                                                                                                                    //     take_profit_percentage: event.target.value
                                                                                                                    // })}
                                                                                                                    onChange={(e) => { 
                                                                                                                        const value = e.target.value;
                                                                                                                        let newValue = 100; // Default value
                                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                                            newValue = '';
                                                                                                                        } else if (value <= 100) {
                                                                                                                            newValue = value;
                                                                                                                        }
                                                                                                                        setSettings({
                                                                                                                            ...settings,
                                                                                                                            take_profit_percentage: newValue
                                                                                                                        });
                                                                                                                        }}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                            {bestStrikes && settings.take_profit_percentage && settings.take_profit_percentage > 0 ? 
                                                                                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call take profit value<span className="fw-bold"> {(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (settings.take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}>Put take profit value <span className="fw-bold">{(( Number(bestStrikes.puts.sell_price))- bestStrikes.puts.sell_price * (settings.take_profit_percentage/100)).toFixed(2)}</span></p></>:null}
                                                                                                             
                                                                                                        </Col>
                                                                                                        </Row>
                                                                                                        <Row className="border opacity-75 bg-light">
                                                                                                        <Col lg="3">
                                                                                                        <FormGroup className="mt-4 mb-0">
                                                                                                        <Label htmlFor="formrow-combined-sl">
                                                                                                        Combined Stop Loss
                                                                                                        {" "}
                                                                                                        <i className="bx bx-info-circle" id ="combined_sl_trigger" />
                                                                                                            <UncontrolledTooltip placement="top" target="combined_sl_trigger">
                                                                                                                {ToolTipData.stop_loss_combined}
                                                                                                            </UncontrolledTooltip>
                                                                                                            </Label>
                                                                                                        {/* <Slider value={triggerPercentage?triggerPercentage:0} min={0} max={100} step={0.5} onChange={value => {setTriggerPercentage(value)}} /> */}
                                                                                                        <div className="input-group">

                                                                                                        <input  type="number"  max={100} step={0.5} value={settings.combined_sl_percentage} onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            combined_sl_percentage: newValue
                                                                                                        });
                                                                                                        }} onBlur={(e) => { 
                                                                                                            const value = e.target.value;
                                                                                                            let newValue = 100; // Default value
                                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                                newValue = '';
                                                                                                            } else if (value <= 100) {
                                                                                                                newValue = value;
                                                                                                            }
                                                                                                            setSettings({
                                                                                                                ...settings,
                                                                                                                combined_sl_percentage: newValue
                                                                                                            });
                                                                                                            } } name="combined_sl_percentage" className="form-control" />
                                                                                                             <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                             </div>
                                                                                                             {bestStrikes && settings.combined_sl_percentage && settings.combined_sl_percentage > 0 ? 
                                                                                                          <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined stop loss value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))+((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_sl_percentage/100))).toFixed(2)}</span></p>
                                                                                                          :null}
                                                                                                        </FormGroup>    
                                                                                                       
                                                                                                        </Col>
                                                                                                        <Col lg="3">
                                                                                                            <FormGroup className="mt-4 mb-0">
                                                                                                            <Label htmlFor="formrow-combined-trail-profit">
                                                                                                            Combined Trailing Profit  {" "}
                                                                                                        <i className="bx bx-info-circle" id ="combined_trail_pro_trigger" />
                                                                                                            <UncontrolledTooltip placement="top" target="combined_trail_pro_trigger">
                                                                                                                {ToolTipData.combined_trailing_profit_percentage}
                                                                                                            </UncontrolledTooltip>
                                                                                                            </Label>
                                                                                                        <div className="input-group">
                                                                                                                
                                                                                                            <input  type="number"    max={100} step={0.5} value={settings.combined_trailing_profit_percentage} onChange={(e) => { 
                                                                                                        const value = e.target.value;
                                                                                                        let newValue = 100; // Default value
                                                                                                        if (value === null || value === '' || value < 0) {
                                                                                                            newValue = '';
                                                                                                        } else if (value <= 100) {
                                                                                                            newValue = value;
                                                                                                        }
                                                                                                        setSettings({
                                                                                                            ...settings,
                                                                                                            combined_trailing_profit_percentage: newValue
                                                                                                        });
                                                                                                        }  } onBlur={(e) => { 
                                                                                                            const value = e.target.value;
                                                                                                            let newValue = 100; // Default value
                                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                                newValue = '';
                                                                                                            } else if (value <= 100) {
                                                                                                                newValue = value;
                                                                                                            }
                                                                                                            setSettings({
                                                                                                                ...settings,
                                                                                                                combined_trailing_profit_percentage: newValue
                                                                                                            });
                                                                                                            } } name="combined_trailing_profit_percentage" className="form-control" />
                                                                                                            <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                            </div>
                                                                                                              {bestStrikes && settings.combined_trailing_profit_percentage && settings.combined_trailing_profit_percentage > 0 ? 
                                                                                                          <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing profit value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))-((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_trailing_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                          :null}
                                                                                                            </FormGroup>
                                                                                                        
                                                                                                        </Col>
                                                                                                        <Col lg="3">
                                                                                                            <FormGroup className="mt-4 mb-0">
                                                                                                            <Label htmlFor="formrow-combined-trail-sl">
                                                                                                            Combined Trailing Stop Price
                                                                                                            {" "}
                                                                                                        <i className="bx bx-info-circle" id ="combined_trail_sl_trigger" />
                                                                                                            <UncontrolledTooltip placement="top" target="combined_trail_sl_trigger">
                                                                                                                {ToolTipData.combined_trailing_stop_price_percentage}
                                                                                                            </UncontrolledTooltip>
                                                                                                                </Label>
                                                                                                            {/* <Slider value={triggerPercentage?triggerPercentage:0} min={0} max={100} step={0.5} onChange={value => {setTriggerPercentage(value)}} /> */}
                                                                                                        <div className="input-group">

                                                                                                            <input  type="number"  max={100} step={0.5} value={settings.combined_trailing_stop_price_percentage} onChange={(e) => { 
                                                                                                            const value = e.target.value;
                                                                                                            let newValue = 100; // Default value
                                                                                                            if (value === null || value === '' || value < 0) {
                                                                                                                newValue = '';
                                                                                                            } else if (value <= 100) {
                                                                                                                newValue = value;
                                                                                                            }
                                                                                                            setSettings({
                                                                                                                ...settings,
                                                                                                                combined_trailing_stop_price_percentage: newValue
                                                                                                            });
                                                                                                            } } onBlur={(e) => { 
                                                                                                                const value = e.target.value;
                                                                                                                let newValue = 100; // Default value
                                                                                                                if (value === null || value === '' || value < 0) {
                                                                                                                    newValue = '';
                                                                                                                } else if (value <= 100) {
                                                                                                                    newValue = value;
                                                                                                                }
                                                                                                                setSettings({
                                                                                                                    ...settings,
                                                                                                                    combined_trailing_stop_price_percentage: newValue
                                                                                                                });
                                                                                                                } } name="combined_trailing_stop_price_percentage" className="form-control" />
                                                                                                                <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                                </div>
                                                                                                                 {bestStrikes && settings.combined_trailing_stop_price_percentage && settings.combined_trailing_stop_price_percentage > 0 ? 
                                                                                                          <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing stop value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) - ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_trailing_stop_price_percentage/100))).toFixed(2)}</span></p>
                                                                                                          :null}
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        <Col lg="3">
                                                                                                            <FormGroup className="mt-4 mb-0">
                                                                                                            <Label htmlFor="formrow-combined-take-profit">
                                                                                                            Combined Take Profit   {" "}
                                                                                                        <i className="bx bx-info-circle" id ="combined_take_pro_trigger" />
                                                                                                            <UncontrolledTooltip placement="top" target="combined_take_pro_trigger">
                                                                                                                {ToolTipData.combined_take_profit_percentage}
                                                                                                            </UncontrolledTooltip>
                                                                                                            </Label>
                                                                                                        <div className="input-group">
                                                                                                                
                                                                                                            <input  type="number"   max={100} step={0.5} value={settings.combined_take_profit_percentage} onChange={(e) => { 
                                                                                                                const value = e.target.value;
                                                                                                                let newValue = 100; // Default value
                                                                                                                if (value === null || value === '' || value < 0) {
                                                                                                                    newValue = '';
                                                                                                                } else if (value <= 100) {
                                                                                                                    newValue = value;
                                                                                                                }
                                                                                                                setSettings({
                                                                                                                    ...settings,
                                                                                                                    combined_take_profit_percentage: newValue
                                                                                                                });
                                                                                                                } } onBlur={(e) => { 
                                                                                                                    const value = e.target.value;
                                                                                                                    let newValue = 100; // Default value
                                                                                                                    if (value === null || value === '' || value < 0) {
                                                                                                                        newValue = '';
                                                                                                                    } else if (value <= 100) {
                                                                                                                        newValue = value;
                                                                                                                    }
                                                                                                                    setSettings({
                                                                                                                        ...settings,
                                                                                                                        combined_take_profit_percentage: newValue
                                                                                                                    });
                                                                                                                    } } name="combined_take_profit_percentage" className="form-control" />
                                                                                                                    <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                                                                                                    </div>
                                                                                                                     {bestStrikes && settings.combined_take_profit_percentage && settings.combined_take_profit_percentage > 0 ? 
                                                                                                          <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined take profit value<span className="fw-bold"> {((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))- ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (settings.combined_take_profit_percentage/100))).toFixed(2)}</span></p>
                                                                                                          :null}
                                                                                                            </FormGroup>
                                                                                                        
                                                                                                        </Col>
                                                                                                        </Row>
                                                                                                        <Row  className="border opacity-75">
                                                                                                        <Col lg="3"  className="mb-3">
                                                                                                            {/* <FormGroup className="mb-0 mt-3"> */}
                                                                                                                <Label htmlFor="formrow-firstname-Input" className="mt-3">
                                                                                                                {/* <a className='link-secondary' target="_blank" href='/help#make_limit_orders_xpercentage'> */}
                                                                                                                  Make Limit Orders x% from Mid/MarkPrice:
                                                                                                                  {/* </a> */}{" "}
                                                                                                                   <i className="bx bx-info-circle" onClick={() => setVisibleMidPrize(!visibleMidPrize)}></i>
                                                                                                                </Label>{" "}
                                                                                                            {/* </FormGroup> */}
                                                                                                        {/* </Col>
                                                                                                        <Col lg="6"> */}
                                                                                                        <div className="mb-0">
                                                                                                        <input autoComplete="off"  type="number"     max={1000} step={0.5} value={StrangleMidPrice} onChange={(e) => { e.target.value <= 1000 ? setStrangleMidPrice(e.target.value) : setStrangleMidPrice(1000)} } onBlur={(e) => e.target.value <= 1000 ? setStrangleMidPrice(e.target.value) : setStrangleMidPrice(100)} name="mid_price_percentage" className="form-control" />
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="3" >
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Waiting time to fill Qty (H : M) <i className="bx bx-info-circle" id ="WTFQTY" />
                                                                                                            <UncontrolledTooltip placement="top" target="WTFQTY"> 
                                                                                                            {ToolTipData.waiting_time_to_fill_qty}
                                                                                                            </UncontrolledTooltip>
                                                                                                            </Label>{" "}
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            <div className="mb-0">
                                                                                                                <Flatpickr
                                                                                                                    className="form-control d-block"
                                                                                                                    placeholder="Select time" value={settings.Qty_time_clock ? settings.Qty_time_clock : Bot_settings_default.Strangles.waiting_time_to_fill_qty} onChange={(e) => setSettings({
                                                                                                                        ...settings,
                                                                                                                        Qty_time_clock: new Date(e).getHours() + ':' + new Date(e).getMinutes()
                                                                                                                    })}
                                                                                                                    options={{
                                                                                                                        enableTime: true,
                                                                                                                        noCalendar: true,
                                                                                                                        dateFormat: "H:i",
                                                                                                                        time_24hr: true
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* </Col> */}
                                                                                                    {/* </> :""} */}
                                                                                                
                                                                                                {/* {strangleAdvancedSettings ? */}
                                                                                                {/* <Col lg="12" >  */}
                                                                                                {/* <Col> */}
                                                                                                
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="2" >
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Automatic Market Fill
                                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="AMF" />
                                                                                                            <UncontrolledTooltip placement="top" target="AMF"> 
                                                                                                                {ToolTipData.automatic_market_fill}
                                                                                                            </UncontrolledTooltip>
                                                                                                        {/* </Col>
                                                                                                        <Col lg="4"> */}
                                                                                                            <div className="mb-3">
                                                                                                                <Switch
                                                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                                                    checkedIcon={<OnSymbol />}
                                                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                                                    checked={Boolean(Number(settings.autoMarketFill))}
                                                                                                                    onChange={(e) => setSettings({
                                                                                                                        ...settings,
                                                                                                                        autoMarketFill: !settings.autoMarketFill
                                                                                                                    })}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        <Col lg="3" >
                                                                                                            <Label for="basicpill-namecard-input11"  className="mt-3">
                                                                                                                Universal stop
                                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="USS" />
                                                                                                            <UncontrolledTooltip placement="top" target="USS"> 
                                                                                                            {ToolTipData.universal_stop}
                                                                                                            </UncontrolledTooltip>
                                                                                                        {/* </Col>
                                                                                                        <Col lg="4"> */}
                                                                                                            <div className="mb-3">
                                                                                                                <Switch
                                                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                                                    checkedIcon={<OnSymbol />}
                                                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                                                    checked={Boolean(Number(settings.universal_stop))}
                                                                                                                    onChange={(e) => setSettings({
                                                                                                                        ...settings,
                                                                                                                        universal_stop: !settings.universal_stop
                                                                                                                    })}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                   
                                                                                              
                                                                                                           
                                                                                                      
                                                                                                        {/* <Row className="mt-3"> */}
                                                                                   
                                                                                        <Row  className="border opacity-75 bg-light">
                                                                                            <Col lg="2">
                                                                                            <h5 className="font-size-15 mt-5 mb-3 ">
                                                                                    Delta Hedging Settings
                                                                                    </h5>
                                                                                            </Col>
                                                                                    <Col lg="4" className="mt-4 mb-3" >
                                                                                      {/* <FormGroup className=""> */}
                                                                                        <Label htmlFor="formrow-firstname-Input">
                                                                                          Enable <a className="text-primary" style={{cursor:"default"}}>Delta Hedging</a>
                                                                                        </Label>{" "}
                                                                                        <i className="bx bx-info-circle" id ="EDH" />
                                                                                          <UncontrolledTooltip placement="top" target="EDH">
                                                                                              {ToolTipData.delta_hedging}
                                                                                          </UncontrolledTooltip>
                                                                                          
                                                                                          {/* <input type="checkbox" className="btn-check" id="btn-check-outlined"  onClick={(e)=> {setDeltahedging(e.target.checked)}}/><br></br>
                                                                                            <label style={{width:"80px"}} className={"btn " + (Deltahedging === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined" >{Deltahedging === true ? "Disable" : "Enable"}</label><br></br> */}
                                                                                      {/* </FormGroup> */}
                                                                                      
                                                                                      {/* </Col>
                                                                                      <Col md="2"> */}
                                                                                      <div>
                                                                                            <Switch
                                                                                            uncheckedIcon={<Offsymbol />}
                                                                                            checkedIcon={<OnSymbol />}
                                                                                            className="me-1 mb-sm-8 mb-2"                                      
                                                                                            onChange={(e) => {setDeltahedging(e),setSettings({...settings,deltahedging:e === true ? 1 : 0})}} 
                                                                                            checked={Deltahedging}
                                                                                          />
                                                                                          </div>
                                                                                          {
                                                                                           settings && settings.delta_hedging_range && settings.delta_hedging_interval ?
                                                                                          <div  className='font-size-10'>Your Settings<i className="mdi mdi-arrow-right
                                                                                      ms-1 text-primary "></i>
                                                                                                <span  className='text-secondary'>Delta Range: <span className='text-info'>{settings.delta_hedging_range}</span> , Interval time for delta checking:  <span className='text-info'>{timeFormat(settings.delta_hedging_interval)} </span> , Order Type: <span className='text-info'>{settings.delta_hedging_order_type?settings.delta_hedging_order_type:'market_order'}</span> , 
    Hedge only for the option with loss: <span className='text-info'>{settings.hedge_only_for_loss? 'Yes ('+settings.hedging_min_loss_value+' '+ (settings.hedging_min_loss_type=='percentage'?'%':'pnts')+')': 'No'}</span> <Link to="/settings?strangles"><i className="bx bx-edit-alt" style={{width:"15px"}}/></Link></span>

                                                                                                </div> : ""
} 
                                                                                    </Col>
                                                                                    
                                                                                    {/* </Row> */}
                                                                                    {/* <Row className="mt-3"> */}
                                                                                    {
                                                                                      Deltahedging ? <>
                                                                                    <Col lg="5"  className="mt-4 mb-3">
                                                                                      {/* <FormGroup className="mb-4"> */}
                                                                                        <Label htmlFor="formrow-firstname-Input">
                                                                                        Disable Delta Hedging on Stop Loss in One Leg
                                                                                        {" "}
                                                                                        <i className="bx bx-info-circle" id ="DHOSLIOL" />
                                                                                          <UncontrolledTooltip placement="top" target="DHOSLIOL">
                                                                                              {ToolTipData.delta_hedging_on_stop_loss_in_one_leg}
                                                                                          </UncontrolledTooltip></Label>
                                                                                          {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                                                                            <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                                                                
                                                                                      {/* </FormGroup> */}
                                                                                    {/* </Col> */}
                                                                                    <Col md="2">
                                                                                            <Switch
                                                                                            uncheckedIcon={<Offsymbol />}
                                                                                            checkedIcon={<OnSymbol />}
                                                                                            className="me-1 mb-sm-8 mb-2"                                      
                                                                                            onChange={(e) => {setDeltahedging2(e),setSettings({...settings,Delta_Hedging_on_Stop_Loss_in_One_Leg:e === true ? 1 : 0})}} 
                                                                                            checked={Deltahedging2}
                                                                                          />
                                                                                          </Col>
                                                                                          </Col>

                                                                                    </> : ''
                                                                                    }
                                                                                    </Row>
                                                                                    
                                                                                    
                                                                                    {/* </Row> */}
                                                                                    {/* </Col> */}
                                                                                                {/* </Col> */}  
                                                                                            </>:""}
                                                                                          
                                                                                        </Col>
                                                                                    </Row>
                                                                                    





                                                                                </Col>
                                                                            {/* </Row> */}

                                                                        </Form>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tabId={4}>
                                                                    <div className="row justify-content-center">
                                                                        <Col lg="6">
                                                                            <div className="text-center">
                                                                                <div className="mb-4">
                                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                                </div>
                                                                                <div>
                                                                                    <h5>Bot started successfully</h5>

                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </TabPane>
                                                            </TabContent>
                                                            {(activeTab == 2 && (!loading.best_strikes_validation && validateStrikes != '')) && <>
                                                                <Alert color="danger" role="alert">
                                                                    {validateStrikes}
                                                                </Alert></>}
                                                        </div>
                                                        <div className="actions clearfix">

                                                            <ul>
                                                                {
                                                                    activeTab !== 1 && !(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation))
                                                                        ?
                                                                        <li
                                                                            className={
                                                                                activeTab === 1
                                                                                    ? "previous disabled"
                                                                                    : "previous"
                                                                            }
                                                                        >
                                                                            <Link
                                                                                to="#"
                                                                                onClick={() => {
                                                                                    toggleTab(activeTab - 1);
                                                                                }}
                                                                            >
                                                                                Previous
                                                                            </Link>
                                                                        </li> : ''}
                                                                {activeTab !== 4 ?
                                                                    <li
                                                                        className={
                                                                            activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)
                                                                                ? "next disabled"
                                                                                : "next"
                                                                        }
                                                                    >

                                                                        {(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation)) || (activeTab == 3 && loading.start_bot) ? <Link> <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing...</span></Link> :
                                                                            <Link
                                                                                to="#"
                                                                                onClick={() => {
                                                                                    (activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)) ? '' :
                                                                                        toggleTab(activeTab + 1);
                                                                                }}
                                                                            >
                                                                                {activeTab === 3 ? "Start bot" : "Next"}
                                                                            </Link>
                                                                        }

                                                                    </li>
                                                                    : ""
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    </CardBody>
                                </Card>
                            </Col>




                        </Row>
                    ):''}
                </Container>
                <Container fluid>

                    <Row>
                        <Col lg="12">
                            <Card className="box_4">                            
                                <CardBody>                                
                                    <h4 className="card-title mb-3">Orders</h4>

                                    

                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "1",
                                                })}
                                                onClick={() => {
                                                    setpopovertop({});
                                                    toggleOrderTab("1");
                                                }}
                                            >
                                                Active strangles
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "2",
                                                })}
                                                onClick={() => {
                                                    setpopovertop({});
                                                    toggleOrderTab("2");
                                                }}
                                            >
                                                Completed strangles
                                            </NavLink>
                                        </NavItem>
                                    </ul>

                                    <TabContent activeTab={activeOrderTab} className="p-3">
                                        <TabPane tabId="1" id="all-order">
                                            <div className="table-responsive">
                                                {/*********/}                                                
                                                <Table className="table table-bordered table align-middle table-nowrap table-check">
                                                    <thead >
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sell/Buy Price</th>
                                                            <th>Trigger/SL/Take Profit</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th>LTP</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop}
                                                                    target="TooltipTop"
                                                                    toggle={() => {
                                                                        setttop(!ttop);
                                                                    }}
                                                                >
                                                                    Unrealised PNL = price - latest ask
                                                                </Tooltip>
                                                                <span id="TooltipTop">UPNL</span>
                                                            </th>
                                                            <th className="text-center" colSpan={2}>Action</th>
                                                        </tr>
                                                    </thead>

                                                    { loading.Table_load === true ? 
                                                     (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    Loading...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : 
                                                    orderData.length > 0 ? (
                                                        orderData.map((row, index) => {
                                                            let indexs = (index+1)%2
                                                            return (
                                                                <tbody key={index}>
                                                                    {row.map((order, index, arr) => {
                                                                        let market_action = getStrangleMarketAction(order);
                                                                        const roll_time_clock =(order.settings.roll_status==1 && order.settings.Roll_time_clock)? order.settings.Roll_time_clock.split(":"):"";
                                                                        return (
                                                                            <tr className={"trow-hover "+ (indexs === 0 ? 'table-common-color': '')} style={(indexs === 0 ? {color:'#74788d'}:{})} key={index}>
                                                                                {index === 0 ?
                                                                                    <td className="p-2 " rowSpan={row.length}> 
                                                                                        <div className="border p-2">
                                                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                            <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-" + order.strangle_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`${order.strangle_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`${order.strangle_id}`)}
                                                                                                    target={"Popovertop-" + order.strangle_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`${order.strangle_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index %: <b>{order.settings.index_percentage}</b> <br />
                                                                                                        {!order.admin_bot? <>Stop Loss Activate % <b>{order.settings.trigger_percentage}</b><br /></>:''} 
                                                                                                        Market Stop Loss %  <b>{order.settings.stop_loss_percentage}</b> <br />
                                                                                                        Index Price Range % for Stop Loss: <b>{order.settings.stop_loss_index_percentage}</b> <br />
                                                                                                        Mid price% :<b>{order.settings.mid_price_percentage}</b> <br />
                                                                                                        Trailing Take Profit  % <b>{order.settings.min_take_profit_percentage ? order.settings.min_take_profit_percentage : 0}</b> <br />
                                                                                                        Trailing Stop Price % <b>{order.settings.trailing_take_profit_percentage ? order.settings.trailing_take_profit_percentage : 0}</b> <br />
                                                                                                        Take Profit % <b>{order.settings.take_profit_percentage}</b> <br />
                                                                                                        Quantity: <b>{order.settings.quantity}</b> <br />
                                                                                                        Waiting time to fill Qty (H : M): <b>{order.settings.waitTimeForFillAllQty ? timeFormat(order.settings.waitTimeForFillAllQty) : '--'}</b> <br />
                                                                                                        {
                                                                                                            order.settings.roll_status  === 1 ?
                                                                                                            <>
                                                                                                        When to Roll? (H : M): <b>{order.settings.Roll_time_clock ? (roll_time_clock[0]+ 'h ' +roll_time_clock[1] + 'm') : '--'}</b> <br />
                                                                                                        Distance from index (Roll): <b>{order.settings.index_distance}</b> <br />
                                                                                                        Premium Roll % (Roll): <b>{order.settings.roll_percentage}</b> <br />
                                                                                                            </>: '' 
                                                                                                        }
                                                                                                        Automatic market fill: <b>{order.settings.autoMarketFill === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Universal stop: <b>{order.settings.universal_stop === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Enable Delta Hedging : <b>{order.settings.deltahedging === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        {
                                                                                                            order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg ? (
                                                                                                                <>
                                                                                                        Disable Delta Hedging on Stop Loss in One Leg : <b>{order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        </>) : '' 
                                                                                                    }
                                                                                                    {order.settings.combined_deactivated? (
                                                                                                                <>Combined Deactivated: <b>true</b><br/></>
                                                                                                            ) : ''}
                                                                                                   {order.settings.reentry_enabled ? (
                                                                                                                <>Re-Entry Enabled: <b>true</b><br/>Waiting time for Re-Entry: <b>{order.settings.wait_time_reentry ?timeFormat(order.settings.wait_time_reentry) :"--" }</b></>
                                                                                                            ) : <>Re-Entry Enabled: <b>false</b><br/></>}
                                                                                                            {order.settings.stopdate_reentry ? (
                                                                                                                <><br/>Stop Date Re-Entry: <b>{order.settings.stopdate_reentry}</b></>
                                                                                                            ) : ''}
                                                                                                    {order.settings.combined_sl_percentage ? <>Combined stop loss: <b>{Number(order.settings.combined_sl_percentage)}%</b><br/></>: ''} 
                                                                                                    {order.settings.combined_trailing_profit_percentage ? <>Combined trailing take profit: <b>{Number(order.settings.combined_trailing_profit_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_trailing_stop_price_percentage ? <>Combined trailing stop price: <b>{Number(order.settings.combined_trailing_stop_price_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_take_profit_percentage ? <>Combined take profit: <b>{Number(order.settings.combined_take_profit_percentage)}%</b><br/></> : ''} 
                                                                                                </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                            <div className="text-primary">Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>
                                                                                            

                                                                                            {order.settings.current_index_price ? <div className="align-text-bottom created-date text-muted">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.settings.created_at)}</div>
                                                                                            <div className="text-muted font-size-10">{moment.utc(getMomentDateTimeFormat(order.settings.created_at)).local().startOf('seconds').fromNow()}</div>
                                                                                            {(order.settings.automatic_bot && order.settings.automatic_bot == 1) ? <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}
                                                                                            {order.settings.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}

                                                                                        </div>
                                                                                    </td>
                                                                                    : ''}
                                                                                <td>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                                <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                    {order.symbol} 
                                                                                    </Badge></a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                                        </div>
                                                                                        : ''}
                                                                                         {order.reentry_stopped ? <div><span className="text-muted font-size-10 ">Re-Entry Stopped</span></div> : ""}
                                                                                </td>

                                                                                <td>
                                                                                    <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.direction!=="buy" && order.status=='closed')  ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                                                                    {order.highest_bid_now || order.lowest_ask_now ?
                                                                                        <div>
                                                                                        <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                                                        <span className="text-muted">, </span>
                                                                                        <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                                                        { order.direction === "sell" &&  (order.settings.combined_sl || order.settings.combined_trailing_profit) && order.sum_of_current_price && order.step==1?<div className="text-muted font-size-10">Combined Current price: <span className="text-primary">{order.sum_of_current_price}</span></div>:''}
                                                                                        {order.direction === "sell" && order.current_profit_percentage  && order.bot_status!=="completed"? <div>
                                                                                            <span className="text-muted font-size-10 d-block">Current Profit/Loss: {
                                                                                                order.current_profit_percentage > 0?
                                                                                                <span className="text-success">{ 
                                                                                                Number(order.current_profit_percentage).toFixed(2)}</span>:<span className="text-danger">{Number(order.current_profit_percentage).toFixed(2)}</span>}%</span>
                                                                                        </div>
                                                                                        :""}
                                                                                        
                                                                                        </div>
                                                                                        : ""
                                                                                    }
                                                                                </td>
                                                                                <td><div className="text-muted text-success font-size-10">
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate {order.settings.combined_sl_percentage?" (Combined)":""}: {getPrices(order, "trigger")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'stop_loss' ? "text-danger" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss {order.settings.combined_sl_percentage?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'take_profit' ? "text-success" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price{order.settings.combined_trailing_profit_percentage?" (Combined)":""}: {getPrices(order, "take_profit")}</span><br />
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Min Take profit Price{order.settings.combined_trailing_profit_percentage?" (Combined)":""}: {getPrices(order, "min_take_profit")} </span>
                                                                                    {order.is_min_take_profit_reached?
                                                                                   <><br /> <span className={order.order_type.toLowerCase() === 'trailing_stop_loss' ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take profit Price: {parseFloat(order.trailing_stop_loss_price).toFixed(2)} {order.new_tailing_profit_percentage && order.is_min_take_profit_reached ?"("+order.new_tailing_profit_percentage+"%)": ""}</span></>
                                                                                    :''}
                                                                                </div>
                                                                                </td>
                                                                                <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td>{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order) }}></span></td>
                                                                                <td>{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                                                                {/* <td title={order.unrealized_pnl_exact ? order.unrealized_pnl_exact : ''}><small>{order.unrealized_pnl} {order.unrealized_pnl_currency ? order.unrealized_pnl_currency : ''}</small></td> */}
                                                                                <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small className={order.unrealized_pnl > 0 ? "text-success" : "text-danger"}>{order.unrealized_pnl && order.unrealized_pnl!='' ? CustomParseFloat(order.unrealized_pnl, 2) + ' USDT' : '...'}</small></td>
                                                                                <td >
                                                                                    <div className="d-flex gap-2">
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                getHistoryBysymbol(order.profile.uid,order.dbid,order.symbol)
                                                                                            }
                                                                                            className="text-info"
                                                                                            disabled={order.status.toLowerCase() === "waiting" ? true : false}
                                                                                        >
                                                                                            <i className="mdi mdi-history font-size-18"></i>
                                                                                        </a>
                                                                                        {stopStrangleFlag[order.symbol] && stopStrangleFlag[order.symbol] == 1 ?
                                                                                            <a className="btn btn-sm" style={{ "marginLeft": "10px" }} >
                                                                                                <div className="spinner-border text-danger  spinner-border-sm" role="status">
                                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                                </div>
                                                                                            </a>
                                                                                            : <a onClick={() =>
                                                                                                marketCloseOrder(order)
                                                                                            }
                                                                                                className="text-danger"
                                                                                            >
                                                                                                <i className="mdi mdi-delete font-size-18"></i>
                                                                                            </a>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                {/*console.log(stopStrangleFlag[order.strangle_id])*/}
                                                                                {index === 0 ?
                                                                                    !stopStrangleFlag[order.strangle_id] ?
                                                                                        order.strangle_status.toLowerCase() === 'closed' ?
                                                                                            <td className="align-middle  " rowSpan={row.length} >
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseStrangle(order.strangle_id, 'market_close')
                                                                                                            }
                                                                                                            className="btn btn-soft-danger btn-sm"
                                                                                                        >
                                                                                                            Market Close 
                                                                                                        </button></div>
                                                                                                    {(order.market_fill_status == 1 && order.settings.autoMarketFill != 1) ? <div style={{ 'marginTop': "10px" }}> <button
                                                                                                        type="button"
                                                                                                        onClick={() =>
                                                                                                            marketFillStrangle(order.strangle_id)
                                                                                                        }
                                                                                                        className="btn btn-soft-success  btn-sm"
                                                                                                    >
                                                                                                        Market Fill
                                                                                                    </button> </div> : ''}
                                                                                                    {order.reentry_stopped != 1 ? (order.settings.reentry_enabled && order.settings.reentry_enabled==1 ? <div style={{ 'marginTop': "10px" }}> <button type="button" onClick={() => stopReentry(order.strangle_id)} className="btn btn-soft-danger  btn-sm">Stop Re-Entry</button> </div> : ''):''}
                                                                                                </div>
                                                                                            </td>
                                                                                            : stopStrangleFlag[order.strangle_id] && stopStrangleFlag[order.strangle_id] == 1 ? <td rowSpan={row.length}><div style={{ "color": "red" }}><Spinner animation="border" size="sm" variant="danger" />Stopping the order(s)..</div></td> : <td className="align-middle" rowSpan={row.length}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseStrangle(order.strangle_id, 'stop')
                                                                                                            }
                                                                                                            className="btn btn-danger btn-sm "
                                                                                                        >
                                                                                                            Stop
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    {(order.market_fill_status == 1 && order.settings.autoMarketFill != 1) ? <div style={{ 'marginTop': "10px" }}> <button
                                                                                                        type="button"
                                                                                                        onClick={() =>
                                                                                                            marketFillStrangle(order.strangle_id)
                                                                                                        }
                                                                                                        className="btn btn-soft-success  btn-sm"
                                                                                                    >
                                                                                                        Market Fill
                                                                                                    </button> </div> : ''}
                                                                                                   {/* { order.settings.reentry_enabled && order.settings.reentry_enabled==1 ? <div style={{ 'marginTop': "10px" }}> <button type="button" onClick={() => stopReentry(order.strangle_id)} className="btn btn-soft-danger  btn-sm">Stop Re-Entryyy</button> </div> : '' } */}
                                                                                                </div>
                                                                                            </td>
                                                                                        : stopStrangleFlag[order.strangle_id] && <td rowSpan={row.length}><div style={{ "color": "red" }}>{(order.status=="closed" && order.step==2 && order.settings.reentry_enabled==1)?'Closed and waiting for Re-Entry':<><Spinner animation="border" size="sm" variant="danger" /> Closing</>}</div> 											</td>
                                                                                    : ''}
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" id="processing">
                                        <Card className="border">
                                            <CardBody>
                                            <Row>
                                                <Col xxl={1} lg={6} className="d-flex align-items-center">
                                                <h5><b>Filter</b></h5>
                                                </Col>
                                                <Col xxl={2} lg={6}>
                                                <Label htmlFor="Created">Created date</Label>
                                                <Flatpickr className="form-control d-block"
                                                    placeholder="Created Date"
                                                    options={{
                                                    mode: "range",
                                                    dateFormat: "d/m/Y",
                                                    defaultDate: [filterStartDate, filterEndDate]
                                                    }}
                                                    onChange={handleCreatedDateChange}
                                                />
                                                </Col>
                                            </Row>
                                            </CardBody>
                                        </Card>    
                                        <div className="table-responsive">
                                                {/*********/}
                                                {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                                                <Table className={"table table-bordered table align-middle table-nowrap table-check " }>
                                                    <thead>
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sold/Bought Price</th>
                                                            <th>Trigger/SL/Take Profit Price</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop1}
                                                                    target="TooltipTop1"
                                                                    toggle={() => {
                                                                        setttop1(!ttop1);
                                                                    }}
                                                                >
                                                                    Booked PNL = sell price - buy price
                                                                </Tooltip>
                                                                <span id="TooltipTop1">Booked PNL</span>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    { loading.Table_load === true ? 
                                                     (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    Loading...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : transactionData.length > 0 ? (
                                                        transactionData.map((row, index) => {
                                                            let last_roll_index;
                                                            let indexs = (index+1)%2
                                                            var PNL = 0;
                                                            row.sort(function(a, b) {return a.symbol.substring(0, 1) - b.symbol.substring(0, 1)})
                                                            // row.sort(function(a, b) {return a.symbol - b.symbol})
                                                             row.sort(function(a, b) {return a.roll_index - b.roll_index})
                                                            let row1 = []
                                                            let row2 = []
                                                            let sym = ''
                                                            row.map((order, index, arr) => {
                                                                if (row1.length >= 0 && sym != order.symbol && sym != '') {
                                                                    row1.push(order)
                                                                } else {
                                                                    sym = order.symbol
                                                                    row2.push(order)
                                                                }
                                                            })
                                                            let all_puts = row.filter(t => t.symbol.substring(0, 1)=="P")
                                                            let all_calls = row.filter(t => t.symbol.substring(0, 1)=="C")
                                                            return (
                                                                <tbody key={index}>
                                                                    {row.map((order, index, arr) => {
                                                                          last_roll_index=order.roll_index
                                                                        let div = row.length / 2
                                                                        if(order.symbol.substring(0, 1)=="C" && (index == 0 || (div >= 2 && index % 2 == 0)) ){
                                                                        let booked_pnl = row[index+1] && row[index+1].direction === 'sell' ? getPrices(row[index+1], "booked_pnl") : "..."
                                                                        let market_action = getStrangleMarketAction(order);
                                                                        const roll_time_clock =(order.settings.roll_status==1 && order.settings.Roll_time_clock)? order.settings.Roll_time_clock.split(":"):"";
                                                                        {row.map((order2, index2, arr) => {
                                                                            let booked_pnl2 = order2.direction === 'sell' ? getPrices(order2, "booked_pnl") : "..."
                                                                            if (index2 === 0){ 
                                                                                PNL = 0;
                                                                            }
                                                                            var pnl = parseFloat(order2.realized_pnl ? order2.realized_pnl  : booked_pnl2 !== '...' ? parseFloat(booked_pnl2).toFixed(4)  : 0)
                                                                            PNL = PNL + pnl
                                                                          //  console.log(PNL , index ,pnl, '***********************************') 
                                                                        })}
                                                                        return (
                                                                            <tr style={{color:"#8a90a0"}}   key={index}>
                                                                                {index === 0 ?
                                                                                    <td  className={(indexs % 2 === 0) ? 'table-white p-2' : 'table-light p-2'} style={{ "verticalAlign": "top" }} rowSpan={row.length}>

                                                                                        <div className="border p-2">
                                                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                            <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-completed-" + order.strangle_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`completed-${order.strangle_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`completed-${order.strangle_id}`)}
                                                                                                    target={"Popovertop-completed-" + order.strangle_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`completed-${order.strangle_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index %: <b>{order.settings.index_percentage}</b> <br />
                                                                                                        {!order.admin_bot? <>Stop Loss Activate %{order.settings.combined_sl?" (Combined)":""}: <b>{order.settings.trigger_percentage}</b> <br /></>:''}
                                                                                                        Market Stop Loss %{order.settings.combined_sl?" (Combined)":""}: <b>{order.settings.stop_loss_percentage}</b> <br />
                                                                                                        Index Price Range % for Stop Loss: <b>{order.settings.stop_loss_index_percentage}</b> <br />
                                                                                                        Trailing Take Profit  %{order.settings.combined_trailing_profit?" (Combined)":""}: <b>{order.settings.min_take_profit_percentage ? order.settings.min_take_profit_percentage : 0}</b> <br />
                                                                                                        Trailing Stop Price %: <b>{order.settings.trailing_take_profit_percentage ? order.settings.trailing_take_profit_percentage : 0}</b> <br />
                                                                                                        Take Profit %{order.settings.combined_trailing_profit?" (Combined)":""}: <b>{order.settings.take_profit_percentage}</b> <br />
                                                                                                        Quantity: <b>{order.settings.quantity}</b> <br />
                                                                                                        {
                                                                                                            order.settings.mid_price_percentage !== undefined && order.settings.mid_price_percentage != '' ? <>
                                                                                                        Make Limit Orders x% from Mid/MarkPrice: <b>{order.settings.mid_price_percentage}</b> <br />
                                                                                                        </>:""
                                                                                                        }
                                                                                                        Waiting time to fill Qty (H : M): <b>{order.settings.waitTimeForFillAllQty ? timeFormat(order.settings.waitTimeForFillAllQty) : '--'}</b> <br />
                                                                                                        {
                                                                                                            order.settings.roll_status  === 1 ?
                                                                                                            <>
                                                                                                        When to Roll? (H : M): <b>{(order.settings.roll_status==1 && order.settings.Roll_time_clock) ? (roll_time_clock[0]+ 'h ' +roll_time_clock[1] + 'm') : '--'}</b> <br />
                                                                                                        Distance from index (Roll): <b>{order.settings.index_distance}</b> <br />
                                                                                                        Premium Roll % (Roll): <b>{order.settings.roll_percentage}</b> <br />
                                                                                                            </>: '' 
                                                                                                        }
                                                                                                        Automatic market fill: <b>{order.settings.autoMarketFill === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Universal stop: <b>{order.settings.universal_stop === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Enable Delta Hedging : <b>{order.settings.deltahedging === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        {
                                                                                                            order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg ? (
                                                                                                                <>
                                                                                                        Disable Delta Hedging on Stop Loss in One Leg : <b>{order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        </>) : '' 
                                                                                                    }
                                                                                                    {
                                                                                                            order.settings.combined_deactivated ? (
                                                                                                                <>Combined Deactivated: <b>true</b><br/></>
                                                                                                            ) : ''}
                                                                                                            {order.settings.reentry_enabled ? (
                                                                                                                <>Re-Entry Enabled: <b>true</b><br/>Waiting time for Re-Entry: <b>{order.settings.wait_time_reentry ?timeFormat(order.settings.wait_time_reentry) :"--" }</b></>
                                                                                                            ) : <>Re-Entry Enabled: <b>false</b><br/></>}
                                                                                                            {order.settings.stopdate_reentry ? (
                                                                                                                <><br/>Stop Date Re-Entry: <b>{order.settings.stopdate_reentry}</b></>
                                                                                                            ) : ''}
                                                                                                            {order.settings.combined_sl_percentage ? <>Combined stop loss: <b>{Number(order.settings.combined_sl_percentage)}%</b><br/></>: ''} 
                                                                                                            {order.settings.combined_trailing_profit_percentage ? <>Combined trailing take profit: <b>{Number(order.settings.combined_trailing_profit_percentage)}%</b><br/></> : ''} 
                                                                                                            {order.settings.combined_trailing_stop_price_percentage ? <>Combined trailing stop price: <b>{Number(order.settings.combined_trailing_stop_price_percentage)}%</b><br/></> : ''} 
                                                                                                            {order.settings.combined_take_profit_percentage ? <>Combined take profit: <b>{Number(order.settings.combined_take_profit_percentage)}%</b><br/></> : ''}
                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                            <div className="text-primary" >Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>
                                                                                            {order.settings.current_index_price ? <div className="align-text-bottom created-date text-muted">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.settings.created_at)}</div>
                                                                                            <div className="text-muted font-size-12" style={{float:"left"}}>Total PNL : <text style={PNL > 0 ? {color:"green"} : {color:"red"}}>{PNL.toFixed(4)}</text> USDT</div><br></br>
                                                                                            <div >{(order.settings.automatic_bot && order.settings.automatic_bot == 1) ? <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}</div>
                                                                                            {order.settings.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}
                                                                                        
                                                                                        </div>
                                                                                    </td>
                                                                                    : ''}
                                                                                <td  className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>
                                                                                {row[index+1] && row[index+1].symbol.substring(0, 1)=="C" ? <><Badge
                                                                                        color={row[index+1].direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {row[index+1].direction}
                                                                                    </Badge> <br></br></>: ''}
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                    
                                                                                </td>
                                                                                <td  className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>
                                                                                {row[index+1] && row[index+1].symbol.substring(0, 1)=="C" ? <><a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+row[index+1].symbol} target="_blank" rel="noreferrer" > 
                                                                                    <Badge color={row[index+1].direction === "buy" ? "success" : "danger"}>
                                                                                    {row[index+1].symbol} 
                                                                                    </Badge></a><br></br></> : ''}
                                                                                <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                        {order.symbol} 
                                                                                    </Badge></a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-muted font-size-10 "  id={"trigger_calc"+order.order_id}>{market_action} </span>
                                                                                            {order.reentry_stopped ? <div><span className="text-muted font-size-10 ">Re-Entry Stopped</span></div> :<><br /></>}
                                                                                            {order.index_price !== undefined ? <><span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                                                                            {order.order_type.toLowerCase()=="stop_loss" && order.settings.combined_sl && order.market_action_associateorder_lowest_ask ?     
                                                                                                <UncontrolledTooltip placement="bottom" target={"trigger_calc"+order.order_id}> 
                                                                                            { "("+order.market_action_associateorder_lowest_ask+" + "+order.market_action_lowest_ask+") > "+getPrices(order, "stop_loss")}
                                                                                            </UncontrolledTooltip>                                      :""}
                                                                                        </div>
                                                                                        : ''}
                                                                                        
                                                                                </td>

                                                                                {/* className={highlighting && GetModifiOrderId == order.order_id && order.status.toLowerCase() === "open" ? 'element highlight':'element'} */}
                                                                                <td className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>{row[index+1] && row[index+1].symbol.substring(0, 1)=="C" ? <h6 className={row[index+1].direction === "buy" ? "text-success" : "text-danger"}>{(row[index+1].average_fill_price && row[index+1].direction !== "buy"  && row[index+1].status=='closed') ? Number(row[index+1].average_fill_price).toFixed(2) :Number(row[index+1].price).toFixed(2) }</h6> : '' }<h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.direction !== "buy"  && order.status=='closed') ? Number(order.average_fill_price).toFixed(2) :Number(order.price).toFixed(2) }</h6></td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}><div className="text-muted text-success font-size-10">
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate {order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "trigger")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'stop_loss' ? "text-danger" : 'text-muted'} ><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss {order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'take_profit' ? "text-success" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price{order.settings.combined_trailing_profit?" (Combined)":""}: {getPrices(order, "take_profit")}</span><br />
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Min Take profit Price{order.settings.combined_trailing_profit?" (Combined)":""}: {getPrices(order, "min_take_profit")}</span>
                                                                                    {order.is_min_take_profit_reached?
                                                                                   <><br /> <span className={order.order_type.toLowerCase() === 'trailing_stop_loss' ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take profit Price: {parseFloat(order.trailing_stop_loss_price).toFixed(2)}</span></>
                                                                                    :''}
                                                                                   


                                                                                </div>
                                                                                </td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}>{row[index+1] && row[index+1].symbol.substring(0, 1)=="C" ? <>{Number(row[index+1].filled_quantity).toFixed(0)}/{Number(row[index+1].settings.quantity).toFixed(0)}<br></br></> : ''}{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)} </td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}>{row[index+1] && row[index+1].symbol.substring(0, 1)=="C" ? <>{row[index+1].order_id !== "" ? row[index+1].order_id : "Nil"}<br></br></> : ''}{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}><span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order, "history") }}></span></td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}><small title={row[index+1] && row[index+1].realized_pnl ? row[index+1].realized_pnl : booked_pnl !== '...' ? booked_pnl : ''} className={row[index+1] && row[index+1].realized_pnl ? (row[index+1].realized_pnl > 0 ? "text-success" : "text-danger" ): (parseFloat(booked_pnl) > 0 ? "text-success" : "text-danger")}>{row[index+1] && row[index+1].realized_pnl ? row[index+1].realized_pnl + ' USDT' : booked_pnl !== '...' ? parseFloat(booked_pnl).toFixed(4) + ' USDT' : '...'}</small></td>


                                                                            </tr>
                                                                        )
                                                                                                    }
                                                                    })}
                                                                    
                                                                    {all_puts.map((order, index, arr) => {
                                                                        let div = row.length / 2
                                                                        
                                                                        if(order.symbol.substring(0, 1)=="P" && (index == 0 || (div >= 2 && index % 2 == 0)) ){
                                                                        //  (index == 2 || ((div == 0 || div == 1) && index % 2 != 0)) ){
                                                                        
                                                                        let booked_pnl = all_puts[index+1] && all_puts[index+1].direction === 'sell' ? getPrices(all_puts[index+1], "booked_pnl") : "..."
                                                                        let market_action = getStrangleMarketAction(order);
                                                                        const roll_time_clock =(order.settings.roll_status==1 && order.settings.Roll_time_clock)? order.settings.Roll_time_clock.split(":"):"";
                                                                        let current_roll_index=last_roll_index+1+order.roll_index
                                                                        {row.map((order2, index2, arr) => {
                                                                            let booked_pnl2 = order2.direction === 'sell' ? getPrices(order2, "booked_pnl") : "..."
                                                                            if (index2 === 0){ 
                                                                                PNL = 0;
                                                                            }
                                                                            var pnl = parseFloat(order2.realized_pnl ? order2.realized_pnl  : booked_pnl2 !== '...' ? parseFloat(booked_pnl2).toFixed(4)  : 0)
                                                                            PNL = PNL + pnl
                                                                            // console.log(PNL , index ,pnl,booked_pnl2,order2.realized_pnl, '***********************************') 
                                                                        })}
                                                                        return (
                                                                            
                                                                            <tr style={{color:"#484F57"}} key={index}>
                                                                            {index === 0 && all_calls.length ==0?
                                                                                    <td  className={(indexs % 2 === 0) ? 'table-white p-2' : 'table-light p-2'} style={{ "verticalAlign": "top" }} rowSpan={row.length}>

                                                                                        <div className="border p-2">
                                                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                            <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-completed-" + order.strangle_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`completed-${order.strangle_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`completed-${order.strangle_id}`)}
                                                                                                    target={"Popovertop-completed-" + order.strangle_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`completed-${order.strangle_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index %: <b>{order.settings.index_percentage}</b> <br />
                                                                                                        {!order.admin_bot? <>Stop Loss Activate %{order.settings.combined_sl?" (Combined)":""}: <b>{order.settings.trigger_percentage}</b> <br /></>:''}
                                                                                                        Market Stop Loss %{order.settings.combined_sl?" (Combined)":""}: <b>{order.settings.stop_loss_percentage}</b> <br />
                                                                                                        Index Price Range % for Stop Loss: <b>{order.settings.stop_loss_index_percentage}</b> <br />
                                                                                                        Trailing Take Profit  %{order.settings.combined_trailing_profit?" (Combined)":""}: <b>{order.settings.min_take_profit_percentage ? order.settings.min_take_profit_percentage : 0}</b> <br />
                                                                                                        Trailing Stop Price %: <b>{order.settings.trailing_take_profit_percentage ? order.settings.trailing_take_profit_percentage : 0}</b> <br />
                                                                                                        Take Profit %{order.settings.combined_trailing_profit?" (Combined)":""}: <b>{order.settings.take_profit_percentage}</b> <br />
                                                                                                        Quantity: <b>{order.settings.quantity}</b> <br />
                                                                                                        Waiting time to fill Qty (H : M): <b>{order.settings.waitTimeForFillAllQty ? timeFormat(order.settings.waitTimeForFillAllQty) : '--'}</b> <br />
                                                                                                        {
                                                                                                            order.settings.roll_status  === 1 ?
                                                                                                            <>
                                                                                                        When to Roll? (H : M): <b>{(order.settings.roll_status==1 && order.settings.Roll_time_clock) ? (roll_time_clock[0]+ 'h ' +roll_time_clock[1] + 'm') : '--'}</b> <br />
                                                                                                        Distance from index (Roll): <b>{order.settings.index_distance}</b> <br />
                                                                                                        Premium Roll % (Roll): <b>{order.settings.roll_percentage}</b> <br />
                                                                                                            </>: '' 
                                                                                                        }
                                                                                                        Automatic market fill: <b>{order.settings.autoMarketFill === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Universal stop: <b>{order.settings.universal_stop === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Enable Delta Hedging : <b>{order.settings.deltahedging === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        {
                                                                                                            order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg? (
                                                                                                                <>
                                                                                                        Disable Delta Hedging on Stop Loss in One Leg : <b>{order.settings.Delta_Hedging_on_Stop_Loss_in_One_Leg === 1 ? 'true' : 'false'}</b><br/>
                                                                                                        </>) : '' 
                                                                                                    }
                                                                                                    {
                                                                                                            order.settings.combined_deactivated ? (
                                                                                                                <>Combined Deactivated: <b>true</b><br/></>
                                                                                                            ) : ''}
                                                                                                            {order.settings.reentry_enabled ? (
                                                                                                                <>Re-Entry Enabled: <b>true</b><br/>Waiting time for Re-Entry: <b>{order.settings.wait_time_reentry ?timeFormat(order.settings.wait_time_reentry) :"--" }</b></>
                                                                                                            ) : <>Re-Entry Enabled: <b>false</b></>}
                                                                                                            {order.settings.stopdate_reentry ? (
                                                                                                                <><br/>Stop Date Re-Entry: <b>{order.settings.stopdate_reentry}</b></>
                                                                                                            ) : ''}
                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                            <div className="text-primary" >Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>
                                                                                            {order.settings.current_index_price ? <div className="align-text-bottom created-date text-muted">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.settings.created_at)}</div>
                                                                                            <div className="text-muted font-size-12" style={{float:"left"}}>Total PNL : <text style={PNL > 0 ? {color:"green"} : {color:"red"}}>{PNL.toFixed(4)}</text> USDT</div><br></br>
                                                                                            <div >{(order.settings.automatic_bot && order.settings.automatic_bot == 1) ? <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}</div>
                                                                                            {order.settings.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}
                                                                                        
                                                                                        </div>
                                                                                    </td>
                                                                                    : ''}
                                                                                <td  className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>
                                                                                {all_puts[index+1] && all_puts[index+1].symbol.substring(0, 1)=="P" ? 
                                                                                    <Badge
                                                                                        color={all_puts[index+1].direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {all_puts[index+1].direction}
                                                                                    </Badge> : ''}<br></br>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td  className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>
                                                                                    {all_puts[index+1] && all_puts[index+1].symbol.substring(0, 1)=="P" ? <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+all_puts[index+1].symbol} target="_blank" rel="noreferrer" >
                                                                                    <Badge color={all_puts[index+1].direction === "buy" ? "success" : "danger"}>
                                                                                        {order.symbol} 
                                                                                    </Badge></a> : ''}<br></br>
                                                                                    <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                        {order.symbol} 
                                                                                    </Badge></a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-muted font-size-10 "  id={"trigger_calc"+order.order_id}>{market_action} </span>{order.index_price !== undefined ? <><br></br><span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                                                                            {order.order_type.toLowerCase()=="stop_loss" && order.settings.combined_sl && order.market_action_associateorder_lowest_ask ?     
                                                                                                <UncontrolledTooltip placement="bottom" target={"trigger_calc"+order.order_id}> 
                                                                                            { "("+order.market_action_associateorder_lowest_ask+" + "+order.market_action_lowest_ask+") > "+getPrices(order, "stop_loss")}
                                                                                            </UncontrolledTooltip>                                      :""}
                                                                                        </div>
                                                                                        : ''}
                                                                                </td>

                                                                                {/* className={highlighting && GetModifiOrderId == order.order_id && order.status.toLowerCase() === "open" ? 'element highlight':'element'} */}
                                                                                <td className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}>{all_puts[index+1] && all_puts[index+1].symbol.substring(0, 1)=="P" ? <h6 className={all_puts[index+1].direction === "buy" ? "text-success" : "text-danger"}>{(all_puts[index+1].average_fill_price && all_puts[index+1].direction !== "buy"  && all_puts[index+1].status=='closed') ? Number(all_puts[index+1].average_fill_price).toFixed(2) :Number(all_puts[index+1].price).toFixed(2) }</h6>:""}<h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.direction !== "buy"  && order.status=='closed') ? Number(order.average_fill_price).toFixed(2) :Number(order.price).toFixed(2) }</h6></td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white' : 'table-light'} style={{ "verticalAlign": "top" }}><div className="text-muted text-success font-size-10">
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate {order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "trigger")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'stop_loss' ? "text-danger" : 'text-muted'} ><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss {order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                                    <span className={order.order_type.toLowerCase() === 'take_profit' ? "text-success" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price{order.settings.combined_trailing_profit?" (Combined)":""}: {getPrices(order, "take_profit")}</span><br />
                                                                                    <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Min Take profit Price{order.settings.combined_trailing_profit?" (Combined)":""}: {getPrices(order, "min_take_profit")}</span>
                                                                                    {order.is_min_take_profit_reached?
                                                                                   <><br /> <span className={order.order_type.toLowerCase() === 'trailing_stop_loss' ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take profit Price: {parseFloat(order.trailing_stop_loss_price).toFixed(2)}</span></>
                                                                                    :''}


                                                                                </div>
                                                                                </td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}>{all_puts[index+1] && all_puts[index+1].symbol.substring(0, 1)=="P" ? <>{Number(all_puts[index+1].filled_quantity).toFixed(0)}/{Number(all_puts[index+1].settings.quantity).toFixed(0)}</>:""}<br></br>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}>{all_puts[index+1] && all_puts[index+1].symbol.substring(0, 1)=="P" ? <>{all_puts[index+1].order_id !== "" ? all_puts[index+1].order_id : "Nil"}</>:""}<br></br>{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}><span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order, "history") }}></span></td>
                                                                                <td className={(indexs % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'} style={{ "verticalAlign": "top" }}><small title={all_puts[index+1] && all_puts[index+1].realized_pnl ? all_puts[index+1].realized_pnl : booked_pnl !== '...' ? booked_pnl : ''} className={all_puts[index+1] && all_puts[index+1].realized_pnl ? (all_puts[index+1] && all_puts[index+1].realized_pnl > 0 ? "text-success" : "text-danger" ): (parseFloat(booked_pnl) > 0 ? "text-success" : "text-danger")}>{all_puts[index+1] && all_puts[index+1].realized_pnl ? all_puts[index+1].realized_pnl + ' USDT' : booked_pnl !== '...' ? parseFloat(booked_pnl).toFixed(4) + ' USDT' : '...'}</small></td>
                                                                            </tr>
                                                                               
                                                                            //     <td  className={(current_roll_index % 2 === 0) ? 'table-white' : 'table-light'}>
                                                                            //         <Badge
                                                                            //             color={order.direction === "buy" ? "success" : "danger"}
                                                                            //             style={{ "textTransform": "uppercase" }}
                                                                            //         >
                                                                            //             {order.direction}
                                                                            //         </Badge>
                                                                            //     </td>
                                                                            //     <td  className={(current_roll_index % 2 === 0) ? 'table-white' : 'table-light'}>
                                                                            //     <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                                            //         <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                            //             {order.symbol} 
                                                                            //         </Badge></a> 
                                                                            //         {market_action != '' ?
                                                                            //             <div>
                                                                            //                 <span className="text-muted font-size-10 "  id={"trigger_calc"+order.order_id}>{market_action} </span>{order.index_price !== undefined ? <><br></br><span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                                                            //                 {order.order_type.toLowerCase()=="stop_loss" && order.settings.combined_sl && order.market_action_associateorder_lowest_ask ?     
                                                                            //                     <UncontrolledTooltip placement="bottom" target={"trigger_calc"+order.order_id}> 
                                                                            //                 { "("+order.market_action_associateorder_lowest_ask+" + "+order.market_action_lowest_ask+") > "+getPrices(order, "stop_loss")}
                                                                            //                 </UncontrolledTooltip>                                      :""}
                                                                            //             </div>
                                                                            //             : ''}
                                                                            //     </td>

                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white' : 'table-light'}><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.direction !== "buy"  && order.status=='closed') ? Number(order.average_fill_price).toFixed(2) :Number(order.price).toFixed(2) }</h6></td>
                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white' : 'table-light'}><div className="text-muted text-success font-size-10">
                                                                            //         <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate: {getPrices(order, "trigger")}</span><br />
                                                                            //         <span className={order.order_type.toLowerCase() === 'stop_loss' ? "text-danger" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss {order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                            //         <span className={order.order_type.toLowerCase() === 'take_profit' ? "text-success" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price: {getPrices(order, "take_profit")}</span><br />
                                                                            //         <span className="text-muted"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Min Take profit Price{order.settings.combined_trailing_profit?" (Combined)":""}: {getPrices(order, "min_take_profit")}</span>
                                                                            //         {order.is_min_take_profit_reached?
                                                                            //        <><br /> <span className={order.order_type.toLowerCase() === 'trailing_stop_loss' ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take profit Price: {parseFloat(order.trailing_stop_loss_price).toFixed(2)}</span></>
                                                                            //         :''}

                                                                            //     </div>
                                                                            //     </td>
                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'}>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'}>{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'}><span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order, "history") }}></span></td>
                                                                            //     <td className={(current_roll_index % 2 === 0) ? 'table-white text-muted' : 'table-light text-muted'}><small title={order.realized_pnl ? order.realized_pnl : booked_pnl !== '...' ? booked_pnl : ''} className={order.realized_pnl ? (order.realized_pnl > 0 ? "text-success" : "text-danger" ): (parseFloat(booked_pnl) > 0 ? "text-success" : "text-danger")}>{order.realized_pnl ? order.realized_pnl + ' USDT' : booked_pnl !== '...' ? parseFloat(booked_pnl).toFixed(4) + ' USDT' : '...'}</small></td>


                                                                            // </tr>
                                                                        )
                                                                                                    }
                                                                    })} 



                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ "textAlign": "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*Listing orders */}
            <div className="page-content">

            </div>
            <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ dbid: "", show: false })}
                data={historyData}
                symbolname={modalShow.dbid}
            />
             <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
        setVisibleMidPrize(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MidPrizeMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
        </React.Fragment>
    );
}
export default ListOptions;
