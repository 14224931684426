import api from './api_helper';
import {getFirebaseBackend} from './firebase_helper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const userRegister = async(data) => {  
  return new Promise(async(resolve, reject) => {    
    var recapture = false ;
    await api({ contentType: true, auth: false }).post('/auth/register-recapture',data).then(res => {
      if (res.data.message == "validated successfully") {
        recapture = true
      }else{
        recapture = false
        reject(['', res.data.message])
      }
    }).catch(error => {
      reject(['', error.response.data ? error.response.data.message :"Something wrong for register"])
      throw error;
    })
    if (recapture == true){
    const fireBaseBackend = getFirebaseBackend()
    const response = await fireBaseBackend.registerUser(data.email , data.password)
    console.log(response)
    if (response.uid == undefined) {
      reject(['', response ])
    }else{
    if(response.uid) {
       var actionCodeSettings = {    
      url: process.env.REACT_APP_BASE_URL+"/login",
      handleCodeInApp: false
      };	
    await response.sendEmailVerification(actionCodeSettings);
    data['uid'] = response.uid
    await api({ contentType: true, auth: false }).post('/auth/register',data).then(res => {
      resolve([200, "Register successfully"])
    }).catch(error => {
      reject(['',error.response.data ? error.response.data.message :"Something wrong for register"])
      throw error;
    })
  }
  }
}
  })
}

// Login Method
const userLogin = async(data)=>{
  return new Promise(async(resolve, reject) => {
  await api({ contentType: true, auth: true }).post('/auth/login',data).then(res => {            
    localStorage.setItem("user", JSON.stringify(res.data.response));
    resolve([200, "Login successfully"])
  }).catch(async error => {
    if (error.response.data.message == "Check your email inbox and verify your email then login again") {
      const fireBaseBackend = getFirebaseBackend()
      const response = await fireBaseBackend.loginUser(data.email , data.password)
      if (response.uid){
        var actionCodeSettings = {    
       url: process.env.REACT_APP_BASE_URL+"/login",
       handleCodeInApp: false
       };	
     await response.sendEmailVerification(actionCodeSettings);
      reject(['', error.response.data.message])
      }else{
        localStorage.removeItem("user")
        reject(['', 'Something wrong for login'])
        throw error;
      }
    }else{
      localStorage.removeItem("user")
      reject(['', error.response.data.message])
      throw error;
    }
    // localStorage.removeItem("user")
    // reject(['', error.response.data ? error.response.data.message :"Something wrong for login"])
    // throw error;
  })

})
}

//Admin Session Login
const adminSessionLogin = async(data)=>{
  return new Promise(async(resolve, reject) => {
  await api({ contentType: true, auth: true }).post('/auth/admin-session-login',data).then(res => {
    localStorage.setItem("user", JSON.stringify(res.data.response));
    resolve([200, "Login successfully"])
  }).catch(error => {
    localStorage.removeItem("user")
    reject(['', error.response.data ? error.response.data.message :"Something wrong for login"])
    throw error;
  })

})
}

const forgotPassword = async(data)=>{
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: false }).post('/auth/password_reset',data).then(res => {      
      resolve([200, "Password reset successfully"])
    }).catch(error => {
      reject(['', error.response.data ? error.response.data.message :"Something wrong for login"])
      throw error;
    })  
  })
}

// Initiate websocket connection
const initiateWsConnect = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).get('/delta/initiate-ws-connect').then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}


// Initiate websocket connection
const updateAPIKey = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/delta/update-apikey-settings', data).then((res) => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

// update binance api key settings
const updateBinanceAPIKey = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/delta/update-binance-apikey-settings', data).then((res) => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}
const getServerIP= async() => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).get('/delta/get-server-ip').then((res) => {
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  userRegister,
  userLogin,  
  forgotPassword,
  initiateWsConnect,
  updateAPIKey,
  updateBinanceAPIKey,
  adminSessionLogin,
  getServerIP
}
