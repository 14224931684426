import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import api from '../../services/api.service';
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    this.get_stray_orders();
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
              const parent7 = parent6.parentElement;
              if (parent7) {
                parent7.classList.add("active"); // li 
              }
            }
          }
        }
      }
    }
    return false;
  };
  get_stray_orders = async()=>{
    firebase.auth();
    
    await api({ contentType: true, auth: true }).get('/delta/get-delta-positions').then((response) => {
      let res=response.data.response.data[0];
      if(typeof res !== 'undefined'){
      this.setState({stray_position_order : res.stray_positions});
      }else{
        this.setState({stray_position_order : ""});
      }
    }).catch((err) => {
      console.log(err);
  });
    // const get_balance = firebase.firestore().collection("users").doc(this.uid).collection("profile").doc("positions-info");
    // await get_balance.onSnapshot((doc) => {
    //     let item = doc.data();
    //     this.strayorders= item;
    //     this.setState({stray_position_order : this.strayorders.stray_positions});        
    // });    
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content" 
              >
                <ul className="navbar-nav">
                  <li>
                <Link to="dashboard" className="">
                  <i className="bx bx-home-circle" />
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to="strangles" className="">
                  <i className="mdi mdi-alpha-s-box" />
                  <span>{this.props.t("Strangles")}</span>
                </Link>
              </li>

              <li>
                <Link to="diagonals" className="">
                  <i className="mdi mdi-alpha-d-box" />
                  <span>{this.props.t("Diagonals")}</span>
                </Link>
              </li>

              <li>
                <Link to="straddles" className="">
                  <i className="bx bx-cube" />
                  <span>{this.props.t("Straddles")}</span>
                </Link>
              </li>
              <li>
                <Link to="trend-following" className="">
                  <i className="mdi mdi-alpha-t-box" />
                  <span>{this.props.t("Trend Following")}</span>
                </Link>
              </li>
              <li>
                <Link to="delta-hedging" className="">
                  <i className="mdi mdi-scale-balance" />
                  <span>{this.props.t("Delta Hedging")}</span>
                </Link>
              </li>
              <li>
                <Link to="transaction" className="">
                  <i className="bx bx-history" />
                  <span>{this.props.t("Transactions")}</span>
                </Link>
              </li>
              {this.state.stray_position_order && this.state.stray_position_order > 0 ?
              <li>
                <Link to="stray-positions" className="text-danger">
                  <i className="mdi mdi-alert-outline text-danger" />
                  <span>{this.props.t("Stray Positions")}
                  <span className="font-size-10"> ({this.state.stray_position_order})</span>
                  </span>
                </Link>
              </li> : ''}
              <li>
                <Link to="settings" className="">
                  <i className="bx bx-cog" />
                  <span>{this.props.t("Settings")}</span>
                </Link>
              </li>
              
              
                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/settings">
                      <i className="bx bx-cog me-2" />
                      {this.props.t("Settings")}
                    </Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/wallet">
                      <i className="bx bx-money me-2" />
                      {this.props.t("Wallet")}
                    </Link>
                </li> 

                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/orders/active">
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Active Orders")}
                    </Link>
                </li>


                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/orders/completed">
                      <i className="bx bx-history me-2" />
                      {this.props.t("Completed Orders")}
                    </Link>
                </li> */}

                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ ordersState: !this.state.ordersState });
                      }}>
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Orders")}
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.ordersState,
                      })}
                    >
                      <Link to="/ccxt/orders/active" className="dropdown-item">
                        {this.props.t("Active")}
                      </Link>
                      <Link to="/ccxt/orders/completed" className="dropdown-item">
                        {this.props.t("Completed")}
                      </Link>
                       </div>
                </li>                */}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
