import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {      
      if (!isAuthProtected && localStorage.getItem("user") && rest.path !== '/admin-session') 
      {        
        return (
          <Redirect
            to={{ pathname: "/ccxt/settings?pairs", state: { from: props.location } }}
          />
        )
      } 
      if (isAuthProtected && !localStorage.getItem("user")) {        
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      if (isAuthProtected && localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (!user.is_deltakey_updated) {
          if (rest.path === '/dashboard' || rest.path === '/strangles' || rest.path === '/diagonals' || rest.path === '/straddles' || rest.path === '/transaction' || rest.path === '/trend-following'  || rest.path === '/delta-hedging' || rest.path === '/settings' || rest.path === '/stray-positions' || rest.path === '/profile') {
            return (
              <Redirect
                to={{ pathname: "/delta-setup", state: { from: props.location } }}
              />
            )
          }          
        } else {
          if (rest.path === '/delta-setup' || rest.path === '/key-setup') {
            return (
              <Redirect
                to={{ pathname: "/dashboard", state: { from: props.location } }}
              />
            )
          }
        }       
      }

      // if (isAuthProtected && localStorage.getItem("user")) {
      //   let user = JSON.parse(localStorage.getItem("user"));
      //   if (user.is_deltakey_updated) {
      //     if (rest.path === '/delta-setup' || rest.path === '/key-setup') {
      //       return (
      //         <Redirect
      //           to={{ pathname: "/dashboard", state: { from: props.location } }}
      //         />
      //       )
      //     }
      //   }

      //   if (!user.is_deltakey_updated) {
      //     if (rest.path === '/strangles' || rest.path === '/diagonals' || rest.path === '/straddles' || rest.path === '/transaction') {
      //       return (
      //         <Redirect
      //           to={{ pathname: "/key-setup", state: { from: props.location } }}
      //         />
      //       )
      //     }
      //   }

      // }
          
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
