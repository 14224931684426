import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const StackedColumnChart = ({ dataColors, periodData , currency })  => {
    var b = periodData[0] ? periodData[0] : [];
    // currency = currency ? currency : [];
    currency = ['USDT'];
    var graphic_data = [];
    var act_graphic_data=[]
    var time_data = []
    var list = [];
    var count = 0;
    for (var j = 0; j < currency.length; j++){
      for(var i = 0; i < b.length ; i++){
        if (b[i].currency == currency[j]){
          if (b[i].pnl_data !== '0.00' && b[i].pnl_data !== '-0.00' && b[i].pnl_data != '0'){
            // console.log(b[i].pnl_data )
            graphic_data.push(b[i].pnl_data);
            act_graphic_data.push(b[i].pnl_data);
            // console.log(new Date(b[i].time),b[i].time)
            if (time_data.includes(b[i].time) == false){

              time_data.push(b[i].time);
            }
            // count = count + 1;
        }else{
          
        }
        
        if (b === undefined || b === null || b === '' || b.length == 0 ){
          list.push({name: 'PNL', data: []});
        }
      // if(count == 500){
      //   count = 0;
      //   break;
      // }
      }else{
      graphic_data.push(0);
      }
    }
 
    if(act_graphic_data.length > 0){
    list.push({name: currency[j], data: graphic_data});
    }
    graphic_data = [];
    act_graphic_data=[];
    }
    const a = list
    // const stackedColumnChartColors = getChartColorsArray(dataColors); 
    let data_length = time_data.length;
    const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "4%",
          colors: {
                ranges: [{
                from: -1000000000000,
                to: 0,
                color: "#f46a6a"
            }, {
                from: 0,
                to: 1000000000000,
                color: "#34c38f"
            }] 
          },
        }
    },
    title: {
      text: "",
      align: 'center',
      style: {
        fontWeight: '500',
      },
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      type: 'time',
      show: true,
      categories:time_data,
      labels: {
        show: true,
      },
      // title: {
      //   text: "Last 7 days P&L"
      // }

    }, 
    
    
  }

  return (
    <div style={{width:"100%", overflowX: "auto", overflowY: "hidden"}}>
    <div style={{ minWidth: data_length > 20 ?'1625px': data_length>15 ? '1375px' : data_length > 7 ? '885px' : '625px' }}> {/* Set a width that forces scrolling */}
        <ReactApexChart
          options={options}
          series={a}
          type="bar"
          height="359" 
          className="apex-charts"
          // height="00"
        />
         </div>
         </div>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart;

//colors: stackedColumnChartColors,
    // legend: {
    //   position: "bottom"
    // },
    // fill: {
    //   opacity: 1
    // }
    // plotOptions: {
    //   bar: {
    //     horizontal: !1,
    //     columnWidth: "35%"
    //     // endingShape: "rounded"
    //   }
    // },
       // zoom: {
      //   enabled: false,
      // },
      // {name: 'Series B',data:['-0.01', '-0.001', '-0.12', 0, '0.19', '0.02', '0.04', '0.06', '-0.0010']},
    // {name: 'Series C',data:[-11, 17, -15, 15, -21, 14, -11, 18, -17, 12, -20, -18]}
    