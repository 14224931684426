import React, { Component, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { changeLayout,changeLayoutMode } from "../../../store/actions";

import { withRouter, Link } from "react-router-dom";
import {layoutModeTypes} from '../../../constants/layout'
//i18n
import { withTranslation } from "react-i18next";
import api from "../../../services/api.service"
// users
import user1 from "../../../assets/images/users/user.png";

import { connect } from "react-redux";
import { logoutUser } from "../../../store/actions";
import moment from "moment";
import firebase from "firebase/compat/app";

const getUserName = () => {
  if (localStorage.getItem("user")) {
    const obj = JSON.parse(localStorage.getItem("user"))
    return obj;
  }
}
const userData = getUserName(); 

// async function UpdateProfileData(data){
//   let data2 = {theme_mode : data}
//   // document.getElementById("preloader").style.display = "block";
//   //     document.getElementById("status").style.display = "block";

//   // setTimeout(function () {
//   //       document.getElementById("preloader").style.display = "none";
//   //       document.getElementById("status").style.display = "none";
//   // }, 1000);
//   let user = JSON.parse(localStorage.getItem("user"));
//   user.profile.theme_mode = data2.theme_mode;
//   localStorage.setItem("user", JSON.stringify(user));   
//   firebase.auth();
//   const firestoreDb = firebase.firestore(); 
//   let updateData = data2
//   updateData.updated_at = moment().toISOString();
//   // const firestoreDb1 = firestoreDb.collection("users").doc(userData.uid);  
// 	// const result = firestoreDb1.collection("profile").doc('data').update(updateData);

//   let send_update_data = {
//     uid: user.uid,
//     data: updateData
// }
//   const result =  await api({ contentType: true, auth: true }).post('/user/update-user',send_update_data)
// }
class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "",
      layoutModeType: this.props.layoutModeType,
    }
    this.changeLayoutMode = this.changeLayoutMode.bind(this);
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
       }))
  }
  changeLayoutMode(e) {
    if (e.target.checked) {
      this.props.changeLayoutMode(e.target.value);
    }
  }
  layoutModeType(e) { 
    if (e.target.checked) {
      this.props.layoutModeType(e.target.value);
    }
  }
  componentDidMount() {
    const userData = getUserName();   
    if (userData) {
      this.setState({ name: userData && userData.profile? userData.profile.first_name:'' ,layoutModeType:this.state.layoutModeType })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {        
        this.setState({ name: userData && userData.profile? userData.profile.first_name:'' })
      }
    }
    if (prevProps !== this.props) {
      this.setState({ 
        layoutModeType:this.state.layoutModeType ,
      })
    }
  }
  render() {
    const { location } = this.props; // Get location from props
 
    const currentPath = location.pathname;
 
    const shouldHide = currentPath.includes('/ccxt');
    const profileLink = currentPath.includes('/ccxt') ? '/ccxt/profile' : '/profile';
 

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          inNavbar={this.state.layoutModeType}
          className="d-inline-block profilehead"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1 text-capitalize">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end text-start">
            <Link style={{color:'#212529'}} to={profileLink}>
            <DropdownItem>
             <i className="bx bx-user font-size-16 align-middle text-start" /> {this.props.t("Profile")}
            </DropdownItem>
            </Link>            
            {!shouldHide && (        
            <Link style={{color:'#212529'}} to="/settings">
            <DropdownItem>              
            <i className="bx bx-wrench font-size-17 align-middle text-start " />{" "}
                          {this.props.t("Settings")}
            </DropdownItem>
            </Link>
            )}
            {/* <DropdownItem >    
              { this.props.layoutModeType === layoutModeTypes.DARK ? (
                <>
                    <label className="mb-0 w-100"  htmlFor="radio" role="button" style={{fontWeight:'normal' }}><i className="bx bx-sun text-start font-size-16 align-middle " />{" "} Light Mode</label>
                    <input
                    type="radio"
                    className="invisible"
                    id="radio"
                    name="radioLight"
                    value={layoutModeTypes.LIGHT}
                    onClick={()=> {UpdateProfileData(layoutModeTypes.LIGHT)}}
                    checked={this.props.layoutModeType === layoutModeTypes.LIGHT}
                    onChange={this.changeLayoutMode}
                  /> 
                </>
              ) : (
                <>
                  <label className="mb-0 w-100" style={{fontWeight:'normal'}} htmlFor="radio" role="button"><i className="bx bx-moon text-start font-size-16 align-middle " />{" "} Dark Mode</label>
                  <input
                    type="radio"
                    className="invisible"
                    id="radio"
                    name="radioDark"
                    value={layoutModeTypes.DARK}
                    onClick={()=> {UpdateProfileData(layoutModeTypes.DARK)}}
                    checked={this.props.layoutModeType === layoutModeTypes.DARK}
                    onChange={this.changeLayoutMode}
                  />
              </>
                  )
              }
            </DropdownItem> */}
            {/* <DropdownItem tag="a" href="#">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {this.props.t("Lock screen")}
            </DropdownItem> */}
            <div className="dropdown-divider" />
            <Link to="" onClick={()=>{this.props.logoutUser(this.props.history)}}  className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span style={{'marginLeft':'10px'}}>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  layoutModeType : PropTypes.any,
  changeLayoutMode : PropTypes.func,
  success: PropTypes.string
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success,...state.Layout }
}

export default withRouter(
  connect(mapStateToProps, {logoutUser,changeLayoutMode})(withTranslation()(ProfileMenu))
)
