import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { logoutUser } from "../../store/actions"
import { Col, Container, FormFeedback, Input, Label, Modal, Row,Nav,NavItem,NavLink,TabContent,TabPane,CardText } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateAPIKey,updateBinanceAPIKey,getServerIP } from "helpers/auth_helper";
import { toast } from "react-toastify";
import classnames from "classnames";
import deltalogo from "../../assets/images/delta.png"
import binancelogo from "../../assets/images/binance.png"
const DeltaSetup = () => {
  const history = useHistory()
  const dispatch = useDispatch();  
  //const translate = useTranslation()
  const [isOpen, setisOpen] = useState(true);
  const [serverIP, setServerIP] = useState("");
  const [formLoader, setformLoader] = useState(false);
  const [binanceFormLoader, setBinanceFormLoader] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  getServerIP().then((res) => {
    if(res && res.data && res.data.response && res.data.response.server_details && res.data.response.server_details.ip_address)
    {
      if(res.data.response.server_details.ip_address !== '')
      {
        setServerIP(res.data.response.server_details.ip_address)
      }
    }
  }).catch(err => {
    console.log("err",err)
  })
  const validateDeltaSetupForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      api_key: '',
      api_secret: '',
    },
    validationSchema: Yup.object().shape({
      api_key: Yup.string().required(
        "Delta API key is required"
      ),
      api_secret: Yup.string().required(
        "Delta API secret is required"
      )
    }),
    onSubmit: async (values) => {
      try
      {
        setformLoader(true);        
        const bodyParams = {          
            appKey : values.api_key,
            appSecret : values.api_secret
        }      
      
        await updateAPIKey(bodyParams).then((res) => {          
          setformLoader(false);                  
          let user = JSON.parse(localStorage.getItem("user"));
          user.is_deltakey_updated = true;
          localStorage.setItem("user", JSON.stringify(user));            
          toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
          history.push("/dashboard");
        }).catch(err => {          
          throw err
        })    

    }
    catch(err)
    {      
        setformLoader(false); 
        if (err && err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (err.message) {
          toast.error(err.message);
        } else {
          toast.error(err);
        }            
    }
    }
  });


  const binance = useFormik({
    initialValues: {
      api_key: '',
      api_secret: '',
    },
    validationSchema: Yup.object().shape({
      api_key: Yup.string().required(
        "Binance API key is required"
      ),
      api_secret: Yup.string().required(
        "Binance API secret is required"
      )
    }),
    onSubmit: async(values) => {
        try
        {
          setBinanceFormLoader(true);
          const bodyParams = {          
              appKey : values.api_key,
              appSecret : values.api_secret
          }      
        
          await updateBinanceAPIKey(bodyParams).then((res) => {
            setBinanceFormLoader(false);                  
            let user = JSON.parse(localStorage.getItem("user"));
            user.is_deltakey_updated = true;
            localStorage.setItem("user", JSON.stringify(user));            
            toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
            history.push("/dashboard");
          }).catch(err => {          
            throw err
          })    

      }
      catch(err)
      {      
          setBinanceFormLoader(false); 
          if (err && err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.message) {
            toast.error(err.message);
          } else {
            toast.error(err);
          }            
      }
    }
  })

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const goDashboard = ()=>{
    history.push("/ccxt/settings");
    let user = JSON.parse(localStorage.getItem("user"));
    toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
  }
  return (
    <>
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>              
              <div>                  
                  <Modal size="lg" isOpen={true} >
                    <span className="float-end" ><Link to={'/'} className="btn btn-close danger mt-2 mx-2 font-size-13 float-end" ></Link></span>
                  <div className="modal-header pt-1">
                      <h5 className="modal-title" id="exampleModalLabel">Delta API Credentials</h5>
                      <span className="float-end">
                        <button onClick={goDashboard} className="btn btn-success"> Switch to Spot <i className="mdi mdi-arrow-right ms-1"></i></button>
                      </span>
                    </div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        DeltaEx {""}
                        <img src={deltalogo} alt="" width="20" />
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Binance {""}
                        <img src={binancelogo} alt="" width="20" />
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <form onSubmit={(e) => {
                      e.preventDefault();
                      validateDeltaSetupForm.handleSubmit();
                      return false;
                    }}>
                    <div className="modal-body">  
                    <div className="alert alert-info mb-3">Make sure you have whitelisted our IP address <b>{serverIP}</b> in your Delta Exchange API key settings </div>              
                      <div className="mb-3">
                        <Label className="form-label">Delta API key</Label>
                        <Input
                          name="api_key"                          
                          type="text"
                          onChange={validateDeltaSetupForm.handleChange}
                          onBlur={validateDeltaSetupForm.handleBlur}
                          value={validateDeltaSetupForm.values.api_key || ""}
                          invalid={
                            validateDeltaSetupForm.touched.api_key && validateDeltaSetupForm.errors.api_key ? true : false
                          }
                        />
                        {validateDeltaSetupForm.touched.api_key && validateDeltaSetupForm.errors.api_key ? (
                          <FormFeedback type="invalid">{validateDeltaSetupForm.errors.api_key}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                        <Label className="form-label">Delta API Secret</Label>
                        <Input
                          name="api_secret"                          
                          type="text"
                          onChange={validateDeltaSetupForm.handleChange}
                          onBlur={validateDeltaSetupForm.handleBlur}
                          value={validateDeltaSetupForm.values.api_secret || ""}
                          invalid={
                            validateDeltaSetupForm.touched.api_secret && validateDeltaSetupForm.errors.api_secret ? true : false
                          }
                        />
                        {validateDeltaSetupForm.touched.api_secret && validateDeltaSetupForm.errors.api_secret ? (
                          <FormFeedback type="invalid">{validateDeltaSetupForm.errors.api_secret}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal"><Link to="" onClick={()=>{dispatch(logoutUser(history))}}  className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                      <span>Logout</span>
                    </Link></button>
                        <button type="submit" className="btn btn-primary">{formLoader && <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> }Submit</button>
                    </div>
                    </form>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <form onSubmit={(e) => {
                                  e.preventDefault();
                                  binance.handleSubmit();
                                  return false;
                                }}>
                                <div className="modal-body">                          
                                  <div className="mb-3">
                                    <Label className="form-label">Binance API key</Label>
                                    <Input
                                      name="api_key"                          
                                      type="text"
                                      onChange={binance.handleChange}
                                      onBlur={binance.handleBlur}
                                      value={binance.values.api_key || ""}
                                      invalid={
                                        binance.touched.api_key && binance.errors.api_key ? true : false
                                      }
                                    />
                                    {binance.touched.api_key && binance.errors.api_key ? (
                                      <FormFeedback type="invalid">{binance.errors.api_key}</FormFeedback>
                                    ) : null}
                                    </div>
                                    <div className="mb-3">
                                    <Label className="form-label">Binance API Secret</Label>
                                    <Input
                                      name="api_secret"                          
                                      type="text"
                                      onChange={binance.handleChange}
                                      onBlur={binance.handleBlur}
                                      value={binance.values.api_secret || ""}
                                      invalid={
                                        binance.touched.api_secret && binance.errors.api_secret ? true : false
                                      }
                                    />
                                    {binance.touched.api_secret && binance.errors.api_secret ? (
                                      <FormFeedback type="invalid">{binance.errors.api_secret}</FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-dark" data-bs-dismiss="modal"><Link to="" onClick={()=>{dispatch(logoutUser(history))}}  className="dropdown-item">
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                  <span>Logout</span>
                                </Link></button>
                                    <button type="submit" className="btn btn-primary">{binanceFormLoader && <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> }Submit</button>
                                </div>
                            </form>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane> */}
                    </TabContent>
                  </Modal>
              </div>                
            </Col>
          </Row>         
        </Container>
      </div>
      </React.Fragment>
      
    </>
  )
}

export default DeltaSetup;
