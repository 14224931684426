import React, { useCallback, useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, Table,  Badge,
    Popover,
    PopoverHeader,
    PopoverBody,Tooltip
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";

import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import api from '../../../services/api.service';
import Swal from 'sweetalert2';
import {  getContractValue, formatUTCDateTime, getCCXTOrderStatusStyle,getTrendmethodStyle,getExchangeName,getCCXTErrorText,getCCXTPrices,CustomParseFloat } from "../../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import HistoryModal from "../../../components/Modal/HistoryModal";

import "react-rangeslider/lib/index.css"
import { Link } from "react-router-dom";
const ListOptions = (props) => {
document.title = "Spot Trade - Active Orders";
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const contractValue = getContractValue('BTCUSDT');
    const firestoreDb = firebase.firestore();
    const firestoreDbUser = firestoreDb.collection("users").doc(uid);
    const ordersRef = firestoreDbUser.collection("ccxt_orders").orderBy("created_at", "desc");  
    const [orderData, setOrderData] = useState([]);
    const [stopOrderFlag, setStopOrderFlag] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ttop, setttop] = useState(false);
    const [popovertop, setpopovertop] = useState({});
    const [totalUSDT, setTotalUSDT] = useState(0);
    
    //for settings -end
    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        }else if(err.data.message){
            toast.error(err.data.message);
        }else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    const popovertopchange = targetName => {
        // Reset all other popovers to false before toggling the selected one
        setpopovertop(prevState => ({
          ...Object.keys(prevState).reduce((acc, key) => {
            acc[key] = { popover: false };
            return acc;
          }, {}),
          [targetName]: {
            popover: !prevState[targetName]?.popover // Toggle the selected popover
          }
        }));
      };
    
      const ispopoverOpen = targetName => {
        console.log(popovertop[targetName]?.popover, 'popovertop[targetName]?.popover')
        return popovertop[targetName]?.popover || false;
      };
    
      // Close popover on outside click
      const wrapperRef = useRef(null);
      useEffect(() => {
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setpopovertop(prevState => {
              // Close all popovers when clicking outside
              return Object.keys(prevState).reduce((acc, key) => {
                acc[key] = { popover: false };
                return acc;
              }, {});
            });
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);

      const timeFormat = (seconds) => {
        var totalNumberOfSeconds = seconds;
       var hours = parseInt( totalNumberOfSeconds / 3600 );
       var minutes = parseInt( (totalNumberOfSeconds - (hours * 3600)) / 60 );
       var seconds = Math.floor((totalNumberOfSeconds - ((hours * 3600) + (minutes * 60))));
       if(seconds >0){
       var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m " + (seconds  < 10 ? "0" + seconds : seconds)+ "s";
       }else{
        var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m";
       }
       return result;
      }
    
    const getOrders = useCallback(() => {

        ordersRef.onSnapshot((querySnapshot) => {

            const items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                 item.id = doc.id;
                           items.push(item)
            });

            setOrderData(items);
        });
    
      }, [ordersRef, contractValue]);
 
    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        if(orderData.length) {
            getTotalPNL();
        }
    }, [orderData]);

    const getTotalPNL = async () => {
        try {
        const bodyParams ={data:orderData};
        await api({ contentType: true, auth: true }).post('/ccxt/getTotalPNL', bodyParams).then(async(res) => {
            setTotalUSDT(res.data.response.totalUSDT)
        });  
        } catch (e) {
          handleError(e);
        }
      }

    const stopOrder = async (uid,order,stop_type="") => {
        let alerttext=stop_type==""?"Do you want to remove the order?":"Do you want to market close the order?"
        Swal.fire({
            title: 'Are you sure?',
            text: alerttext,
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if(!stopOrderFlag[order.exchange_id]){
                    stopOrderFlag[order.exchange_id]=[];
                }
                stopOrderFlag[order.exchange_id][order.id] = 1;
                setStopOrderFlag(stopOrderFlag);
                console.log("order",order)
                const params = {
                    uid: uid,
                    Order_id: order.Order_id,
                    symbol: order.symbol,
                    exchange_id:order.exchange_id,
                    stop_type:stop_type,
                    id:order.id
                  };
                await api({ contentType: true, auth: true }).post('/ccxt/stop-order', params).then((res) => {
                    if (res.data !== "") {
                        handleError(res);
                        //setStopped(res.data.message);
                        stopOrderFlag[order.exchange_id][order.id] = 0;

                        setStopOrderFlag(stopOrderFlag);
                    }
                });
            }
        });
    }
    

return (
    <React.Fragment>
    <div className="page-content">
            
        <Container fluid>
            <Breadcrumbs title="DeriSnap" breadcrumbItem="Active Orders" />
            <Row>
                <Col lg="12">
                    <Card className="box_4">
                        <CardBody>
                                <div className="d-md-flex justify-content-md-between">
                                <h4 className="card-title mb-3">Orders</h4>
                                { orderData.length ? <p className="mb-0 fs-5">
                                Total PNL :&nbsp; 
                                <b className={Number(totalUSDT) >0? "text-success" :Number(totalUSDT)<0? "text-danger":""}>{Number(totalUSDT).toFixed(4)}&nbsp;USDT</b>
                                </p> : null
                                }
                                </div>
                            <div className="table-responsive">
                                {/*********/}
                                <Table className={"table table-bordered table align-middle table-nowrap table-check " }>
                                    <thead >
                                        <tr>
                                            <th>Exchange</th>
                                            <th>Side</th>
                                            <th>Symbol</th>
                                            <th>Sell/Buy Price</th>
                                            {/* <th title="Stop Loss / Trailing Take Profit Price">SL / TSL price</th> */}
                                            <th>Qty</th>
                                            <th className="text-decoration-underline" >
                                            <Tooltip
                                                placement="top"
                                                isOpen={ttop}
                                                target="TooltipTop"
                                                toggle={() => {
                                                    setttop(!ttop);
                                                }}
                                            >
                                                Sell order: Price - Lowest ask <br /> Buy order: Highest bid - Price
                                            </Tooltip>
                                            <span id="TooltipTop">UPNL</span>
                                                </th>
                                            <th>Status</th>
                                            <th>Order ID</th>
                                            <th  className="text-center" colSpan={2}>Action</th>
                                        </tr>
                                    </thead>
                                    {orderData.length > 0 ? (
                                        orderData.map((order, index) => {
                                        let market_action =order.market_close==1 ?"Triggered Market close": order.order_trigger_type && order.order_trigger_type=="stop_loss" && !order.is_min_take_profit_reached?"Triggered Stop loss":order.order_trigger_type && order.order_trigger_type=="stop_loss" && order.is_min_take_profit_reached?"Triggered Trailing profit":""
                                        let indexs = (index+1)%2
                                        let trend_method=order.settings.trend_method?getTrendmethodStyle(order.settings.trend_method):''
                                        let popoverId = `open-${order.id}`;
                                        return (
                                                <tbody key={index}>
                                                                <tr style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')} key={index}>
                                                                
                                                                    <td className=" p-2 ">
                                                                        <div className="border p-2">
                                                                        
                                                                        <b className="text-primary font-size-15">{getExchangeName(order.exchange_id)}</b>
                                                                        {/* <div className="text-muted font-size-12">Unrealized PNL : <span title={order.unrealized_pnl ? order.unrealized_pnl : ''}  className={Number(order.unrealized_pnl) >0? "text-success font-size-15" : "text-danger"}><small>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl,3) + ' USDT'+' ('+CustomParseFloat(order.unrealized_pnl_per,3)+'%)' : '...'}</small></span></div> */}
                                                                        <div ref={wrapperRef} style={{ float: "right" }}>
                                                                            <a
                                                                                id={"Popovertop-open-" + order.id}
                                                                                color="secondary"
                                                                                onClick={() => {
                                                                                popovertopchange(popoverId);
                                                                                }}
                                                                            >
                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                            </a>
                                                                            <Popover
                                                                                placement="right"
                                                                                isOpen={ispopoverOpen(popoverId)}
                                                                                target={"Popovertop-open-" + order.id}
                                                                                toggle={() => {
                                                                                popovertopchange(popoverId);
                                                                                }}
                                                                                style={{ border: "none" }}
                                                                            >
                                                                            <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}>
                                                                                <b>Info</b> 
                                                                                <button onClick={() => document.body.click()} style={{ float:'right',border:'none', 
                                                                                background:'transparent',cursor:'pointer',fontSize:'20px',padding:'0px',marginTop:'-4px',
                                                                                color:'white', fontWeight: '600' }}> &times; </button>
                                                                            </PopoverHeader>
                                                                                <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                    Fill type: <b>{order.settings.fill_type.length !== 0 ? <>{order.settings.fill_type == 'instant_fill' ? 'Instant Fill' : 'Wait and Fill' }</> : '--'}</b>  <br />
                                                                                    {order.settings.fill_type !="instant_fill" && order.settings.price_distance_percentage && order.settings.price_distance_percentage.length !== 0 ? <> Price Distance % for Wait and Fill: <b>{order.settings.fill_type == 'instant_fill' ? '--' : order.settings.price_distance_percentage }</b><br /></> : ''} 
                                                                                    Market Stop Loss: <b>{order.settings.stop_loss && order.settings.stop_loss.length !== 0 ? <>{order.settings.stop_loss}{order.settings.stop_loss_type == "points" ? 'Pts' : "%"}</>: '--' }</b><br />
                                                                                    Minimum Take Profit%:  <b>{(order.settings.min_take_profit_percentage?order.settings.min_take_profit_percentage:'--')}</b><br />
                                                                                    Trailing Take Profit%:  <b>{(order.settings.trailing_take_profit_percentage?order.settings.trailing_take_profit_percentage:'--')}</b><br />
                                                                                    Trend signal method: <b>{order.settings.trend_method.length !== 0 ? order.settings.trend_method : '--'}</b>
                                                                                    <br />
                                                                                    {order.settings.trend_method=="sma"?
                                                                                    <>
                                                                                    Time frame: <b>{order.settings.time_frame? order.settings.time_frame : '--'}</b><br />
                                                                                    Short length: <b>{order.settings.short_term ? order.settings.short_term : '--'}</b><br />
                                                                                    Long length: <b>{order.settings.long_term ? order.settings.long_term : '--'}</b><br />
                                                                                    </>:order.settings.trend_method=="ema"?
                                                                                    <>
                                                                                    Time frame: <b>{order.settings.ema_time_frame ? order.settings.ema_time_frame : '--'}</b><br />
                                                                                    Short length: <b>{order.settings.ema_short_term ? order.settings.ema_short_term : '--'}</b><br />
                                                                                    Long length: <b>{order.settings.ema_long_term? order.settings.ema_long_term : '--'}</b><br />
                                                                                    </>:order.settings.trend_method=="macd"? 
                                                                                    <>
                                                                                        Time frame: <b>{order.settings.macd_time_frame?order.settings.macd_time_frame:"15m"}</b><br />
                                                                                    Short length: <b>{order.settings.macd_short_term ? order.settings.macd_short_term : '--'}</b><br />
                                                                                    Long length: <b>{order.settings.macd_long_term ? order.settings.macd_long_term : '--'}</b><br />
                                                                                    Signal length: <b>{order.settings.macd_signal_term ? order.settings.macd_signal_term : '--'}</b><br />
                                                                                    </>: order.settings.trend_method=="supertrend"?
                                                                                    <>
                                                                                    Time frame: <b>{order.settings.st_time_frame?order.settings.st_time_frame:"15m"}</b><br />
                                                                                    ATR length: <b>{order.settings.st_atr_length ? order.settings.st_atr_length : '--'}</b><br />
                                                                                    Factor: <b>{order.settings.st_factor ? order.settings.st_factor : '--'}</b>
                                                                                    <br />
                                                                                    Minimum supertrend distance:  <b>{order.settings.st_min_distance ? order.settings.st_min_distance : '--'}</b><br />
                                                                                    Maximum supertrend distance:  <b>{order.settings.st_max_distance ? order.settings.st_max_distance : '--'}</b> <br />
                                                                                    </>
                                                                                    :<>
                                                                                    Time frame: <b>15m</b><br />
                                                                                    Short length: <b>10</b><br />
                                                                                    Long length: <b>100</b><br />
                                                                                    </>
                                                                                    
                                                                                    }
                                                                                    Order Side : <b>{order.settings.order_side && order.settings.order_side?order.settings.order_side:"both"}</b> <br />
                                                                                {order.settings.order_side =="sell" && order.settings.min_sell_price?<>Minimum Sell Price: <b>{order.settings.min_sell_price}</b><br /></>:''}
                                                                                {order.settings.order_side =="buy" &&  order.settings.max_buy_price?<>Maximum Buy Price: <b>{order.settings.max_buy_price}</b><br /></>:''}
                                                                                    Waiting Time for Order after Stop Loss: <b>{order.settings.waiting_time_for_next_order_stop_loss && order.settings.waiting_time_for_next_order_stop_loss !== '' ? timeFormat(order.settings.waiting_time_for_next_order_stop_loss) : '--'}</b><br />
                                                                                    Waiting Time for Order after Trailing Take Profit: <b>{order.settings.waiting_time_for_next_order_trailing_profit && order.settings.waiting_time_for_next_order_trailing_profit !== '' ? timeFormat(order.settings.waiting_time_for_next_order_trailing_profit) : '--'}</b><br />
                                                                                    {order.settings.settings_quantity?
                                                                                    <>Quantity: <b>
                                                                                    {Number(order.settings.settings_quantity)}{order.settings.quantity_type === 'balance_per' ? ' % (quote margin)' 
                                                                                    : order.settings.quantity_type === 'balance_per_base' ? ' % (base margin)' : ''}
                                                                                    </b></>:''}<br />
                                                                                </PopoverBody>
                                                                            </Popover>
                                                                            </div>
                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}</div>
                                                                            {order.settings.signal ? <div className="align-text-bottom created-date"><span title={trend_method.fullform}>{trend_method.name}</span> Signal:{order.settings.signal == 'uptrend' ? <><span className="text-success font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-up"></i></span>Uptrend</> : <><span className="text-danger font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-down"></i></span>Downtrend</>}</div> : ''}
                                                                        </div>
                                                                    </td>
                                                                    
                                                                <td>
                                                                    <Badge
                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                        style={{ "textTransform": "uppercase" }}
                                                                    >
                                                                        {order.direction}
                                                                    </Badge>
                                                                </td>
                                                                <td>
                                                                
                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                    {order.settings.trading_pair}
                                                                    </Badge>
                                                                    {order.created_by_manual && order.created_by_manual== 1 ?<div><span className="text-primary  font-size-11 ">Manually created</span></div>:''}
                                                                    {market_action != '' ?
                                                                        <div>
                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                        </div>
                                                                        : ''}
                                                                        {order.error_constructor_name != '' ?
                                                                        <div>
                                                                            <span className="text-danger font-size-11 " title={order.error_message!=""?order.error_message:order.error_constructor_name}> {getCCXTErrorText(order.error_constructor_name)} </span>
                                                                        </div>
                                                                        : order.error_message!=""?
                                                                        <div>
                                                                        <span className="text-danger font-size-11 "> {order.error_message} </span>
                                                                            </div>
                                                                        : ''}
                                                                </td>

                                                                <td>
                                                                    <h6 className={order.direction === "buy" ? "text-success" : "text-danger"} title={"("+order.price+" x "+order.settings.quantity+" = "+(order.price*order.settings.quantity).toFixed(2)+ ")"} >{Number((order.price*order.settings.quantity)).toFixed(5)+' '+order.settings.quote}{<div><small title={order.price} className="text-muted">({Number(order.price).toFixed(5)+' '+order.settings.quote})</small></div>}</h6>
                                                                    {order.highest_bid || order.lowest_ask ?
                                                                        <div>
                                                                            <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid ? Number(order.highest_bid).toFixed(4) : '...'}</span></span>
                                                                            <span className="text-muted">, </span>
                                                                            <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask ? Number(order.lowest_ask).toFixed(4) : '...'}</span></span>
                                                                        </div>
                                                                        : ""
                                                                    }
                                                                </td>
                                                                {/* <td> {order.trailing_stop_loss_price != '' ? <span className="text-secondary font-size-10 ">Trailing Take profit Price: {order.trailing_stop_loss_price} </span> : <span className="text-secondary font-size-10 ">Stop Loss Price: {getCCXTPrices(order,"stop_loss")} </span>}</td> */}
                                                                <td>{Number(order.settings.quantity)}</td>
                                                                <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}  className={Number(order.unrealized_pnl) >0? "text-success" : "text-danger"}><small>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl,3) + ' '+ (order.settings.quote || 'USDT') +' ('+CustomParseFloat(order.unrealized_pnl_per,3)+'%)' : '...'}</small></td>
                                                                <td> <span dangerouslySetInnerHTML={{ __html: getCCXTOrderStatusStyle(order) }}></span>
                                                                {order.status == 'closed' && order.order_trigger_type && order.order_trigger_type=="base" ?
                                                                        <div>
                                                                            <span className="text-warning font-size-10 ">Waiting for {order.direction === "buy" ? "Sell" : "Buy"}/SL </span><br/>
                                                                            {order.is_min_take_profit_reached && order.trailing_stop_loss_price != '' ? <span className="text-secondary font-size-10 ">Trailing Take profit Price: {order.trailing_stop_loss_price} </span> : <span className="text-secondary font-size-10 ">Stop Loss Price: {getCCXTPrices(order,"stop_loss")} </span>}
                                                                            
                                                                        </div>
                                                                        :order.status == 'closed' && order.order_trigger_type && order.order_trigger_type=="stop_loss" && order.market_close!=1 && order.waiting_for_next_order_trigger?<div><span className="text-warning font-size-10 ">Waiting for Next Order After {order && order.order_trigger_type == 'stop_loss' && order.is_min_take_profit_reached  ? 'Trailing Take Profit' : 'Stop Loss'   }</span></div> :  ''}
                                                                </td>
                                                                <td className="font-size-11">{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                <td className="text-center">
                                                                <div>
                                                                        {/**button for market close */}
                                                                        {order.status == 'closed' && order.order_trigger_type && order.order_trigger_type=="base" ?
                                                                        <>
                                                                            {(stopOrderFlag[order.exchange_id] && stopOrderFlag[order.exchange_id][order.symbol] && stopOrderFlag[order.exchange_id][order.symbol] == 1) || order.market_close==1 ?
                                                                            <a className="btn btn-outline-danger btn-sm" style={{ "marginLeft": "10px" }} >
                                                                                <div className="spinner-border text-danger  spinner-border-sm" role="status">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                            </a>:
                                                                            <a className="btn btn-outline-danger btn-sm" onClick={() =>
                                                                                stopOrder(order.profile.uid,order,"market_close")
                                                                            }>
                                                                                Market close
                                                                            </a>}
                                                                            </>  : 
                                                                        <>
                                                                        {(stopOrderFlag[order.exchange_id] && stopOrderFlag[order.exchange_id][order.symbol] && stopOrderFlag[order.exchange_id][order.symbol] == 1) ?
                                                                            <a className="btn btn-outline-danger btn-sm" style={{ "marginLeft": "10px" }} >
                                                                                <div className="spinner-border text-danger  spinner-border-sm" role="status">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                            </a>
                                                                            : <a onClick={() =>
                                                                                stopOrder(order.profile.uid,order)
                                                                            }
                                                                                className="text-danger" 
                                                                            >
                                                                                <i className="mdi mdi-delete font-size-18"></i>
                                                                            </a>
                                                                        }</>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                {/*console.log(stopOrderFlag[order.Order_id])  || (order.is_stop_triggered==1)*/}
                                                                
                                                            </tr>
                                                        
                                                
                                                </tbody>
                                            )
                                        })
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                    No orders found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                                {/*********/}
                            </div>
                                    
                                
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
            {/*Listing orders */}
            <div className="page-content">

            </div>
            <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ symbol: "", show: false })}
                data={historyData}
                symbolname={modalShow.symbol}
            />
    </React.Fragment>
);
}
export default ListOptions;
