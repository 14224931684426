import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const StackedColumnChart = ({ dataColors, periodData }) => {
    var b = periodData[0] ? periodData[0] : [];
    var graphic_data = [];
    var time_data = []
    var count = 0;
    for(var i = 0; i < b.length ; i++){
        if (b[i].pnl_data !== '0.00' && b[i].pnl_data !== '-0.00' && b[i].pnl_data != '0'){
          // console.log(b[i].pnl_data )
          graphic_data.push(b[i].pnl_data);
          // console.log(new Date(b[i].time),b[i].time)
          time_data.push(b[i].time);
          // count = count + 1;
      }
    // if(count == 500){
    //   count = 0;
    //   break;
    // }
    }
    const a = [
    {name: 'PNL',data:graphic_data},
    ]
    const stackedColumnChartColors = getChartColorsArray(dataColors);  
    const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "4%",
          colors: {
                ranges: [{
                from: -100000000,
                to: 0,
                color: "#f46a6a"
            }, {
                from: 0,
                to: 1000000000,
                color: "#34c38f"
            }] 
          },
        }
    },
    title: {
      text: "Last 7 days P&L",
      align: 'center',
      style: {
        fontWeight: '500',
      },
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      type: 'time',
      show: true,
      categories:time_data,
      labels: {
        show: true,
      },
      // title: {
      //   text: "Last 7 days P&L"
      // }

    }, 
    
    
  }
  return (
    <React.Fragment> 
      <ReactApexChart
        options={options}
        series={a}
        type="bar"
        height="359" 
        className="apex-charts"
        // height="00"
      />
    </React.Fragment>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart;

//colors: stackedColumnChartColors,
    // legend: {
    //   position: "bottom"
    // },
    // fill: {
    //   opacity: 1
    // }
    // plotOptions: {
    //   bar: {
    //     horizontal: !1,
    //     columnWidth: "35%"
    //     // endingShape: "rounded"
    //   }
    // },
       // zoom: {
      //   enabled: false,
      // },
      // {name: 'Series B',data:['-0.01', '-0.001', '-0.12', 0, '0.19', '0.02', '0.04', '0.06', '-0.0010']},
    // {name: 'Series C',data:[-11, 17, -15, 15, -21, 14, -11, 18, -17, 12, -20, -18]}
    