import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WelcomeComp from "./WelcomeComp";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import api from '../../helpers/api_helper';
import firebase from "firebase/compat/app";
import classNames from "classnames";
import { formatUTCDate, formatUTCDateTime, getSettingsDateTimeFormat } from "../../services/common.service";
import api2 from '../../services/api.service';

var count = 0
const Dashboard = props => {
  document.title = "Dashboard";
  firebase.auth();
  const [userBalance, setUserBalance] = useState();
  const firestoreDb = firebase.firestore();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  var date = new Date();
  const [strangleOpenOrder, setStrangleOpenOrder] = useState();
  const [stranglePositionOrder, setStranglePositionOrder] = useState();
  const [diagonalOpenOrder, setDiagonalOpenOrder] = useState();
  const [diagonalPositionOrder, setDiagonalPositionOrder] = useState();
  const [straddleOpenOrder, setStraddleOpenOrder] = useState();
  const [straddlePositionOrder, setStraddlePositionOrder] = useState();
  const [trendOpenOrder, setTrendOpenOrder] = useState();
  const [trendPositionOrder, setTrendPositionOrder] = useState();
  const [graphsize, setgraphsize] = useState("5");
  const [others, setothes] = useState("7");
  const [otherMenu, setOtherMenu] = useState(false);
  const [summary, setSummary] = useState({});
  const [enddate, setenddate] = useState('')
  const [startdate, setstartdate] = useState('')
  const getUserBalance = async () => {
    await api({ contentType: true, auth: true }).get('/delta/get-balance').then(res => {
      setUserBalance(res.data.response.balance);
    }).catch(err => {
      setUserBalance('');
      throw err;
    })
  }
  let incount = 0
  let incount2 = 0
  const getOrdersDetails = async () => {
    const strangle_open_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'open').get().then(stra_open_order => {
      setStrangleOpenOrder(stra_open_order.size);
    });
    const strangle_position_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'closed').where('direction', '==', 'sell').get().then(stra_position_order => {
      setStranglePositionOrder(stra_position_order.size);
    });
    const diagonal_open_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'open').get().then(dia_open_order => {
      setDiagonalOpenOrder(dia_open_order.size);
    });
    const diagonal_position_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'closed').get().then(dia_position_order => {
      setDiagonalPositionOrder(dia_position_order.size);
    });
    const straddle_open_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'open').get().then(straddle_open_order => {
      setStraddleOpenOrder(straddle_open_order.size);
    });
    const straddle_position_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'closed').where('bot_status', '==', 'active').get().then(straddle_position_order => {
      setStraddlePositionOrder(straddle_position_order.size);
    });

    const trend_open_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'open').get().then(trend_open_order => {
      setTrendOpenOrder(trend_open_order.size);
    });
    const trend_position_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'closed').get().then(trend_position_order => {
      setTrendPositionOrder(trend_position_order.size);
    });
  }
  const convert = (str) => {
    // console.log('str', str)
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  // const [todaydata, settodaydata] = useState(false)
  // const get_latest_graph_data = () =>{
  //   var graph_created_at = localStorage.getItem('graph_created_at')
  //   let converted_date = new Date(date);
  //   let converted_start_date = new Date(graph_created_at);
  //   let converted_end_date2 = new Date(converted_date);
  //   var firestoreDb1 = firestoreDb.collection("users").doc(uid);
  //    var allorder1 = firestoreDb1.collection("history").where('created_at', '>=', converted_start_date.toISOString()).where('created_at', '<=', converted_end_date2.toISOString()).get();
  //    allorder1.then((allOrders) => {
  //     if (allOrders.docs.length !== 0){
  //       let orders_info = []
  //       allOrders.forEach(function (docs) {
  //         var data = docs.data()
  //         orders_info.push(data)
  //       })
  //       if (orders_info.filter(order => order.status === 'closed').length > 0 ) {
  //         settodaydata(true)
  //       }
  //     }else{
  //       settodaydata(false)
  //     }
  //   })
  // }
  // const [Interval,setIntervals] = useState(false)
  useEffect(() => {
    count = count + 1
    if (count === 1) {
      count = 0
      getUserBalance();
      getOrdersDetails();
    }
  }, [count])

  useEffect(() => {
    setenddate(`${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${(date.getDate()).toString().length === 1 ? '0' + (date.getDate()) : date.getDate()}`)
    getMonday(new Date());
    fetchPNLData(uid, startdate, enddate);
  }, [startdate, count]);



  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - 6; // adjust when day is sunday
    var currentMonday = new Date(d.setDate(diff));
    return setstartdate(`${currentMonday.getFullYear()}-${(currentMonday.getMonth() + 1).toString().length === 1 ? '0' + (currentMonday.getMonth() + 1) : currentMonday.getMonth() + 1}-${(currentMonday.getDate()).toString().length === 1 ? '0' + (currentMonday.getDate()) : currentMonday.getDate()}`);
  }
  const fetchPNLData = async (uid, startdate, enddate) => {
    const datestart = new Date(startdate);
    const dateend = new Date(enddate);
    var time_difference = dateend.getTime() - datestart.getTime();
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    let graph_data = '';
    var startdate1;
    var enddate1;
    let total_pnl_local = await localStorage.getItem('total_pnl' + uid);
    let graph_data_local = await localStorage.getItem('graph_data' + uid);
    if (total_pnl_local != '' && graph_data_local != '') {
      setSummary({

        ...summary,

        total_pnl: Number(total_pnl_local),

        graph_data: JSON.parse(graph_data_local)

      });
    }
    let total_pnl_val = '';
    let graph_data_arr = '';
    for (let i = 0; i <= days_difference + 1; i++) {

      startdate1 = (new Date(startdate));

      startdate1.setDate(datestart.getDate() - 1 + i)


      enddate1 = (new Date(startdate));

      enddate1.setDate(datestart.getDate() + i)

      var time = new Date();

      let converted_start_date = new Date(convert(time));

      converted_start_date.setHours(0, 0, 0, 0);

      let converted_end_date2 = new Date(convert(time));

      converted_end_date2.setHours(23, 59, 59, 999);


      var start = ((`${new Date(startdate1).getFullYear()}-${(new Date(startdate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(startdate1).getMonth() + 1) : new Date(startdate1).getMonth() + 1}-${startdate1.getDate().toString().length === 1 ? '0' + startdate1.getDate() : startdate1.getDate()}${converted_start_date.toISOString().slice(10, -1)}z`).toString())

      var end = ((`${new Date(enddate1).getFullYear()}-${(new Date(enddate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(enddate1).getMonth() + 1) : new Date(enddate1).getMonth() + 1}-${enddate1.getDate().toString().length === 1 ? '0' + enddate1.getDate() : enddate1.getDate()}${converted_end_date2.toISOString().slice(10, -1)}z`).toString())

      var firestoreDb1 = firestoreDb.collection("users").doc(uid);

      // var allorder1 = firestoreDb1.collection("history").where('created_at', '>=', start).where('created_at', '<=', end).get();

      let dates;
      let month;
      let dates1;
      let month1;
      var body = {
        uid: uid,
        start: start,
        end: end
      }
      await api2({ contentType: true, auth: true }).post('/dashboard/dashboard-history-data', body).then(async (res) => {
        var Result = res.data.response.data[0];
        var Result1 = res.data.response.data[1];
        // console.log('Result', res.data.response.data)
        // allorder1.then((allOrders) => {

        let orders_info = [];
        let orders_info1 = [];
        Result.forEach(function (doc) {

          var data = doc.data;

          data.id = doc.id;

          data.expiry_date = formatUTCDate(data.expiry_date);

          data.created_at = getSettingsDateTimeFormat(data.created_at);

          data.triggered_at = data.triggered_at ? getSettingsDateTimeFormat(data.triggered_at) : data.updated_at;

          orders_info.push(data);

        });
        Result1.forEach(function(doc){
          var data = doc.data;
          data.id = doc.id;
          // data.expiry_date = formatUTCDate(data.expiry_date);
          data.created_at = getSettingsDateTimeFormat(data.created_at);
          // data.triggered_at = data.triggered_at ? getSettingsDateTimeFormat(data.triggered_at) : data.updated_at;
          orders_info1.push(data);
        
        })
        let count1 = 0;
        let pnl1;

        let count = 0;

        let pnl;
        let total_pnl1 = orders_info1.filter(order => order.state === 'closed').reduce((sum, item) => {

          pnl1 = item.meta_data !== undefined ? item.meta_data.pnl ? item.meta_data.pnl : 0 : 0

          if (item.created_at !== undefined) {

            if (item.created_at.toString().length === 18) {

              dates1 = 0 + item.created_at.slice(0, 1);
              month1 = item.created_at.slice(3, 5);

            } else {

              dates1 = item.created_at.slice(0, 2);
              month1 = item.created_at.slice(3, 5);


            }



          } else {


          }

          return Number(sum) + Number(pnl1);

        }, 0);
        let total_pnl = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {

          if (item.strategy === 'strangles' || item.strategy === 'trend_following') {

            pnl = (item.booked_pnl ? Number(item.booked_pnl) * 0.001 * item.filled_quantity : item.realized_pnl ? Number(item.realized_pnl) : 0);

          } else {

            pnl = (item.booked_pnl ? Number(item.booked_pnl) : item.realized_pnl ? Number(item.realized_pnl) : 0);

          }

          if (item.created_at !== undefined) {

            if (item.created_at.toString().length === 18) {

              dates = 0 + item.created_at.slice(0, 1);
              month = item.created_at.slice(3, 5);

            } else {

              dates = item.created_at.slice(0, 2);
              month = item.created_at.slice(3, 5);


            }



          } else {


          }

          return Number(sum) + Number(pnl);

        }, 0);

        if (total_pnl !== 0 || total_pnl1 !== 0) {

          //graph_data.push({ pnl_data: (Number(total_pnl)).toFixed(2), time: `${(new Date(startdate).getMonth() + 1).toString().length === 1 ? '0' + (new Date(startdate).getMonth() + 1) : new Date(startdate).getMonth() + 1}/${dates}/${new Date(startdate).getFullYear()}` });
          if (graph_data.length == 0) {
            if (total_pnl !== 0 && total_pnl1 !==0 ){
            graph_data = ('[{ "pnl_data": "' + (Number(total_pnl+total_pnl1)).toFixed(7) + '","time":"' + `${dates}/${month}` + '"}');
          }else if (total_pnl == 0 && total_pnl1 !== 0){
            graph_data = ('[{ "pnl_data": "' + (Number(total_pnl1)).toFixed(7) + '","time":"' + `${dates1}/${month1}` + '"}');
          }else if (total_pnl !== 0 && total_pnl1 ==0 ){
            graph_data = ('[{ "pnl_data": "' + (Number(total_pnl)).toFixed(7) + '","time":"' + `${dates}/${month}` + '"}');
          }
          } else {
            if (total_pnl !== 0 && total_pnl1 !==0 ){
              graph_data = graph_data + (',{ "pnl_data": "' + (Number(total_pnl+total_pnl1)).toFixed(7) + '","time":"' + `${dates}/${month}`+ '"}');
            }else if (total_pnl == 0 && total_pnl1 !== 0){
              graph_data = graph_data + (',{ "pnl_data": "' + (Number(total_pnl1)).toFixed(7) + '","time":"' + `${dates1}/${month1}`+ '"}');
            }else if (total_pnl !== 0 && total_pnl1 ==0 ){
              graph_data = graph_data + (',{ "pnl_data": "' + (Number(total_pnl)).toFixed(7) + '","time":"' + `${dates}/${month}`+ '"}');
            }
          }
          if (!total_pnl_local && !graph_data_local) {
            setSummary({

              ...summary,

              total_pnl: Number(total_pnl),

              graph_data: JSON.parse(graph_data + ']')

            });

            if (i == 6) { //write to local storage after get the last value
              total_pnl_val = Number(total_pnl);
              graph_data_arr = JSON.parse(graph_data + ']')
              localStorage.setItem('graph_data' + uid, JSON.stringify(graph_data_arr))
              localStorage.setItem('total_pnl' + uid, Number(total_pnl_val))
            }
          } else {
            total_pnl_val = Number(total_pnl);
            graph_data_arr = JSON.parse(graph_data + ']')

            if (i == 6) {  //rewrite the chart data after get the last value

              setSummary({

                ...summary,

                total_pnl: Number(total_pnl),

                graph_data: JSON.parse(graph_data + ']')

              });
              localStorage.setItem('graph_data' + uid, JSON.stringify(graph_data_arr))
              localStorage.setItem('total_pnl' + uid, Number(total_pnl_val))
            }
          }

        }else{
          if (i == 6) {  //rewrite the chart data after get the last value
            localStorage.setItem('graph_data' + uid, JSON.stringify(graph_data_arr))
            localStorage.setItem('total_pnl' + uid, Number(total_pnl_val))
          }
        }

      })

    }


  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Derisnap")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>

            <Col xl="4" className="box_2">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <h4 className="card-title text-capitalize">Wallet Details Of {currentUser && currentUser.profile && currentUser.profile.first_name ? currentUser.profile.first_name : 'User'}</h4>
                  {currentUser.is_deltakey_updated ?
                    <Row>
                      <Col lg="12">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-warning text-white font-size-18">
                                <i className="mdi mdi-bitcoin" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">BTC</h5>
                          </div>

                          <Row>
                            <div className="col-lg-6">
                              <div className="mt-3">
                                <p>Wallet</p>
                                <h4 className={userBalance && userBalance.btc.balance > 0 ? "text-warning" : "text-block"}>{userBalance ? parseFloat(userBalance.btc.balance).toFixed(6) : ' '}</h4>
                              </div>
                            </div>

                            <div className="col-lg-6 align-self-end">
                              <div className="float-end mt-3">
                                <p>Available</p>
                                <h4 className={userBalance && userBalance.btc.available_balance > 0 ? "text-warning" : "text-block"}>{userBalance ? parseFloat(userBalance.btc.available_balance).toFixed(6) : ' '}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-success text-white font-size-18">
                                <i className="mdi mdi-alpha-t-circle" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">USDT</h5>
                          </div>

                          <Row>
                            <div className="col-lg-6">
                              <div className="mt-3">
                                <p>Wallet</p>
                                <h4 className={userBalance && userBalance.usdt.balance > 0 ? "text-success" : "text-block"}>{userBalance ? parseFloat(userBalance.usdt.balance).toFixed(2) : ' '}</h4>
                              </div>
                            </div>

                            <div className="col-lg-6 align-self-end">
                              <div className="float-end mt-3">
                                <p>Available</p>
                                <h4 className={userBalance && userBalance.usdt.available_balance > 0 ? "text-success" : "text-block"}>{userBalance ? parseFloat(userBalance.usdt.available_balance).toFixed(2) : ' '}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary text-white font-size-18">
                                <i className="mdi mdi-ethereum" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">ETH</h5>
                          </div>

                          <Row>
                            <div className="col-lg-6">
                              <div className="mt-3">
                                <p>Wallet</p>
                                <h4 className={userBalance && userBalance.eth.balance > 0 ? "text-primary" : "text-block"}>{userBalance ? parseFloat(userBalance.eth.balance).toFixed(6) : ' '}</h4>
                              </div>
                            </div>

                            <div className="col-lg-6 align-self-end">
                              <div className="float-end mt-3">
                                <p>Available</p>
                                <h4 className={userBalance && userBalance.eth.available_balance > 0 ? "text-primary" : "text-block"}>{userBalance ? parseFloat(userBalance.eth.available_balance).toFixed(6) : ' '}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    :
                    <Row>
                      <Col>
                        <Alert color="success" style={{ marginTop: "75px" }}>
                          Please update your <Link to="/settings" className="alert-link">API credentials</Link> and get your wallet details.
                        </Alert>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Card>
            </Col>

            <Col xl="8" className="order_detail box_3">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <h4 className="card-title">Orders Details
                    <span className="float-end" style={{ marginTop: "-4px" }}>
                      <Link to="/transaction" className="btn btn-primary  btn-sm">All Orders <i className="mdi mdi-arrow-right ms-1"></i></Link>
                    </span>
                  </h4>
                  {currentUser.is_deltakey_updated ?
                    // <Row>
                    //   <Col lg="6">
                    <Row>
                      <Col lg="12">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-hourglass" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Open orders</h5>
                          </div>

                          <Row>
                            {/* <div className="border-2 border-warning border p-2 rounded mt-1"> */}
                            <div className="col-lg-3 ">
                              <div className="mt-3 text-center text-warning border-2 border-warning border p-2 rounded mt-1">
                                <p>Strangles</p>
                                <h4 className={"text-block"}>{strangleOpenOrder ? strangleOpenOrder : 0}</h4>
                              </div>
                              {/* </div> */}
                            </div>

                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-success border-2 border-success border p-2 rounded mt-1">
                                <p>Diagonals</p>
                                <h4 className={"text-block"}>{diagonalOpenOrder ? diagonalOpenOrder : 0}</h4>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-dark border-2 border-dark border p-2 rounded mt-1">
                                <p>Straddles</p>
                                <h4 className={"text-block"}>{straddleOpenOrder ? straddleOpenOrder : 0}</h4>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-primary  border-2 border-primary border p-2 rounded mt-1">
                                <p>Trend Following</p>
                                <h4 className={"text-block"}>{trendOpenOrder ? trendOpenOrder : 0}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                      {/* </Row> */}
                      {/* // <Row> */}
                      <Col lg="12">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-check-circle" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Position Orders</h5>
                          </div>

                          <Row>
                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-warning border-2 border-warning border p-2 rounded mt-1">
                                <p>Strangles</p>
                                <h4 className={"text-block"}>{stranglePositionOrder ? stranglePositionOrder : 0}</h4>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-success border-2 border-success border p-2 rounded mt-1">
                                <p>Diagonals</p>
                                <h4 className={"text-block"}>{diagonalPositionOrder ? diagonalPositionOrder : 0}</h4>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mt-3 text-center border-2 text-dark border-dark border p-2 rounded mt-1">
                                <p>Straddles</p>
                                <h4 className={"text-block"}>{straddlePositionOrder ? straddlePositionOrder : 0}</h4>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="mt-3 text-center text-primary border-2 border-primary border p-2 rounded mt-1">
                                <p>Trend Following</p>
                                <h4 className={"text-block"}>{trendPositionOrder ? trendPositionOrder : 0}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    //   <div className="border p-3 rounded mt-4">
                    //       <div className="d-flex align-items-center mb-3">
                    //         <div className="avatar-xs me-3">
                    //           <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    //             <i className="bx bx-hourglass" />
                    //           </span>
                    //         </div>
                    //         <h5 className="font-size-14 mb-0">Open orders</h5>
                    //       </div>

                    //       <Row>
                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Strangles</p>
                    //             <h4 className={strangleOpenOrder > 0 ? "text-info" : "text-block"}>{strangleOpenOrder}</h4>
                    //           </div>
                    //         </div>

                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Diagonals</p>
                    //             <h4 className={diagonalOpenOrder > 0 ? "text-info" : "text-block"}>{diagonalOpenOrder}</h4>
                    //           </div>
                    //         </div>
                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Straddles</p>
                    //             <h4 className={straddleOpenOrder > 0 ? "text-info" : "text-block"}>{straddleOpenOrder}</h4>
                    //           </div>
                    //         </div>
                    //         <div className="col-lg-6">
                    //           <div className="mt-3">
                    //             <p>Trend Following</p>
                    //             <h4 className={trendOpenOrder > 0 ? "text-info" : "text-block"}>{trendOpenOrder}</h4>
                    //           </div>
                    //         </div>

                    //       </Row>
                    //     </div>
                    //   </Col>
                    //   <Col lg="6">
                    //     <div className="border p-3 rounded mt-4">
                    //       <div className="d-flex align-items-center mb-3">
                    //         <div className="avatar-xs me-3">
                    //           <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    //             <i className="bx bx-check-circle" />
                    //           </span>
                    //         </div>
                    //         <h5 className="font-size-14 mb-0">Position Orders</h5>
                    //       </div>

                    //       <Row>
                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Strangles</p>
                    //             <h4 className={stranglePositionOrder > 0 ? "text-info" : "text-block"}>{stranglePositionOrder}</h4>
                    //           </div>
                    //         </div>
                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Diagonals</p>
                    //             <h4 className={diagonalPositionOrder > 0 ? "text-info" : "text-block"}>{diagonalPositionOrder}</h4>
                    //           </div>
                    //         </div>
                    //         <div className="col-lg-4">
                    //           <div className="mt-3">
                    //             <p>Straddles</p>
                    //             <h4 className={straddlePositionOrder > 0 ? "text-info" : "text-block"}>{straddlePositionOrder}</h4>
                    //           </div>
                    //         </div>
                    //         <div className="col-lg-6">
                    //           <div className="mt-3">
                    //             <p>Trend Following</p>
                    //             <h4 className={trendPositionOrder > 0 ? "text-info" : "text-block"}>{trendPositionOrder}</h4>
                    //           </div>
                    //         </div>
                    //       </Row>
                    //     </div>
                    //   </Col>
                    // </Row>
                    :
                    <Row>
                      <Col>
                        <Alert color="success" style={{ marginTop: "75px" }}>
                          Please update your <Link to="/settings" className="alert-link">API credentials</Link> and get your order details.
                        </Alert>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Card>
            </Col>

            <Col xl="6" className="box_3">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Go to</h4>

                  <div className="row">
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="bx bx-cog h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Bot Settings</h5>
                              <span className="float-end" style={{ marginTop: "-4px" }}>
                                <Link to="/settings" className="btn btn-primary  btn-sm"> Go to Settings <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                Update Api, Bot, Privacy and Telegram.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="mdi mdi-alpha-s-box h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Strangles</h5>
                              <span className="float-end" style={{ marginTop: "-4px" }}>
                                <Link to="/strangles" className="btn btn-primary  btn-sm">Go to Strangles <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Strangles.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="mdi mdi-alpha-d-box h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Diagonals</h5>
                              <span className="float-end" style={{ marginTop: "-4px" }}>
                                <Link to="/diagonals" className="btn btn-primary  btn-sm">Go to Diagonals <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Diagonals
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="bx bx-cube h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Straddles</h5>
                              <span className="float-end" style={{ marginTop: "-4px" }}>
                                <Link to="/straddles" className="btn btn-primary  btn-sm">Go to Straddles <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Straddles
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* #TASK0! */}
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle" />
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <i className="mdi mdi-alpha-t-box h2 text-primary" />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Trend Following</h5>
                              <span className="float-end" style={{ marginTop: "-4px" }}>
                                <Link to="/trend-following" className="btn btn-primary  btn-sm">Go to Straddles <i className="mdi mdi-arrow-right ms-1"></i></Link>
                              </span>
                              <p className="text-muted">
                                create, view active and completed Trend Following
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                </CardBody>
              </Card>
            </Col>

            <Col xl="6" className="box_3">
              <Card className="overflow-hidden" xl="8" style={{ minHeight: "96%" }}>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title">Today&apos;s PNL  {summary.total_pnl ? (<span className={parseFloat(summary.total_pnl) < 0 ? "text-danger" : "text-success"}>{summary.total_pnl.toFixed(2)} USDT</span>) : <snap className="text-danger">NA</snap>} </h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="list-inline-item">
                          <Dropdown
                            isOpen={otherMenu}
                            toggle={() => {
                              setOtherMenu(!otherMenu);
                            }}
                          >
                            <DropdownToggle
                              tag="i"
                              className="btn nav-btn"
                              type="button"
                            >
                              <i className="bx bx-dots-horizontal-rounded" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem> <Link
                                to="/transaction" className="text-dark"
                              >All Transactions</Link></DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <WelcomeComp graphdata={summary.graph_data} />
                </CardBody>
              </Card>
            </Col>



          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
