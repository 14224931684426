import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row,UncontrolledAlert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userForgetPassword,userForgetPasswordSuccess,userForgetPasswordError } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import blz_logo from "../../assets/images/blaze_logo.png";
//import { values } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {form_loading:false};
  }

  componentDidMount() {
    this.props.userForgetPasswordSuccess("");
    this.props.userForgetPasswordError("");
  }


  render() {
    return (
      <React.Fragment>        
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Forgot Password</h5>
                          <p>Enter your email and get your reset password link.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError.includes("Firebase:") ? this.props.forgetError.replace("Firebase:",""):this.props.forgetError}                          
                        </Alert>
                      ) : null}
                      {/* {this.props.forgetSuccessMsg ? (
                        <UncontrolledAlert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </UncontrolledAlert>
                      ) : null} */}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email:"",
                          recaptcha: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email("Please Enter Valid Email").required(
                            "Please Enter Your Email"
                          ),
                          recaptcha : Yup.string().required(
                            "Please verify you are not a robot"
                          ),
                        })}
                        onSubmit={(values) => {
                          this.setState({form_loading : true});
                          this.props.userForgetPassword(values, this.props.history);                          
                        }}
                      >
                        {({ errors, status, touched,setFieldValue }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} render="explicit" onChange={(response) => { setFieldValue("recaptcha", response); }}/>                            
                            {errors.recaptcha && touched.recaptcha && (<p className="text-danger" style={{fontSize: '80%',color:'#f46a6a'}}>{errors.recaptcha}</p>)}
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                {this.state.form_loading && !this.props.forgetSuccessMsg && !this.props.forgetError ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                Reset
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} DeriSnap.
                    Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'11px'}} ></img></a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.string,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
  userForgetPasswordSuccess: PropTypes.any,
  userForgetPasswordError: PropTypes.any,
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword,userForgetPasswordSuccess,userForgetPasswordError })(ForgetPasswordPage)
);