// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    CardTitle, Spinner, Alert, Table, Tooltip, Badge,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Popover,
    PopoverHeader,
    PopoverBody,
    Button
} from "reactstrap";
let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_2a46a') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: "BINANCE:BTCUSDT",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "in",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            withdateranges: true,
            range: "YTD",
            hide_side_toolbar: false,
            allow_symbol_change: true,
            details: true,
            calendar: true,
            studies: [{
              id: "STD;EMA",
              inputs: {
                in_0: 9, // Replace with the desired ATR length value
                in_1: 'close',
                in_2:0 // Replace with the desired factor value
            },
            override: {
              "plot.color.0": "#f02b6d"
            }
          }],
            container_id: "tradingview_2a46a"
          });
        }
      }
    },
    []
  );
//"STD;Average_True_Range","STD;RSI","STD;Smoothed%1Moving%1Average",
  return (
    <React.Fragment>
    <div className="page-content">

        <Container fluid>
        <Row> <h4 className="mb-0 font-size-18">Tradingview.</h4>
                        <Col lg="12" style={{"height":"500px"}}>
    <div className='tradingview-widget-container' style={{"height":"500px"}}>
      <div id='tradingview_2a46a'  style={{"height":"500px"}}/>
      <div className="tradingview-widget-copyright" style={{"height":"500px"}}>
        <a href="https://in.tradingview.com/symbols/BTCUSDT/?exchange=DELTA" ><span className="blue-text">BTCUSDT chart</span></a>
      </div>
    </div>
    </Col></Row>
    </Container>
    </div>
    </React.Fragment>
  );
}

