import React, { useEffect } from 'react'
import Quantity from '../../assets/help/quantity.png'
import { Container, Row, Col,Card,
  CardBody, } from "reactstrap"
import blz_logo from "../../assets/images/blaze_logo.png";
import place_order from "../../assets/help/place_order.png";
import logoDark from "../../assets/images/logo-color.png";
import puts from "../../assets/help/Puts.png";
import calls from "../../assets/help/Calls.png";
import both from "../../assets/help/Both.png";
import indexDistance from "../../assets/help/IndexDistance.png";
import pputs from "../../assets/help/PPuts.png";
import trailing_image from '../../assets/images/trailing_image3.png'
import { Link } from "react-router-dom";
const index = () => {
  useEffect(() => {
    console.log('Help Page => ',window.location.hash.substring(1) )
    if(window.location.hash && window.location.hash.substring(1) ){
      let element = document.getElementById(window.location.hash.substring(1))
      if(element){
        element.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }
  }, [])
  return (
    <div className='text-black'>
    <div className='mt-2 mx-4'  style={{marginBottom:"75px"}}>
    <Card className="border ">
    <CardBody>
    <h1 className='text-center text-black' style={{fontSize:"27px"}}><img src={logoDark} style={{float:"left"}} alt="" height="50" /><snap className='float-end'>Trend Following Strategy Bot Setup Guide</snap></h1>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div  className='text-black'>
    <h4 id='quantity' ><a className='headerlink text-black' href='#quantity'>1. Quantity</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Determine the quantity for each signal. For instance, if the <a className='headerlink text-dark' href='#strike_count'>strike count</a> is 2 and the quantity is 4, then the orders will be placed across two strikes, with 2 quantities in each.</p>
    <div >
    <img  sizes="(max-width: 100%) 75%, 100%" src={Quantity} alt="can't load the image" className='img-fluid' />
    </div>
    </div> 
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='place_order'><a className='headerlink text-black' href='#place_order'>2. Expiry</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary' >Select the expiry for placing orders. For example, choosing &apos;Tomorrow&apos; will generate orders based on strikes from the upcoming expiry date
    <br></br><b>Ex:</b><br></br>
    If today is JAN 08, 2024:<br></br>
    08 JAN 2024 &gt; Today expiry <br></br>
    09 JAN 2024 &gt; Tomorrow expiry <br></br>
    10 JAN 2024 &gt; Day after tomorrow expiry<br></br>
    12 JAN 2024 &gt; Current week friday expiry<br></br>
    19 JAN 2024 &gt; Next week friday expiry<br></br>
    26 JAN 2024 &gt; Next to next week friday expiry<br></br>
    23 FEB 2024 &gt; Next month end expiry<br></br>
    </p>
    <div>
    <img sizes="(max-width: 100%) 75%, 100%" src={place_order} alt="can't load the image" className='img-fluid' />
    </div>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='distance_from_index'><a className='headerlink text-black' href='#distance_from_index'>3. Distance from index</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Specify the distance from the index value to select the strike price. The bot will choose the strike based on this distance.
    <br></br><b>Ex:</b><h6>i) Single <a className='headerlink text-dark' href='#strike_count'> Strike Counts</a></h6>&nbsp;If the current BTC/USDT index price: 46136.2 <br></br>&nbsp;The distance from the index: 1%, <a className='headerlink text-dark' href='#strike_count'> strike count</a> : 2 <br></br> &nbsp;For <snap className='text-success'>calls</snap>: 46136.2+1% = 46597 <br></br> &nbsp; The bot selects the closest strike at 46600, near the calculated value of 46597<br></br><br></br> &nbsp;For <snap className='text-danger'>puts</snap>: 46136.2-1%=45674.838  <br></br> &nbsp; The bot selects the closest strike at 45600, near the calculated value of 45674<br></br></p>
    <div >
    <img sizes="(max-width: 100%) 75%, 100%" src={indexDistance} alt="can't load the image" className='img-fluid' /><br></br>
    </div>
    <p className='mb-2 mt-1 font-size-13 text-secondary'><br></br><h6>ii) Multiple<a className='headerlink text-dark' href='#strike_count'> Strike Count</a></h6>&nbsp;If the current BTC/USDT index price: 46136.2 <br></br>&nbsp;The distance from the index: 1%, <a className='headerlink text-dark' href='#strike_count'> strike count</a> : 2 <br></br> &nbsp;For <snap className='text-success'>calls</snap>: 46136.2+1% = 46597 <br></br> &nbsp;The bot selects the closest strike at 46600, near the calculated value of 46597. The subsequent strike chosen will be 46800, which follows after 46600. <br></br><br></br> &nbsp;For <snap className='text-danger'>puts</snap>: 46136.2-1%=45674.838  <br></br> &nbsp;The bot selects the closest strike at 45600, near the calculated value of 45674. The subsequent strike chosen will be 45400, which follows after 45600.<br></br></p>
    <div >
    <img sizes="(max-width: 100%) 75%, 100%" src={pputs} alt="can't load the image" className='img-fluid' />
    </div>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='select_side'><a className='headerlink text-black' href='#select_side'>4. Select Side</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>When &apos;Side&apos; is set to &apos;Buy&apos;, It makes a buy order. When set to &apos;Sell&apos;, It created a sell order. Chooseing &apos;Both&apos; generates both buy and sell orders
      <br></br>
  {/*create table for each side and uptrend and downtrend*/}
  <div className='col-xl-6'>
      <table className='table table-striped  table-bordered mb-0 ' >

        <thead className="table-light"><tr>
        <th></th>
      <th>Buy</th>
      <th>Sell</th>
      <th>Both</th></tr>
      </thead>
      <tbody>
      <tr>
      <th>Uptrend<span className="text-success font-size-18" style={{"vertical-align": "middle"}}><i className="bx bx-caret-up"></i></span></th>
      <td>Buy on calls</td>
      <td>Sell on puts</td>
      <td>Buy on calls & Sell on puts</td>

      </tr>
      <tr>
      <th>Downtrend<span className="text-danger font-size-18" style={{"vertical-align":"middle"}}><i className="bx bx-caret-down"></i></span></th>
      <td>Buy on puts</td>
      <td>Sell on calls</td>
      <td>Buy on puts & Sell on calls</td>

      </tr>
      </tbody>
      </table></div>
    <br></br><b >Ex:</b><br></br><b>Buy:</b><br></br>
    <div >
    <img sizes="(max-width: 100%) 75%, 100%"  src={indexDistance} alt="can't load the image" className='img-fluid mt-2 mb-2' />
    </div>
    <br></br><b >Sell:</b><br></br>
    <div >
    <img sizes="(max-width: 100%) 75%, 100%" src={calls} alt="can't load the image" className='img-fluid mt-2 mb-2' /><br></br><b >Both:</b><br></br>
    </div>
    <div >
    <img sizes="(max-width: 100%) 75%, 100%" src={both} alt="can't load the image" className='img-fluid mt-2 mb-2' /></div></p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='strike_count'><a className='headerlink text-black' href='#strike_count'>5. Strike Counts</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Define the maximum number of strikes to use for order creation. For example, if the strike count is set to 2, the bot will generate orders based on two different strike prices. <br /> 
    <ul>
      <li>If we receive an <span className='text-dark'>uptrend</span> signal, the strike count is set to <span className='text-dark'>2</span>, and the side is selected as <span className='text-dark'>sell</span>, then it will create <span className='text-dark'>two sell orders on the put side</span>.</li>
      <li>If we receive an <span className='text-dark'>uptrend</span> signal, the strike count is set to <span className='text-dark'>3</span>, and the side is selected as <span className='text-dark'>both</span>, then it will create <span className='text-dark'>two sell orders on the put side</span> and <span className='text-dark'>one buy order on the call side</span>. In total, three orders will be created.</li>
      <li>If we receive an <span className='text-dark'>uptrend</span> signal, the strike count is set to <span className='text-dark'>4</span>, and the side is selected as <span className='text-dark'>both</span>, then it will create <span className='text-dark'>two sell orders on the put side</span> and <span className='text-dark'>two buy orders on the call side</span>. In total, four orders will be created.</li>
    </ul>
     </p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='total_quantity_limit'><a className='headerlink text-black' href='#total_quantity_limit'>6. Total quantity limit</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Sets the maximum number of orders allowed. If the sum of <a className='headerlink text-dark' href='#quantity'>quantities</a> in active open orders and open positions reaches this limit, no new orders will be placed for the next signal.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='maximum_numberof_orders'><a className='headerlink text-black' href='#maximum_numberof_orders'>7. Maximum number of orders from same trend signal</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Specifies the maximum number of orders allowed per trend signal. It counts orders until the trend shifts from uptrend to downtrend, or vice versa. If the order count reaches this limit for a single trend direction, no new orders will be placed for the subsequent signal in the same direction. </p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='market_stop_loss_percentage'><a className='headerlink text-black' href='#market_stop_loss_percentage'>8. Market Stop Loss Percentage</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>This is used to calculate the stop loss value.When the stop loss value is hit:
    <ul>
    <li>If there&apos;s an open sell order position, a buy order is instantly placed at the current market price to close the position.</li>
    <li>If there&apos;s an open buy order position, a sell order is placed at the current market price to close the position.</li>
    </ul>
    This helps manage risk by quickly closing positions when the market moves unfavorably, preventing further losses.

</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='make_limit_orders_xpercentage'><a className='headerlink text-black' href='#make_limit_orders_xpercentage'>9. Make Limit Orders x% from Mid/MarkPrice</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>This field is to calculate the price for the limit order.
    <br></br>
This helps create limit orders with prices close to the current market price. If the value is 0 or empty, then the limit price will be the mid value of best bid and best ask. otherwise it will use the following method for limit price
<br></br>


        <b>Ex:</b> If you entered 75% here, then the calculation for price as follows.<br></br>
        <b>for sell order&gt;</b> if the best bid is 100 and the best ask is 300, then the mid value is 200 Difference between current best ask and mid value is 100 (300-200), 75% of value 100 is 75. so limit order price= 200 (mid price) + 75 So the sell price will be 275.<br></br>
        <b>for buy order &gt;</b> Difference between mid value and current best bid is 100 (200-100), 75% of value 100 is 75. so limit order price= 200 (mid price) - 75, So the buy price will be 125.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='minimum_take_profite_percent'><a className='headerlink text-black' href='#minimum_take_profite_percent'>11. Trailing Take Profit  %</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>To set the Minimum profit for an order.<br></br>
    <img src={trailing_image} alt="Trailing Stop Price Percentage" style={{width:"40%"}}/> <br></br>
    <b>Ex:</b> Trailing Take Profit Percentage = 10%, <a className='headerlink text-black' href='#trailing_take_profite_percentage'>Trailing Stop Price Percentage</a> = 5%.<br></br>
    <b>1.</b>When order entered into position with price as 600, then Minimum profit will be 540 (-10% of 600) & Trailing will be 570 (-5% of 600), that is Stop price = 570.<br></br>
    <b>2.</b>When ASK price was in the range of 540 to 569, the Stop price will be 570.<br></br>
    <b>3.</b>When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br></br>
    <b>4.</b>When ASK price is 510 (that is going behind the Minimum profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br></br>
    <b>5.</b>That is when Profit goes high, we will reduce the stop price as well.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='trailing_take_profite_percentage'><a className='headerlink text-black' href='#trailing_take_profite_percentage'>12. Trailing Stop Price %</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>To set the Minimum profit for an order.<br></br>

        <b>Ex:</b> <a className='headerlink text-dark' href='#minimum_take_profite_percent'>Trailing Take Profit Percentage</a> = 10%, Trailing Stop Price Percentage = 5%.<br></br>
        <b>1.</b> When order entered into position with price as 600, then Minimum profit will be 540 (-10% of 600) & Trailing will be 570 (-5% of 600), that is Stop price = 570.<br></br>
        <b>2.</b> When ASK price was in the range of 540 to 569, the Stop price will be 570.<br></br>
        <b>3.</b> When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br></br>
        <b>4.</b> When ASK price is 510 (that is going behind the Minimum profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br></br>
        <b>5.</b> That is when Profit goes high, we will reduce the stop price as well.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='waiting_time_for_re_entry'><a className='headerlink text-black' href='#waiting_time_for_re_entry'>13. Waiting Time for re-entries (H : M)</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Time interval before a new trend following order is generated for each signal </p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='waiting_time_for_re_entrie_after_stop_loss'><a className='headerlink text-black' href='#waiting_time_for_re_entrie_after_stop_loss'>14. Waiting Time for re-entries after stop loss (H : M)</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Time interval before a new trend following order is placed on the same side after experiencing a <a className='headerlink text-black' href='#market_stop_loss_percentage'>stop loss</a>.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='waiting_timefor_entry'><a className='headerlink text-black' href='#waiting_timefor_entry'>15. Waiting Time for enter market order(H : M)</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Set the duration for waiting on limit orders. If a limit order isn&apos;t filled within this timeframe, a market order will be placed to enter into the position.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='order_interval_for_same_trend_signal'><a className='headerlink text-black' href='#order_interval_for_same_trend_signal'>16. Order Interval for same trend signal (H : M)</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Interval for order creation on the same trend signal.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='close_old_orders_on_new_signal'><a className='headerlink text-black' href='#close_old_orders_on_new_signal'>17. Close old orders on new signal </a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Close old orders on every new signal</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='close_old_orders_on_trend_change'><a className='headerlink text-black' href='#close_old_orders_on_trend_change'>18. Close Existing Positions and Open Orders on Trend Change</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Automatically close existing orders when the trend signal shifts from a downtrend to an uptrend or vice versa.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='close_old_orders_on_trend_change'><a className='headerlink text-black' href='#close_old_orders_on_trend_change'>19. Close Open Orders on Trend Reversal</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Close only open orders when the trend signal shifts from a downtrend to an uptrend or vice versa.</p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='trend_indicator'><a className='headerlink text-black' href='#trend_indicator'>20.Trend Indicator</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Select the trading indicator: SuperTrend, SMA Crossover, EMA Crossover, MACD Signal Line Cross. Based on your selection, please provide the necessary parameters</p>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>
    Certainly, here are the necessary parameters for each indicator:
    <br></br>  <br></br>
    <b>SuperTrend:</b>
    <br></br>
    <span className='text-dark'>Interval: </span>Interval for OHLC values<br></br>
    <span className='text-dark'>ATR Length: </span>Average True Range(ATR) is the average of true ranges over the specified period. ATR measures volatility, taking into account any gaps in the price movement.<br></br>
    
    <span className='text-dark'>Factor: </span>Multiplier, if the value 3 which means the range over 10 days (ATR) has been multiplied by 3 which gives you the volatility for movement.<br></br>
    <span className='text-dark'>Minimum supertrend distance: </span>The distance between the current price and the supertrend line should be greater than this value<br></br>
    <span className='text-dark'>Maximum supertrend distance: </span>The distance between the current price and the supertrend line should be less than this value<br></br><br></br>
    <b>SMA Crossover:</b>
    <br></br>
    <span className='text-dark'>Interval:</span> Interval for OHLC values<br></br>
    <span className='text-dark'>Short length:</span> Define the short length period for the Simple Moving Average (SMA).<br></br>
    <span className='text-dark'>Long length:</span> Set the long length period for the SMA.<br></br><br></br>
    <b>EMA Crossover:</b><br></br>
    <span className='text-dark'>Interval: </span>Interval for OHLC values<br></br>
    <span className='text-dark'>Short length:</span> Input the short length period for the Exponential Moving Average (EMA).<br></br>
    <span className='text-dark'>Long length: </span>Specify the long length period for the EMA.<br></br><br></br>
    <b>MACD Signal Line Cross:</b><br></br>
    <span className='text-dark'>Interval: </span>Interval for OHLC values<br></br>
    <span className='text-dark'>Fast Period:</span> Set the fast period for the MACD.<br></br>
    <span className='text-dark'>Slow Period: </span>Specify the slow period for the MACD.<br></br>
    <span className='text-dark'>Signal Period:</span> Define the signal line period for the MACD.<br></br>
    These parameters define the settings for each selected trading indicator. Adjust them based on your preferred strategy or market conditions.
    </p>
    {/* <img style={{height:"200px", width:"85%"}} src={Quantity} alt="can't load the image" className='img-fluid' /> */}
    </div >
    </CardBody>
    </Card>
    {/* <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='intraval'><a className='headerlink text-black' href='#intraval'>20. Interval</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Interval for OHLC values</p>
    
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='atr_length'><a className='headerlink text-black' href='#atr_length'>21. ATR length </a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Average True Range(ATR) is the average of true ranges over the specified period. ATR measures volatility, taking into account any gaps in the price movement.</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='factor'><a className='headerlink text-black' href='#factor'>22. Factor</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>Multiplier, if the value 3 which means the range over 10 days (ATR) has been multiplied by 3 which gives you the volatility for movement.</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='minimum_supertrend_distance'><a className='headerlink text-black' href='#minimum_supertrend_distance'>23. Minimum supertrend distance</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>The distance between the current price and the supertrend line should be greater than this value</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='maximum_supertrend_distance'><a className='headerlink text-black' href='#maximum_supertrend_distance'>24. Maximum supertrend distance</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>The distance between the current price and the supertrend line should be less than this value</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='short_length'><a className='headerlink text-black' href='#short_length'>25. Short length</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'><b>MACD: </b>The short length, long length, and signal length refer to the time periods used in the MACD calculation.The short length EMA is calculated over a relatively short period of time<br></br>
    <b>SMA: </b>The short length and long length refer to the time periods used to calculate the SMA. The short length SMA is calculated over a relatively short period of time<br></br>
    <b>EMA: </b>The short length and long length refer to the time periods used to calculate the EMA. The short length EMA is calculated over a relatively short period of time</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='long_length'><a className='headerlink text-black' href='#long_length'>26. Long length</a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'><b>MACD: </b>The short length, long length, and signal length refer to the time periods used in the MACD calculation.The long length EMA is calculated over a longer period of time<br></br>
    <b>SMA: </b>The short length and long length refer to the time periods used to calculate the SMAThe long length SMA is calculated over a longer period of time<br></br>
    <b>EMA: </b>The short length and long length refer to the time periods used to calculate the EMA. The long length EMA is calculated over a longer period of time</p>
    </div>
    </CardBody>
    </Card>
    <Card className="border">
    <CardBody>
    <div className='text-black'>
    <h4 id='signal_length'><a className='headerlink text-black' href='#signal_length'>27. Signal length </a></h4>
    <p className='mb-2 mt-1 font-size-13 text-secondary'>The distance between the current price and the supertrend line should be less than this value</p>

    </div>
    </CardBody>
    </Card> */}
    </div>
    <div>
    <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © DeriSnap.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'13px'}} ></img></a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
    </div>
  )
}

export default index