import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { adminSessionLogout, loginUser, logoutUser } from "store/actions";

const AdminSession = () => {

  const history = useHistory();
  const dispatch = useDispatch();      

  function useQuery() {
    const { search } = useLocation();  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const searchParams = useQuery();

  //Get Decrypted uid
  const getCustomToken = () => {
    try {
      const custom_token = atob(searchParams.get('custom-token'));       
      return custom_token;
    } catch (error) {
      
      return history.push("/404");
    }  
  }  
  const custom_token = getCustomToken();
  const name = searchParams.get('name')?searchParams.get('name'):'user';
  
  const startAdminSession = async () => {
    try {		            
      if (custom_token) {
        dispatch(adminSessionLogout(history,false));
        dispatch(loginUser({ custom_token: custom_token },history));
      }     
		} catch (e) {            
      return false;
    }
  }

  useEffect(() => {   
    startAdminSession();
  }, []);

  return (  
    <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    Login as {name}
                  </h1>
                  <h4>Wait a moment, we are redirecting you to user panel...</h4>                  
                </div>
              </Col>
            </Row>            
          </Container>
        </div>
      </React.Fragment>    
  );
};

export default AdminSession;
