import React, { useState } from 'react'
import DeltaSetup from 'pages/Authentication/DeltaSetup'
import Delta from '../../assets/images/delta.png'
import { Link, useHistory } from 'react-router-dom'
import { Col, Container, FormFeedback, Input, Label, Modal, Row,Nav,NavItem,NavLink,TabContent,TabPane,CardText, Card, CardBody, Button } from "reactstrap";
import { toast } from "react-toastify";
import Breadcrumbs from 'components/Common/Breadcrumb';
import colorlogo from "../../assets/images/logo-color.png";
const BotSelect = () => {
  const [SelectedBot, setSelectedBot] = useState('')
  const history = useHistory()
  const goDashboard = ()=>{
    history.push("/ccxt/settings");
    let user = JSON.parse(localStorage.getItem("user"));
    toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
  }
  return (
    SelectedBot === ''?
<>

              
<Container fluid >
          {/* Render Breadcrumb */}
          <div style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
          <span className="logo-sm" >
                    <img src={colorlogo} alt="" height="50" />
                  </span>

          </div>
          <Row className="mx-auto d-flex justify-content-center col-9 mt-5">
          
            <Col  xl="10" sm="10" >
              <Card >
                <CardBody>
                  <div className="text-left">
                    <Row className="justify-content-center">
                      <Col lg="10">
                        <h4 className="mt-4 fw-semibold">
                        Derivatives Bot for Delta Exchange <img src={Delta} width={"20px"} />
                        </h4>
                        <p className="text-muted mt-3">
                        Boost your trading with our bot-enabled Delta Exchange option trade. Utilize various strategies like triangles, straddles, diagonals, trend following, and delta hedging to optimize your trading performance
                        </p>

                        <div className="mt-4 float-end">
                          {/* button triggers modal */}
                          <Button
                            type="button"
                            color="success"
                            onClick={()=>setSelectedBot('Delta')}
                          >
                            Start <i className="mdi mdi-arrow-right ms-1"></i>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="10" sm="10">
              <Card>
                <CardBody>
                  <div className="text-left">
                    <Row className="justify-content-center">
                      <Col lg="10">
                        <h4 className="mt-4 fw-semibold">
                         Spot Trading Bot
                        </h4>
                        <p className="text-muted mt-3">
                         Explore our platform&#39;s features! We provide the facility to connect with spot trade exchanges like Delta Exchange, Binance, Paper Trade etc. Take advantage of our spot trading bot to simplify your trading experience.
                        </p>

                        <div className="mt-4 float-end">
                          {/* button triggers modal */}
                          <Button
                            type="button"
                            color="success"
                            onClick={goDashboard}
                          >
                            Start<i className="mdi mdi-arrow-right ms-1"></i>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
 : <div><DeltaSetup/></div>
  )
}

export default BotSelect