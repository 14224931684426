import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card,CardBody } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"
import logo from "../../assets/images/logo.png";
import logoLight from "../../assets/images/full-logo-light.png";
import logoLightSvg from "../../assets/images/logo_light.png";
import logoDark from "../../assets/images/logo-color.png";

class Pages404 extends Component {
  render() {
    //meta title
    // document.title = "404 Error Page | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="account-pages my-5 ">
          <Container>
            <Row>
              <Col lg="12">
                <center>
              <Link to="/" className="logo logo-dark ">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    {/* //LogoChange */}
                    <img src={this.props.layoutModeType && this.props.layoutModeType== "dark"?logoLight:logoDark} alt="" height="50" />
                  </span>
                </Link>
                </center>
              <Card>
                <CardBody>
              
                <div className=" mb-5">
          
                  {/* <h4 className="text-uppercase">Home page</h4> */}
                  <div className="mt-5 ">
                   
                    <div>
            <h2>Great news! Our beta bot is now ready, and we’re excited to invite you to register for free.</h2>
            <p>Here’s how you can get started:</p>
            <ul>
                {/* <li><strong>Step 1: Visit Our Website</strong><br />
                    Head over to our official Derisnap website at <a href="https://trade.derisnap.com">trade.derisnap.com</a></li>
                <li><strong>Step 2: Sign Up</strong><br />
                    Click on the “Sign Up” or “Register” button prominently displayed on the homepage.</li> */}
                <li><strong>Step 1: Create Your Account</strong><br />
                    Fill out the registration form with your:
                    <ul>
                        <li>Full Name: Enter your first and last name.</li>
                        <li>Email Address: Provide a valid email address.</li>
                        <li>Password: Create a secure password for your account.</li>
                    </ul>
                </li>
                <li><strong>Step 2: Verify Your Email</strong><br />
                    Once you’ve completed the registration form, we’ll send a verification email to the address you provided. Click on the verification link in the email to confirm your account.</li>
                <li><strong>Step 3: Log In</strong><br />
                    Return to the website and log in using your email address and password.</li>
                <li><strong>Step 4: Add Delta Exchange Key and Token</strong><br />
                    After logging in, you’ll need to add your Delta Exchange Key and Token to access the full functionality of our beta bot. Here’s how:
                    <ul>
                        <li>Look for the “Profile Settings” option in the navigation menu.</li>
                        <li>Inside your settings, you’ll find a section labeled “API Keys”.</li>
                        <li>Click on this section and you’ll see fields to enter your Delta Exchange Key and Token.</li>
                    </ul>
                </li>
                <li><strong>Step 5: Save Your Delta Exchange Key and Token</strong><br />
                    Enter your Delta Exchange Key and Token in the provided fields. Make sure to double-check for accuracy.</li>
                <li><strong>Step 6: Verify Delta Exchange Integration</strong><br />
                    Once you’ve added your Delta Exchange Key and Token, our system will verify the integration and will show your Delta Exchange account details.</li>
                <li><strong>Step 7: Explore the Beta Bot</strong><br />
                    With your Delta Exchange Key and Token successfully integrated, you can now explore the full functionality of our beta bot, including many strategies.</li>
            </ul>
        </div>
        <div className="text-center">
                    <Link
                      className="btn btn-primary"
                      to="/login"
                    >
                     Login & Explore
                    </Link>
                    </div>
                  </div>
                </div>
                </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Pages404
