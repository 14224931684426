import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody , CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"
import { Link } from "react-router-dom"

import Flatpickr from "react-flatpickr";
import StackedColumnChart from "../Chart/StackedColumnChart"
import { useDispatch } from "react-redux"
import '../../assets/scss/theme.scss'
import { handlezoom } from "."
const WelcomeComp = ({graphdata}) => {
  const get_user_details = JSON.parse(localStorage.getItem("user"));
  const [isShowcal,setisshowcal] = useState(false)
  const [periodType, setPeriodType] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 29)).toISOString());
  const [filterEndDate, setFilterEndDate] = useState(new Date(new Date()).toISOString());
  const [periodData, setPeriodData] = useState([])
  const [otherMenu, setOtherMenu] = useState(false);
  
  const dispatch = useDispatch()
  const handleCustombtn = (e)=>{
    setisshowcal(true)
  }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const handleCreatedDateChange = (selectedDates, datestr) => {    
    if (selectedDates && selectedDates.length) {
      let converted_date = new Date(convert(selectedDates[0]));
      converted_date.setHours(0, 0, 0, 0);
      setFilterStartDate(converted_date.toISOString()); 
      if(selectedDates[1]){
        let converted_end_date = new Date(convert(selectedDates[1]));
        converted_end_date.setHours(23, 59, 59, 999);
        setFilterEndDate(converted_end_date.toISOString());
        setisshowcal(false)    
      }
    } else {
      setFilterStartDate(defaultStartDate); 
      setFilterEndDate(defaultEndDate);  
      setisshowcal(false)    
    }
  }
  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    if (pType === 'Customize'){
      handleCustombtn();
    }
  };
  return (
    <React.Fragment>
          <StackedColumnChart  className="chartAreaWrapper" periodData={[graphdata]}   dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' />
   </React.Fragment>
  )
}
export default WelcomeComp
