import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,  
  Button,  
} from "reactstrap";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import images
import verificationImg from "../../assets/images/key-update.png";

const CryptoKYCApplication = props => {
  const history = useHistory();

  const gotoSettings=()=>{
    history.push("/settings");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="KEY SETUP" />

          <Row className="justify-content-center mt-lg-5">
            <Col xl="5" sm="8">
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col lg="10">
                        <h4 className="mt-4 fw-semibold">
                          API Credentials
                        </h4>
                        <p className="text-muted mt-3">
                          Please update your API key details in your Settings page and start your Bot.
                        </p>

                        <div className="mt-4">
                          {/* button triggers modal */}
                          <Button
                            type="button"
                            color="primary"
                            onClick={gotoSettings}
                          >
                            Go to Settings
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="justify-content-center mt-5 mb-2">
                      <Col sm="10" xs="8">
                        <div>
                          <img
                            src={verificationImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CryptoKYCApplication;
